import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg<any>`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const WaitingResults = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        clip-path="url(#clip0_24_9)"
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g clip-path="url(#clip0_25_30)">
          <path d="M11 23H2C1.73478 23 1.48043 22.8712 1.29289 22.642C1.10536 22.4128 1 22.1019 1 21.7778V2.22222C1 1.89807 1.10536 1.58719 1.29289 1.35798C1.48043 1.12877 1.73478 1 2 1H16C16.2652 1 16.5196 1.12877 16.7071 1.35798C16.8946 1.58719 17 1.89807 17 2.22222V8.33333" />
          <path d="M17.5 23C20.5376 23 23 20.5376 23 17.5C23 14.4624 20.5376 12 17.5 12C14.4624 12 12 14.4624 12 17.5C12 20.5376 14.4624 23 17.5 23Z" />
          <path d="M19 18H17V15" />
          <path d="M13 6.11111C13 5.90483 12.9181 5.707 12.7722 5.56114C12.6263 5.41528 12.4285 5.33333 12.2222 5.33333H10.6667V3.77778C10.6667 3.5715 10.5847 3.37367 10.4389 3.22781C10.293 3.08194 10.0952 3 9.88889 3H9.11111C8.90483 3 8.707 3.08194 8.56114 3.22781C8.41528 3.37367 8.33333 3.5715 8.33333 3.77778V5.33333H6.77778C6.5715 5.33333 6.37367 5.41528 6.22781 5.56114C6.08194 5.707 6 5.90483 6 6.11111V6.88889C6 7.09517 6.08194 7.293 6.22781 7.43886C6.37367 7.58472 6.5715 7.66667 6.77778 7.66667H8.33333V9.22222C8.33333 9.4285 8.41528 9.62633 8.56114 9.77219C8.707 9.91806 8.90483 10 9.11111 10H9.88889C10.0952 10 10.293 9.91806 10.4389 9.77219C10.5847 9.62633 10.6667 9.4285 10.6667 9.22222V7.66667H12.2222C12.4285 7.66667 12.6263 7.58472 12.7722 7.43886C12.9181 7.293 13 7.09517 13 6.88889V6.11111Z" />
          <path d="M11 15.5H5M12.5 13H5M11 18.5H5" />
        </g>
        <defs>
          <clipPath id="clip0_25_30">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </g>
    </StyledIcon>
  )
}
