import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Discount = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M8.03 15.97l8-8M10.09 7.91a1.5 1.5 0 11-2.12 2.12 1.5 1.5 0 012.12-2.12M16.09 13.91a1.5 1.5 0 11-2.12 2.12 1.5 1.5 0 012.12-2.12"></path>
        <path d="M23.5 12h0a4 4 0 00-2.534-3.714h0a3.988 3.988 0 00-5.252-5.252h0a3.989 3.989 0 00-7.428 0h0a3.988 3.988 0 00-5.252 5.252h0a3.989 3.989 0 000 7.428h0a3.988 3.988 0 005.252 5.252h0a3.989 3.989 0 007.428 0h0a3.988 3.988 0 005.252-5.252h0A4 4 0 0023.5 12z"></path>
      </g>
    </StyledIcon>
  )
}
