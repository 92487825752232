import React, { ChangeEvent } from 'react'

interface CheckboxProps {
  label: string
  name: string
  checked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

export const CheckboxTW = ({
  label,
  name,
  checked,
  onChange,
  disabled,
}: CheckboxProps) => {
  return (
    <div className="flex items-center">
      <input
        id={name}
        name={name}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="checkboxTW"
        disabled={disabled}
      />
      <label
        htmlFor={name}
        className="ml-[14px] block font-medium text-zoyya-text"
      >
        {label}
      </label>
    </div>
  )
}
