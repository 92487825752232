import {
  AppBody,
  AppWrapper,
  LeftWrapper,
  RouteContentContainer,
  UserAvatarWrapper,
  UserName,
} from 'src/styles'
import { Route, Switch, Redirect } from 'react-router-dom'
import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  Suspense,
  lazy,
  useEffect,
} from 'react'
import { FormField, Loader } from 'components'
import { MobileContext } from 'src/Context'
import { usePromptToInstall } from 'src/usePromptToInstall'
import { PrivateRoute } from 'sdk/PrivateRoute'
import { isNumber, includes } from 'lodash'
import moment from 'moment'
import { HeaderContext, ClientMobileHeader } from 'mobile/Header'
import { getLocationId, getOrgId, getUserAvatar, isIos } from 'src/helpers'
import { useLocation } from 'react-router'
import { useSdk } from 'sdk'
import EditEmployee from 'views/Settings/Employees/EmployeeEditor/EditEmployee'
import NewEmployee from 'views/Settings/Employees/EmployeeEditor/NewEmployee'
import ResourcesList from 'views/Settings/Resources/ResourcesList'
import EmployeesList from 'views/Settings/Employees/EmployeeEditor/EmployeesList'
import { ServiceTypeEditor } from 'views/Settings/ServiceTypes/ServiceTypeEditor'
import { searchClients, useGetLocationLoading, useGetMe } from 'state/queries'
import { useTranslation } from 'react-i18next'
import i18n, { getCurrentLanguage } from 'src/localization'
import { MenuItem } from 'mobile/Menu'
import Helmet from 'react-helmet'
import { FooterContext, ClientMobileFooter } from 'mobile/Footer'
import LocationNotifications from 'views/Settings/NotificationPreferences/LocationNotifications'
import { icons } from 'components'
import LocationWorkHoursMobile from 'mobile/Settings/WorkHours/LocationWorkHoursMobile'
import { ClientAttachments } from 'views/Clients/ClientAttachments'
import { useConfigFeaturesQuery } from './state/graphql'
import PosScreen from 'views/CashRegister/PosScreen'
import EditResourceType from 'views/Settings/Resources/ResourceTypeEditor/EditResourceType'
import { NewResourceType } from 'views/Settings/Resources/ResourceTypeEditor/NewResourceType'
import { SidebarLeftItem } from 'layout/styles'
import {
  SidebarLeftItems,
  SidebarHint,
  SidebarIconWrapper,
} from 'src/sdk/tw/components/SidebarLink'
import { trpc } from './trpc'
import { useACL } from './sdk/acl'
import { useAppStore, useFeatureFlags } from './state/local/appStore'
import { usePageVisibility } from './sdk/usePageVisibility'
import CalendarHotel from './views/CalendarHotel/CalendarHotel'
import NewTemplate from './views/Settings/GiftCard/NewTemplate'
import GiftCardList from './views/Settings/GiftCard/GiftCardList'
import EditTemplate from './views/Settings/GiftCard/EditTemplate'
import NewGiftCard from './views/Settings/GiftCard/NewGiftCard'
import EditGiftCard from './views/Settings/GiftCard/EditGiftCard'
import { useIdle } from './sdk/useIdle'
import { createPortal } from 'react-dom'
import { ButtonTW } from './sdk/tw/ButtonTW'
import { Formik, Form } from 'formik'
import { InputTW } from './sdk/tw/InputTW'
import { useAppServices } from './sdk/appServices'
import { getErrorMessage } from '../../server/src/sdk/errorHandler'
import { LockScreen } from './LockScreen'
import { useScreenLock } from './sdk/useScreenLock'
import NewGoodsRemoved from './views/WMS/NewGoodsRemoved'
import TemplateList from './views/Settings/GiftCard/TemplateList'
import { ChangelogUpgradePage } from './components/UpgradePage/UpgradePage'
import { ClientRemindersListMobile } from './views/Clients/ClientRemindersListMobile'
import { ClientRemindersForm } from './views/Clients/ClientRemindersForm'

const GoodsReceivedList = lazy(() => import('views/WMS/GoodsReceivedList'))
const GoodsReleasedList = lazy(() => import('views/WMS/GoodsReleasedList'))
const CurrentStock = lazy(() => import('views/WMS/CurrentStock'))
const SalesInPeriod = lazy(() => import('views/WMS/SalesInPeriod'))

const NewGoodsReceived = lazy(() => import('src/views/WMS/NewGoodsReceived'))
const EditGoodsReceived = lazy(() => import('src/views/WMS/EditGoodsReceived'))
const GoodsReport = lazy(() => import('src/views/WMS/GoodsReport'))
const PriceChangeReport = lazy(() => import('src/views/WMS/PriceChangeReport'))
const ProductStockReport = lazy(
  () => import('src/views/WMS/ProductStockReport')
)
const EditGoodsReleased = lazy(() => import('src/views/WMS/EditGoodsReleased'))
const BusinessPartnersListScreen = lazy(
  () => import('views/BusinessPartners/BusinessPartnersListScreen')
)
const BusinessPartnersCreateScreen = lazy(
  () => import('views/BusinessPartners/BusinessPartnersCreateScreen')
)
const BusinessPartnersEditScreen = lazy(
  () => import('views/BusinessPartners/BusinessPartnersEditScreen')
)

const PosSettingsOperators = lazy(
  () => import('views/CashRegister/PosSettingsOperators')
)
const PosSettingsDevices = lazy(
  () => import('views/CashRegister/PosSettingsDevices')
)
// import MessagesList from 'views/Messages/MessagesList'

const RezervationsDesktop = lazy(
  () => import('views/Reservations/RezervationsDesktop')
)
const RezervationsMobile = lazy(
  () => import('views/Reservations/RezervationsMobile')
)

const NewHolidayContainer = lazy(
  () => import('views/Settings/Holidays/NewHoliday/NewHolidayContainer')
)
const EditHolidayContainer = lazy(
  () => import('views/Settings/Holidays/NewHoliday/EditHolidayContainer')
)
const NewProfessionContainer = lazy(
  () => import('views/Settings/Professions/ProfessionEditor/NewProfession')
)
const EditProfessionContainer = lazy(
  () => import('views/Settings/Professions/ProfessionEditor/EditProfession')
)
const EditResource = lazy(
  () => import('views/Settings/Resources/ResourceEditor/EditResource')
)
const NewResource = lazy(
  () => import('views/Settings/Resources/ResourceEditor/NewResource')
)
const SettingsMobile = lazy(
  () => import('src/mobile/Settings/SettingsScreen/SettingsMobile')
)
const BranchSettings = lazy(
  () => import('src/mobile/Settings/SettingsScreen/BranchSettings')
)
const ReportsMenuMobile = lazy(
  () => import('src/mobile/Settings/SettingsScreen/ReportsMenuMobile')
)
const HomeMobile = lazy(() => import('mobile/Home/HomeMobile'))
const ClientModal = lazy(() => import('views/Clients/NewClient'))
const HolidaysList = lazy(() => import('views/Settings/Holidays/HolidaysList'))

const ServicesList = lazy(() => import('views/Settings/Services/ServicesList'))
const ProductList = lazy(() => import('views/Settings/Products/ProductsList'))
const GalleryImages = lazy(() => import('views/Settings/Gallery/GalleryImages'))
const EditProduct = lazy(
  () => import('views/Settings/Products/ProductEditor/EditProduct')
)
const NewProduct = lazy(
  () => import('views/Settings/Products/ProductEditor/NewProduct')
)
const NewProductType = lazy(
  () => import('views/Settings/Products/ProductEditor/NewProductType')
)
const LocationGeneralInfo = lazy(
  () => import('views/Settings/Locations/NewLocation/LocationGeneralInfo')
)
const ProfessionsList = lazy(
  () => import('views/Settings/Professions/ProfessionsList')
)

const NewLocation = lazy(
  () => import('views/Settings/Locations/NewLocation/NewLocation')
)
const WorkHours = lazy(() => import('views/WorkHours/WorkHours'))
const NotFound = lazy(() => import('components/NotFound/NotFound'))
const Sidebar = lazy(() => import('layout/Sidebar'))
const DashboardIncome = lazy(() => import('views/Reports/DashboardIncome'))
const DashboardServices = lazy(() => import('views/Reports/DashboardServices'))
const DashboardDuration = lazy(() => import('views/Reports/DashboardDuration'))
const DashboardAppointment = lazy(
  () => import('views/Reports/DashboardAppointment')
)
const PeriodIncome = lazy(() => import('views/Reports/PeriodIncome'))
const PeriodServices = lazy(() => import('views/Reports/PeriodServices'))
const PeriodDuration = lazy(() => import('views/Reports/PeriodDuration'))
const PeriodClients = lazy(() => import('views/Reports/PeriodClients'))
const PeriodEmployees = lazy(() => import('views/Reports/PeriodEmployees'))
const LocationWorkHours = lazy(
  () => import('views/Settings/WorkHours/LocationWorkHours')
)
const LocationBooking = lazy(
  () => import('views/Settings/Booking/LocationBooking')
)
const CalendarMobile = lazy(() => import('mobile/Calendar/CalendarMobile'))
/* const LocationBookingIntegrations = lazy(() =>
  import('views/Settings/Booking/LocationBookingIntegrations')
) */
const LocationUserPermissions = lazy(
  () => import('views/Settings/Permissions/LocationUserPermissions')
)
const LocationBookingPrepayments = lazy(
  () => import('views/Settings/Booking/LocationBookingPrepayments')
)
const LocationBookingPrepaymentsStripe = lazy(
  () => import('views/Settings/Booking/LocationBookingPrepaymentsStripe')
)
const LocationBookingPrepaymentsStripeRefresh = lazy(
  () => import('views/Settings/Booking/LocationBookingPrepaymentsStripeRefresh')
)
const LanguageModal = lazy(
  () => import('components/LanguageModal/LanguageModal')
)
const UpgradePage = lazy(() => import('components/UpgradePage'))
const BillingContainer = lazy(
  () => import('views/Settings/Billing/BillingContainer')
)
const Visits = lazy(() => import('views/Reports/Visits'))
const MissedAppointments = lazy(
  () => import('views/Reports/MissedAppointments')
)
const AppointmentList = lazy(() => import('views/Reports/AppointmentList'))
const Reviews = lazy(() => import('views/Reports/Reviews'))
const CashRegister = lazy(() => import('views/Reports/CashRegister'))
const CalendarV2 = lazy(() => import('views/CalendarComplex/CalendarComplex'))
const CalendarV1 = lazy(() => import('views/Calendar/CalendarContainer'))
const EditService = lazy(
  () => import('views/Settings/Services/ServiceEditor/EditService')
)
const EditClient = lazy(() => import('views/Clients/EditClient'))
const LocationsContainer = lazy(
  () => import('views/Settings/Locations/LocationsList')
)
const NewService = lazy(
  () => import('views/Settings/Services/ServiceEditor/NewService')
)
const NewServiceType = lazy(
  () => import('views/Settings/Services/ServiceEditor/NewServiceType')
)
const Organization = lazy(
  () => import('views/Settings/Organization/OrganizationContainer')
)
const MyAccount = lazy(() => import('views/Settings/MyAccount/MyAccount'))
const MyAccountSecurity = lazy(
  () => import('views/Settings/MyAccount/MyAccountSecurity')
)
const MyAccountContact = lazy(
  () => import('views/Settings/MyAccount/MyAccountContact')
)
const ClientsList = lazy(() => import('views/Clients/ClientsList'))

const ClientDetailsMobile = lazy(
  () => import('views/Clients/ClientDetailsMobile')
)
const ClientDetailsGroupMobile = lazy(
  () => import('views/Clients/ClientDetailsGroupMobile')
)

const HomeContainer = lazy(() => import('views/Home/HomeContainer'))

const ResourcesListMobile = lazy(
  () => import('mobile/Settings/Resources/ResourcesListMobile')
)
const ReceiptListScreen = lazy(
  () => import('views/CashRegister/ReceiptListScreen')
)
const JournalListScreen = lazy(
  () => import('views/CashRegister/JournalListScreen')
)
const LocationChangelogScreen = lazy(
  () => import('views/Settings/Changelog/LocationChangelog')
)

export const InstallAppOnIos = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <icons.PhoneDownload color={'white'} />
      <div style={{ margin: 'auto' }}>
        <span>{i18n.t('translation.clientRoutes.installAppLabel')}</span>
        <icons.SafariShare color={'white'} size={'smaller'} />
        <br />
        <span>{i18n.t('translation.clientRoutes.installAppLabel2')}</span>
      </div>
      <icons.Cross color={'white'} size={'smaller'} />
    </div>
  )
}

export const AppDrawer = props => {
  const { onCloseDrawer } = props
  const { appServices, history } = useSdk()
  const userData = useGetMe()
  const user = userData?.data?.user?.me
  const { t } = useTranslation()
  const { deferredPrompt } = usePromptToInstall()
  const navItems = useMemo(
    () => [
      ...(!!user?.id && !localStorage.getItem('partnerOrgId')
        ? [
            {
              id: 1,
              route: `/client/*/register`,
              title: t('translation.Navigation.title-partnerAccount'),
              icon: <icons.Briefcase size={'small'} />,
            },
          ]
        : []),
      {
        id: 1,
        route: `/${localStorage.getItem('partnerOrgId')}/*/settings/language`,
        title: getCurrentLanguage() === 'en' ? 'English' : 'Hrvatski',
        icon:
          getCurrentLanguage() === 'sr' ? (
            <icons.SerbianFlag />
          ) : getCurrentLanguage() === 'en' ? (
            <icons.UKFlag />
          ) : getCurrentLanguage() === 'hr' ? (
            <icons.CroatianFlag />
          ) : getCurrentLanguage() === 'es' ? (
            <icons.SpanishFlag />
          ) : (
            <icons.GermanFlag />
          ),
      },

      ...(deferredPrompt
        ? [
            {
              id: 1,
              title: t('translation.clientRoutes.installApp'),
              onClick: () => {
                if (isIos()) {
                } else {
                  deferredPrompt.prompt()
                }
              },
              icon: <icons.PhoneDownload size={'small'} />,
            },
          ]
        : []),
      ...(!!user
        ? [
            {
              id: 1,
              title: t('translation.clientRoutes.logout'),
              onClick: () => {
                appServices.auth.signOut()
                window.open(`/login`, '_self')
              },
              icon: <icons.LogoutIcon size={'small'} />,
            },
          ]
        : []),
      ...(!user
        ? [
            {
              id: 1,
              route: '/login',
              title: t('translation.clientRoutes.login'),
              icon: <icons.Login size={'small'} />,
            },
            {
              id: 1,
              route: '/signup',
              title: t('translation.clientRoutes.register'),
              icon: <icons.Register size={'small'} />,
            },
          ]
        : []),
    ],
    [user, appServices, t, deferredPrompt]
  )

  const handleMenuItemClick = item => {
    onCloseDrawer()
    if (item.onClick) {
      item.onClick()
    } else {
      history.push(item.route)
    }
  }
  return (
    <div>
      {user?.id ? (
        <UserAvatarWrapper>
          {getUserAvatar(user, false, 'default')}
          <UserName>{user?.firstName + ' ' + user?.lastName}</UserName>
        </UserAvatarWrapper>
      ) : null}
      {navItems.map((x, index) => (
        <MenuItem
          key={index}
          label={x.title}
          icon={x.icon}
          cy={x?.id?.toString()}
          onClick={() => handleMenuItemClick(x)}
        />
      ))}
    </div>
  )
}

type RouteContainerProps = {
  route: JSX.Element
  showMobileFooter?: boolean
  selectedEmployeesInWeek?: any[]
  setSelectedEmployeesInWeek?: any[]
  showActive?: boolean
  setShowActive?: any
  setShowOffHours?: any
  showOffHours?: boolean
  hideSidebar?: boolean
  orgSidebar?: boolean
  hasPermissionToAccess?: boolean
}
const RouteContainer = ({
  route,
  showMobileFooter,
  selectedEmployeesInWeek,
  setSelectedEmployeesInWeek,
  showActive,
  setShowActive,
  setShowOffHours,
  showOffHours,
  hideSidebar,
  orgSidebar,
  hasPermissionToAccess = true,
}: RouteContainerProps) => {
  const isMobile = useContext(MobileContext)
  const [headerOptions, setHeaderOptions] = useState()
  const [footerOptions, setFooterOptions] = useState()
  const getHeaderOptions = useCallback(() => headerOptions, [headerOptions])
  const getFooterOptions = useCallback(() => footerOptions, [footerOptions])
  const footerProvider = useMemo(
    () => ({
      setOptions: setFooterOptions,
      getOptions: getFooterOptions,
    }),
    [getFooterOptions, setFooterOptions]
  )
  const headerProvider = useMemo(
    () => ({
      setOptions: setHeaderOptions,
      getOptions: getHeaderOptions,
    }),
    [getHeaderOptions, setHeaderOptions]
  )
  const orgId = getOrgId()
  const locationIdStr = getLocationId()
  let locationId: bigint = BigInt(-1)
  if (locationIdStr) {
    try {
      locationId = BigInt(locationIdStr)
    } catch (e) {
      locationId = BigInt(-1)
    }
  }

  const sidebarHeight = `${
    (document.getElementById('sidebarHeader')?.offsetHeight || 0) +
    (document.getElementById('sidebarItems')?.offsetHeight || 0) +
    (document.getElementById('sidebarFooter')?.offsetHeight || 0)
  }px`

  const orgSidebarHeight = `${
    (document.getElementById('sidebarHeaderOrg')?.offsetHeight || 0) +
    (document.getElementById('sidebarFooterOrg')?.offsetHeight || 0)
  }px`
  return (
    <HeaderContext.Provider value={headerProvider as any}>
      <FooterContext.Provider value={footerProvider as any}>
        <AppBody
          minHeight={
            !isMobile && !hideSidebar
              ? !orgSidebar
                ? sidebarHeight
                : orgSidebarHeight
              : '100%'
          }
        >
          {!isMobile && !hideSidebar ? (
            <Sidebar
              selectedEmployeesInWeek={selectedEmployeesInWeek}
              showActive={showActive}
              setSelectedEmployeesInWeek={setSelectedEmployeesInWeek}
              setShowActive={setShowActive}
              setShowOffHours={setShowOffHours}
              showOffHours={showOffHours}
              orgSidebar={orgSidebar}
            />
          ) : null}
          <LeftWrapper hasFooter={showMobileFooter}>
            <RouteContentContainer hasFooter={showMobileFooter}>
              {isMobile ? <ClientMobileHeader /> : null}
              {hasPermissionToAccess ? (
                route
              ) : (
                <Redirect
                  to={`/${getOrgId()}/${getLocationId()}/events/day/${moment().format(
                    'YYYY-MM-DD'
                  )}/calendar/day`}
                />
              )}
            </RouteContentContainer>
          </LeftWrapper>

          {isMobile && showMobileFooter ? <ClientMobileFooter /> : null}
        </AppBody>
      </FooterContext.Provider>
    </HeaderContext.Provider>
  )
}

export const GetPartnerRoutes = ({
  selectedEmployeesInWeek,
  setSelectedEmployeesInWeek,
  showActive,
  setShowActive,
  setShowOffHours,
  showOffHours,
  myOrgs,
  orgFeatures,
  setOpenBillingModal,
}) => {
  const isMobile = useContext(MobileContext)

  const isVisible = usePageVisibility()

  const { pathname } = useLocation()
  const { t, apolloClient } = useSdk()
  const [mobileCalendarFooterVisible, setMobileCalendarFooterVisible] =
    useState(true)

  const [setFeatureFlags] = useAppStore(store => [store.setFeatureFlags])
  const [featureFlagsSet, setFeatureFlagsSet] = useState(false)

  const { data: featureFlagsData, isLoading: isLoadingFeatureFlags } =
    trpc.featureFlags_get.useQuery({
      orgId: getOrgId()!,
      locationId: BigInt(getLocationId() || 0),
    })
  const loyaltyFeatureFlag = featureFlagsData?.loyalty === 'true'

  const { idle, reset } = useIdle(featureFlagsData?.lockTimeout || 0)

  const { isLocked, lock, unLock } = useScreenLock()
  const { data: userSessionData } = trpc.user_getSession.useQuery()
  const lockMutation = trpc.session_lock.useMutation()

  useEffect(() => {
    if (!featureFlagsData || isLoadingFeatureFlags) return
    setFeatureFlagsSet(true)
    setFeatureFlags(featureFlagsData)
  }, [featureFlagsData, isLoadingFeatureFlags])
  useEffect(() => {
    if (isLoadingFeatureFlags || !featureFlagsData) return
    if (featureFlagsData.lockTimeout === 0) return
    const lockSession = async () => {
      await lockMutation.mutateAsync()
      localStorage.removeItem('token')
      lock()
    }
    if (idle) {
      lockSession()
    }
  }, [idle, isLoadingFeatureFlags, featureFlagsData])
  const { data: locationData, loading: loadingLocationData } =
    useGetLocationLoading(getLocationId())
  const location = locationData?.location?.get
  const locationIdStr = getLocationId()
  let locationId: bigint = BigInt(-1)
  if (locationIdStr) {
    try {
      locationId = BigInt(locationIdStr)
    } catch (e) {
      locationId = BigInt(-1)
    }
  }

  const {
    data: permissions,
    isLoading: permissionsLoading,
    error: permissionsError,
    refetch: refetchPermissions,
  } = trpc.getUserPermissions.useQuery({
    orgId: getOrgId()!,
    locationId: locationId,
  })

  const trpcContext = trpc.useContext()
  useEffect(() => {
    if (isVisible) {
      trpcContext.unconfirmedBookingCount_get.invalidate()
      trpcContext.calendar_getEntries.invalidate()
    }
  }, [isVisible])

  const { hasPermission } = useACL()
  trpc.onUserPermissionsUpdate.useSubscription(
    { orgId: getOrgId(), locationId: getLocationId() },
    {
      onData: async changedPolicies => {
        await refetchPermissions()

        if (
          // @ts-ignore
          changedPolicies?.ACCESS_CLIENT_EMAIL ||
          // @ts-ignore
          changedPolicies?.ACCESS_CLIENT_PHONE
        ) {
          await apolloClient.query({
            query: searchClients,
            fetchPolicy: 'network-only',
            variables: {
              pageNumber: 1,
              pageSize: 50,
              searchTerm: '',
              sortBy: localStorage.getItem('clientsSortBy') || 'id_desc',
            },
          })
        }
      },
    }
  )

  if (!myOrgs) return null

  function getCalendarRoute() {
    const featureFlags = useFeatureFlags()

    if (!featureFlagsSet) return null
    const calendarVersion = featureFlags.calendarVersion
    return (
      <RouteContainer
        showMobileFooter={mobileCalendarFooterVisible}
        route={
          calendarVersion && calendarVersion === 'v2' ? (
            <CalendarV2
              selectedEmployeesInWeek={selectedEmployeesInWeek}
              showActive={showActive}
              setSelectedEmployeesInWeek={setSelectedEmployeesInWeek}
              setShowActive={setShowActive}
              setShowOffHours={setShowOffHours}
              showOffHours={showOffHours}
            />
          ) : calendarVersion && calendarVersion === 'HOTEL' ? (
            <CalendarHotel
              setMobileCalendarFooterVisible={setMobileCalendarFooterVisible}
            />
          ) : !calendarVersion || calendarVersion === 'v1' ? (
            isMobile ? (
              <CalendarMobile
                showActive={showActive}
                setShowActive={setShowActive}
              />
            ) : (
              <CalendarV1
                selectedEmployeesInWeek={selectedEmployeesInWeek}
                selectedEmployeesInMonth={[]}
                setSelectedEmployeesInMonth={empl => {}}
                showActive={showActive}
                showOffHours={showOffHours}
              />
            )
          ) : (
            <Loader />
          )
        }
        selectedEmployeesInWeek={selectedEmployeesInWeek}
        showActive={showActive}
        setSelectedEmployeesInWeek={setSelectedEmployeesInWeek}
        setShowActive={setShowActive}
        showOffHours={showOffHours}
        setShowOffHours={setShowOffHours}
      />
    )
  }
  const storedLocationId =
    sessionStorage.getItem('locationId') || localStorage.getItem('locationId')
  const defaultCalendarView = localStorage.getItem('calendarView') || 'threeDay'
  const storedOrgId = localStorage.getItem('orgId')
  const redirectToOrg = myOrgs?.find(e => e.id === storedOrgId)
  let redirectToOrgId = redirectToOrg?.Id
  let redirectToLocationId = redirectToOrg?.myLocations?.find(
    e => isNumber(storedLocationId) && Number(e.id) === Number(storedLocationId)
  )?.Id

  if (!redirectToOrgId && myOrgs?.length) {
    const filteredOrgs = myOrgs.filter(x => x.myRole !== 'CLIENT')
    redirectToOrgId = filteredOrgs[0].id
    redirectToLocationId = filteredOrgs[0].myLocations?.[0]?.id
  }

  localStorage.setItem('partnerOrgId', redirectToOrgId)

  const unlockApp = (userId: string) => {
    if (userId !== userSessionData?.currentUserId) {
      window.location.reload()
      reset()
    } else {
      unLock()
    }
  }
  return (
    <div className="flex flex-1 h-full overflow-auto">
      <Suspense
        fallback={
          <AppBody>
            <LeftWrapper hasFooter={isMobile}>
              {isMobile ? <ClientMobileHeader /> : null}
              <RouteContentContainer>
                <Loader isComponent />
              </RouteContentContainer>
            </LeftWrapper>
            {isMobile && <ClientMobileFooter />}
          </AppBody>
        }
      >
        <Helmet>
          <title>{`Zoyya${
            !loadingLocationData ? ' - ' + location?.name : ''
          }`}</title>
        </Helmet>

        <Switch>
          {/*DASHBOARD ROUTES*/}
          <Route path={`/:orgId/:locationId/dashboard`}>
            <RouteContainer
              route={isMobile ? <HomeMobile /> : <HomeContainer />}
              showMobileFooter={true}
            />
          </Route>

          <Route path={`/:orgId/:locationId/dashboard/day/:dayId/:action`}>
            <RouteContainer
              route={isMobile ? <HomeMobile /> : <HomeContainer />}
            />
          </Route>
          <Route path={`/:orgId/:locationId/dashboard/day/:dayId/:action`}>
            <RouteContainer
              route={isMobile ? <HomeMobile /> : <HomeContainer />}
            />
          </Route>
          <Route path={`/:orgId/:locationId/reports/overview/income/:dateFrom`}>
            <RouteContainer
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_ADVANCED_REPORTS')}
              route={
                orgFeatures?.hasReporting ? (
                  <DashboardIncome />
                ) : (
                  <UpgradePage
                    isReportsPage
                    header={t('translation.Sidebar.label-reports')}
                    title={t('translation.UpgradePageReports.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t('translation.UpgradePageReports.imageUrl')}
                    text={t('translation.UpgradePageReports.description')}
                    list={[
                      t('translation.UpgradePageReports.bullet1'),
                      t('translation.UpgradePageReports.bullet2'),
                      t('translation.UpgradePageReports.bullet3'),
                    ]}
                  />
                )
              }
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/reports/overview/services/:dateFrom`}
          >
            <RouteContainer
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_ADVANCED_REPORTS')}
              route={
                orgFeatures?.hasReporting ? (
                  <DashboardServices />
                ) : (
                  <UpgradePage
                    isReportsPage
                    header={t('translation.Sidebar.label-reports')}
                    title={t('translation.UpgradePageReports.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t('translation.UpgradePageReports.imageUrl')}
                    text={t('translation.UpgradePageReports.description')}
                    list={[
                      t('translation.UpgradePageReports.bullet1'),
                      t('translation.UpgradePageReports.bullet2'),
                      t('translation.UpgradePageReports.bullet3'),
                    ]}
                  />
                )
              }
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/reports/overview/duration/:dateFrom`}
          >
            <RouteContainer
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_ADVANCED_REPORTS')}
              route={
                orgFeatures?.hasReporting ? (
                  <DashboardDuration />
                ) : (
                  <UpgradePage
                    isReportsPage
                    header={t('translation.Sidebar.label-reports')}
                    title={t('translation.UpgradePageReports.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t('translation.UpgradePageReports.imageUrl')}
                    text={t('translation.UpgradePageReports.description')}
                    list={[
                      t('translation.UpgradePageReports.bullet1'),
                      t('translation.UpgradePageReports.bullet2'),
                      t('translation.UpgradePageReports.bullet3'),
                    ]}
                  />
                )
              }
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/reports/overview/appointment/:dateFrom`}
          >
            <RouteContainer
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_ADVANCED_REPORTS')}
              route={
                orgFeatures?.hasReporting ? (
                  <DashboardAppointment />
                ) : (
                  <UpgradePage
                    isReportsPage
                    header={t('translation.Sidebar.label-reports')}
                    title={t('translation.UpgradePageReports.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t('translation.UpgradePageReports.imageUrl')}
                    text={t('translation.UpgradePageReports.description')}
                    list={[
                      t('translation.UpgradePageReports.bullet1'),
                      t('translation.UpgradePageReports.bullet2'),
                      t('translation.UpgradePageReports.bullet3'),
                    ]}
                  />
                )
              }
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/reports/periods/:overviewType/:dateFrom/:dateTo`}
          >
            <RouteContainer
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_ADVANCED_REPORTS')}
              route={
                orgFeatures?.hasReporting ? (
                  includes(pathname, `income`) ? (
                    <PeriodIncome />
                  ) : includes(pathname, `services`) ? (
                    <PeriodServices />
                  ) : includes(pathname, `duration`) ? (
                    <PeriodDuration />
                  ) : includes(pathname, `clients`) ? (
                    <PeriodClients />
                  ) : includes(pathname, `employees`) ? (
                    <PeriodEmployees />
                  ) : (
                    <></>
                  )
                ) : (
                  <UpgradePage
                    isReportsPage
                    header={t('translation.Sidebar.label-reports')}
                    title={t('translation.UpgradePageReports.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t('translation.UpgradePageReports.imageUrl')}
                    text={t('translation.UpgradePageReports.description')}
                    list={[
                      t('translation.UpgradePageReports.bullet1'),
                      t('translation.UpgradePageReports.bullet2'),
                      t('translation.UpgradePageReports.bullet3'),
                    ]}
                  />
                )
              }
            />
          </Route>
          <Route path={`/:orgId/:locationId/reports/visits/:employeeId/:dayId`}>
            <RouteContainer
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_BASIC_REPORTS')}
              route={
                orgFeatures?.hasReporting ? (
                  <Visits />
                ) : (
                  <UpgradePage
                    isReportsPage
                    header={t('translation.Sidebar.label-reports')}
                    title={t('translation.UpgradePageReports.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t('translation.UpgradePageReports.imageUrl')}
                    text={t('translation.UpgradePageReports.description')}
                    list={[
                      t('translation.UpgradePageReports.bullet1'),
                      t('translation.UpgradePageReports.bullet2'),
                      t('translation.UpgradePageReports.bullet3'),
                    ]}
                  />
                )
              }
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/reports/appointmentList/:dateFrom/:dateTo`}
          >
            <RouteContainer
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_BASIC_REPORTS')}
              route={
                orgFeatures?.hasReporting ? (
                  <AppointmentList />
                ) : (
                  <UpgradePage
                    isReportsPage
                    header={t('translation.Sidebar.label-reports')}
                    title={t('translation.UpgradePageReports.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t('translation.UpgradePageReports.imageUrl')}
                    text={t('translation.UpgradePageReports.description')}
                    list={[
                      t('translation.UpgradePageReports.bullet1'),
                      t('translation.UpgradePageReports.bullet2'),
                      t('translation.UpgradePageReports.bullet3'),
                    ]}
                  />
                )
              }
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/reports/missedAppointments/:dateFrom/:dateTo`}
          >
            <RouteContainer
              showMobileFooter={true}
              route={
                orgFeatures?.hasReporting ? (
                  <MissedAppointments />
                ) : (
                  <UpgradePage
                    isReportsPage
                    header={t('translation.Sidebar.label-reports')}
                    title={t('translation.UpgradePageReports.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t('translation.UpgradePageReports.imageUrl')}
                    text={t('translation.UpgradePageReports.description')}
                    list={[
                      t('translation.UpgradePageReports.bullet1'),
                      t('translation.UpgradePageReports.bullet2'),
                      t('translation.UpgradePageReports.bullet3'),
                    ]}
                  />
                )
              }
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/reports/reviews/:dateFrom/:dateTo/appointment/:resourceBookingId`}
          >
            <RouteContainer showMobileFooter={false} route={<Reviews />} />
          </Route>
          <Route path={`/:orgId/:locationId/reports/reviews/:dateFrom/:dateTo`}>
            <RouteContainer showMobileFooter={true} route={<Reviews />} />
          </Route>

          <Route
            exact
            path={`/:orgId/:locationId/cashRegister/reports/:reportId`}
          >
            <RouteContainer
              // showMobileFooter={true}
              route={
                orgFeatures?.hasReporting ? (
                  <CashRegister />
                ) : (
                  <UpgradePage
                    isReportsPage
                    header={t('translation.Sidebar.label-reports')}
                    title={t('translation.UpgradePageReports.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t('translation.UpgradePageReports.imageUrl')}
                    text={t('translation.UpgradePageReports.description')}
                    list={[
                      t('translation.UpgradePageReports.bullet1'),
                      t('translation.UpgradePageReports.bullet2'),
                      t('translation.UpgradePageReports.bullet3'),
                    ]}
                  />
                )
              }
            />
          </Route>
          {/*EVENTS ROUTES*/}

          <Route
            path={`/:orgId/:locationId/events/day/:dayId/calendar/:view/booking/:bookingId/:selectedHour/:selectedMinute`}
          >
            {getCalendarRoute()}
          </Route>
          <Route
            path={`/:orgId/:locationId/events/day/:dayId/calendar/:view/appointment/view/:reservationId/:hour/:minute`}
          >
            {getCalendarRoute()}
          </Route>
          <Route
            path={`/:orgId/:locationId/events/day/:dayId/calendar/:view/appointment/view/:appointmentViewId`}
          >
            {getCalendarRoute()}
          </Route>

          <Route
            path={`/:orgId/:locationId/events/day/:dayId/calendar/:view/appointment/:appointmentId/:selectedHour/:selectedMinute`}
          >
            {getCalendarRoute()}
          </Route>
          <Route
            path={`/:orgId/:locationId/events/day/:dayId/calendar/:view/appointment/:appointmentId`}
          >
            {getCalendarRoute()}
          </Route>
          <Route
            path={`/:orgId/:locationId/events/day/:dayId/calendar/:view/booking/:bookingId`}
          >
            {getCalendarRoute()}
          </Route>
          <Route
            path={`/:orgId/:locationId/events/day/:dayId/calendar/:view/:selectedHour/:selectedMinutes`}
          >
            {getCalendarRoute()}
          </Route>
          <Route
            path={`/:orgId/:locationId/events/day/:dayId/calendar/:view/:selectedEmployeeId`}
          >
            {getCalendarRoute()}
          </Route>
          <Route path={`/:orgId/:locationId/events/day/:dayId/calendar/:view`}>
            {getCalendarRoute()}
          </Route>
          <Route path={`/:orgId/:locationId/rezervations`}>
            <RouteContainer
              route={
                isMobile ? <RezervationsMobile /> : <RezervationsDesktop />
              }
              showMobileFooter={true}
            />
          </Route>

          {/*MESSAGES ROUTES*/}
          {/* Messages are currently hidden */}
          {/* <Route path={`/:orgId/:locationId/messages/list`}>
            <RouteContainer
              route={isMobile ? <MessagesListMobile /> : <MessagesList />}
              setOpenBillingModal={setOpenBillingModal}
              showMobileFooter={true}
            />
          </Route> */}

          {/*CASH REGISTER*/}
          <Route path={`/:orgId/:locationId/cashRegister/journal`}>
            <RouteContainer
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_INVOICING')}
              route={
                orgFeatures.planId === 'advanced' ? (
                  <PosScreen />
                ) : (
                  <UpgradePage
                    title={t(
                      'translation.UpgradePageCashRegisterJournal.title'
                    )}
                    header="Blagajna"
                    setOpenBillingModal={setOpenBillingModal}
                    image={t(
                      'translation.UpgradePageCashRegisterJournal.imageUrl'
                    )}
                    text={t(
                      'translation.UpgradePageCashRegisterJournal.description'
                    )}
                    list={[
                      t('translation.UpgradePageCashRegisterJournal.bullet1'),
                      t('translation.UpgradePageCashRegisterJournal.bullet2'),
                      t('translation.UpgradePageCashRegisterJournal.bullet3'),
                    ]}
                  />
                )
              }
            />
          </Route>
          <Route path={`/:orgId/:locationId/cashRegister/receiptList`}>
            <RouteContainer
              hasPermissionToAccess={hasPermission('ACCESS_INVOICING')}
              route={
                orgFeatures.planId === 'advanced' ? (
                  <ReceiptListScreen />
                ) : (
                  <UpgradePage
                    header={t(
                      'translation.UpgradePageCashRegisterReceiptList.label-holidays'
                    )}
                    title={t(
                      'translation.UpgradePageCashRegisterReceiptList.title'
                    )}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t(
                      'translation.UpgradePageCashRegisterReceiptList.imageUrl'
                    )}
                    text={t(
                      'translation.UpgradePageCashRegisterReceiptList.description'
                    )}
                    list={[
                      t(
                        'translation.UpgradePageCashRegisterReceiptList.bullet1'
                      ),
                      t(
                        'translation.UpgradePageCashRegisterReceiptList.bullet2'
                      ),
                      t(
                        'translation.UpgradePageCashRegisterReceiptList.bullet3'
                      ),
                    ]}
                  />
                )
              }
            />
          </Route>
          <Route path={`/:orgId/:locationId/cashRegister/journalList`}>
            <RouteContainer
              hasPermissionToAccess={hasPermission('ACCESS_CASH_REGISTER')}
              route={
                orgFeatures.planId === 'advanced' ? (
                  <JournalListScreen />
                ) : (
                  <UpgradePage
                    header={t(
                      'translation.UpgradePageCashRegisterJournalList.label-holidays'
                    )}
                    title={t(
                      'translation.UpgradePageCashRegisterJournalList.title'
                    )}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t(
                      'translation.UpgradePageCashRegisterJournalList.imageUrl'
                    )}
                    text={t(
                      'translation.UpgradePageCashRegisterJournalList.description'
                    )}
                    list={[
                      t(
                        'translation.UpgradePageCashRegisterJournalList.bullet1'
                      ),
                      t(
                        'translation.UpgradePageCashRegisterJournalList.bullet2'
                      ),
                      t(
                        'translation.UpgradePageCashRegisterJournalList.bullet3'
                      ),
                    ]}
                  />
                )
              }
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/cashRegister/settings/devices/:deviceId`}
          >
            <RouteContainer
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_CASH_REGISTER')}
              route={
                orgFeatures.planId === 'advanced' ? (
                  <PosSettingsDevices />
                ) : (
                  <UpgradePage
                    header={t(
                      'translation.UpgradePageCashRegisterJournalList.label-holidays'
                    )}
                    title={t(
                      'translation.UpgradePageCashRegisterJournalList.title'
                    )}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t(
                      'translation.UpgradePageCashRegisterJournalList.imageUrl'
                    )}
                    text={t(
                      'translation.UpgradePageCashRegisterJournalList.description'
                    )}
                    list={[
                      t(
                        'translation.UpgradePageCashRegisterJournalList.bullet1'
                      ),
                      t(
                        'translation.UpgradePageCashRegisterJournalList.bullet2'
                      ),
                      t(
                        'translation.UpgradePageCashRegisterJournalList.bullet3'
                      ),
                    ]}
                  />
                )
              }
            />
          </Route>
          <Route path={`/:orgId/:locationId/cashRegister/settings/devices`}>
            <RouteContainer
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_CASH_REGISTER')}
              route={
                orgFeatures.planId === 'advanced' ? (
                  <PosSettingsDevices />
                ) : (
                  <UpgradePage
                    header={t(
                      'translation.UpgradePageCashRegisterJournalList.label-holidays'
                    )}
                    title={t(
                      'translation.UpgradePageCashRegisterJournalList.title'
                    )}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t(
                      'translation.UpgradePageCashRegisterJournalList.imageUrl'
                    )}
                    text={t(
                      'translation.UpgradePageCashRegisterJournalList.description'
                    )}
                    list={[
                      t(
                        'translation.UpgradePageCashRegisterJournalList.bullet1'
                      ),
                      t(
                        'translation.UpgradePageCashRegisterJournalList.bullet2'
                      ),
                      t(
                        'translation.UpgradePageCashRegisterJournalList.bullet3'
                      ),
                    ]}
                  />
                )
              }
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/cashRegister/settings/operators/:operatorId`}
          >
            <RouteContainer
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_CASH_REGISTER')}
              route={
                orgFeatures.planId === 'advanced' ? (
                  <PosSettingsOperators />
                ) : (
                  <UpgradePage
                    header={t(
                      'translation.UpgradePageCashRegisterJournalList.label-holidays'
                    )}
                    title={t(
                      'translation.UpgradePageCashRegisterJournalList.title'
                    )}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t(
                      'translation.UpgradePageCashRegisterJournalList.imageUrl'
                    )}
                    text={t(
                      'translation.UpgradePageCashRegisterJournalList.description'
                    )}
                    list={[
                      t(
                        'translation.UpgradePageCashRegisterJournalList.bullet1'
                      ),
                      t(
                        'translation.UpgradePageCashRegisterJournalList.bullet2'
                      ),
                      t(
                        'translation.UpgradePageCashRegisterJournalList.bullet3'
                      ),
                    ]}
                  />
                )
              }
            />
          </Route>
          <Route path={`/:orgId/:locationId/cashRegister/settings/operators`}>
            <RouteContainer
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_CASH_REGISTER')}
              route={
                orgFeatures.planId === 'advanced' ? (
                  <PosSettingsOperators />
                ) : (
                  <UpgradePage
                    header={t(
                      'translation.UpgradePageCashRegisterJournalList.label-holidays'
                    )}
                    title={t(
                      'translation.UpgradePageCashRegisterJournalList.title'
                    )}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t(
                      'translation.UpgradePageCashRegisterJournalList.imageUrl'
                    )}
                    text={t(
                      'translation.UpgradePageCashRegisterJournalList.description'
                    )}
                    list={[
                      t(
                        'translation.UpgradePageCashRegisterJournalList.bullet1'
                      ),
                      t(
                        'translation.UpgradePageCashRegisterJournalList.bullet2'
                      ),
                      t(
                        'translation.UpgradePageCashRegisterJournalList.bullet3'
                      ),
                    ]}
                  />
                )
              }
            />
          </Route>

          {/*CLIENT ROUTES*/}
          <Route path={`/:orgId/:locationId/clients/details/:id/attachments`}>
            <RouteContainer
              //showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_CLIENT_LIST')}
              route={<ClientAttachments />}
            />
          </Route>
          <Route path={`/:orgId/:locationId/clients/details/:id/reminders/new`}>
            <RouteContainer
              hasPermissionToAccess={hasPermission('ACCESS_CLIENT_LIST')}
              route={<ClientRemindersForm isNew />}
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/clients/details/:id/reminders/edit/:reminderId`}
          >
            <RouteContainer
              hasPermissionToAccess={hasPermission('ACCESS_CLIENT_LIST')}
              route={<ClientRemindersForm />}
            />
          </Route>
          <Route path={`/:orgId/:locationId/clients/details/:id/reminders`}>
            <RouteContainer
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_CLIENT_LIST')}
              route={<ClientRemindersListMobile />}
            />
          </Route>

          <Route path={`/:orgId/:locationId/clients/details/:id`}>
            <RouteContainer
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_CLIENT_LIST')}
              route={
                isMobile ? (
                  location?.bookingKind === 'GROUP' ? (
                    <ClientDetailsGroupMobile />
                  ) : (
                    <ClientDetailsMobile />
                  )
                ) : (
                  <></>
                )
              }
            />
          </Route>
          <Route path={`/:orgId/:locationId/clients/list/new/:id`}>
            <RouteContainer
              route={<ClientModal />}
              hasPermissionToAccess={hasPermission('ACCESS_CLIENT_LIST')}
            />
          </Route>
          <Route path={`/:orgId/:locationId/clients/list/edit/:id`}>
            <RouteContainer
              route={<EditClient />}
              hasPermissionToAccess={hasPermission('ACCESS_CLIENT_LIST')}
            />
          </Route>
          <Route path={`/:orgId/:locationId/clients/:clientsView`}>
            <RouteContainer
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_CLIENT_LIST')}
              route={
                orgFeatures.hasClients ? (
                  <ClientsList
                    isGroupLocation={location?.bookingKind === 'GROUP'}
                  />
                ) : (
                  <></>
                )
              }
            />
          </Route>

          {/*SERVICES ROUTES*/}
          <Route path={`/:orgId/:locationId/settings/language`}>
            <RouteContainer route={<LanguageModal />} />
          </Route>
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/holidays/new`}
          >
            <RouteContainer
              route={<NewHolidayContainer />}
              hasPermissionToAccess={hasPermission('ACCESS_LOCATION_SETTINGS')}
            />
          </Route>

          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/holidays/delete/holiday/:id`}
          >
            <RouteContainer
              route={
                orgFeatures.hasHolidays ? (
                  <HolidaysList />
                ) : (
                  <UpgradePage
                    isAvailableOnAdvancedPlan
                    header={t('translation.SettingsMobile.label-holidays')}
                    title={t('translation.UpgradePageHolidays.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t('translation.UpgradePageHolidays.imageUrl')}
                    text={t('translation.UpgradePageHolidays.description')}
                    list={[
                      t('translation.UpgradePageHolidays.bullet1'),
                      t('translation.UpgradePageHolidays.bullet2'),
                      t('translation.UpgradePageHolidays.bullet3'),
                    ]}
                  />
                )
              }
              hasPermissionToAccess={hasPermission('ACCESS_LOCATION_SETTINGS')}
              showMobileFooter={true}
            />
          </Route>

          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/holidays/edit/holiday/:id`}
          >
            <RouteContainer
              route={<EditHolidayContainer />}
              hasPermissionToAccess={hasPermission('ACCESS_LOCATION_SETTINGS')}
            />
          </Route>

          <Route path={`/:orgId/:locationId/settings/account/general`}>
            <RouteContainer showMobileFooter={false} route={<MyAccount />} />
          </Route>
          <Route path={`/:orgId/:locationId/settings/account/security`}>
            <RouteContainer
              showMobileFooter={false}
              route={<MyAccountSecurity />}
            />
          </Route>
          <Route path={`/:orgId/:locationId/settings/account/contact`}>
            <RouteContainer
              showMobileFooter={false}
              route={<MyAccountContact />}
            />
          </Route>
          {/*EMPLOYEES ROUTES*/}

          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/employees/edit/user/edit/:userId/:selectedUserView/:fromDate/:toDate`}
          >
            <RouteContainer
              route={
                <EditEmployee
                  setOpenBillingModal={setOpenBillingModal}
                  hasOnlineBooking={orgFeatures?.hasOnlineBooking}
                />
              }
              hasPermissionToAccess={hasPermission(
                'ACCESS_EMPLOYEES_RESOURCES'
              )}
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/employees/user/new/:id`}
          >
            <RouteContainer
              route={
                orgFeatures?.hasEmployees ? (
                  <NewEmployee
                    setOpenBillingModal={setOpenBillingModal}
                    hasOnlineBooking={orgFeatures?.hasOnlineBooking}
                  />
                ) : (
                  <UpgradePage
                    isAvailableOnAdvancedPlan
                    header={t('translation.SettingsMobile.label-employees')}
                    title={t('translation.UpgradePageEmployees.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t('translation.UpgradePageEmployees.imageUrl')}
                    text={t('translation.UpgradePageEmployees.description')}
                    list={[
                      t('translation.UpgradePageEmployees.bullet1'),
                      t('translation.UpgradePageEmployees.bullet2'),
                      t('translation.UpgradePageEmployees.bullet3'),
                    ]}
                  />
                )
              }
              hasPermissionToAccess={hasPermission(
                'ACCESS_EMPLOYEES_RESOURCES'
              )}
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/employees/user/edit/:id`}
          >
            <RouteContainer
              route={
                <EditEmployee
                  setOpenBillingModal={setOpenBillingModal}
                  hasOnlineBooking={orgFeatures?.hasOnlineBooking}
                />
              }
              hasPermissionToAccess={hasPermission(
                'ACCESS_EMPLOYEES_RESOURCES'
              )}
            />
          </Route>

          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/resources/edit/resource/edit/:resourceId/:selectedUserView/:fromDate/:toDate`}
          >
            <RouteContainer
              route={<EditResource />}
              hasPermissionToAccess={hasPermission(
                'ACCESS_EMPLOYEES_RESOURCES'
              )}
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/resources/resource/new`}
          >
            <RouteContainer
              route={<NewResource />}
              hasPermissionToAccess={hasPermission(
                'ACCESS_EMPLOYEES_RESOURCES'
              )}
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/resources/resource/edit/:id`}
          >
            <RouteContainer
              route={<EditResource />}
              hasPermissionToAccess={hasPermission(
                'ACCESS_EMPLOYEES_RESOURCES'
              )}
            />
          </Route>

          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/resources/resourceType/new`}
          >
            <RouteContainer
              route={<NewResourceType />}
              hasPermissionToAccess={hasPermission(
                'ACCESS_EMPLOYEES_RESOURCES'
              )}
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/resources/resourceType/edit/:id`}
          >
            <RouteContainer
              route={<EditResourceType />}
              hasPermissionToAccess={hasPermission(
                'ACCESS_EMPLOYEES_RESOURCES'
              )}
            />
          </Route>

          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/services/serviceType/:typeId/new`}
          >
            <RouteContainer
              route={
                <NewService
                  setOpenBillingModal={setOpenBillingModal}
                  hasOnlineBooking={orgFeatures?.hasOnlineBooking}
                />
              }
              hasPermissionToAccess={hasPermission('ACCESS_LOCATION_SETTINGS')}
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/services/serviceType/newServiceType`}
          >
            <RouteContainer
              route={<NewServiceType />}
              hasPermissionToAccess={hasPermission('ACCESS_PRICE_LIST')}
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/services/serviceType/:typeId/edit/:id`}
          >
            <RouteContainer
              route={
                <EditService
                  setOpenBillingModal={setOpenBillingModal}
                  hasOnlineBooking={orgFeatures?.hasOnlineBooking}
                />
              }
              hasPermissionToAccess={hasPermission('ACCESS_PRICE_LIST')}
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/services/serviceType/:typeId/edit`}
          >
            <RouteContainer
              route={<ServiceTypeEditor />}
              hasPermissionToAccess={hasPermission('ACCESS_PRICE_LIST')}
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/services/serviceType/:typeId`}
          >
            <RouteContainer
              route={<ServicesList />}
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_PRICE_LIST')}
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/services/serviceType`}
          >
            <RouteContainer
              route={<ServicesList />}
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_PRICE_LIST')}
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/products/productType/:typeId/new`}
          >
            <RouteContainer
              route={
                orgFeatures.planId === 'advanced' ? (
                  <NewProduct />
                ) : (
                  <UpgradePage
                    header={t('translation.Sidebar.label-products')}
                    title={t('translation.UpgradePageProducts.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t('translation.UpgradePageProducts.imageUrl')}
                    text={t('translation.UpgradePageProducts.description')}
                    list={[
                      t('translation.UpgradePageProducts.bullet1'),
                      t('translation.UpgradePageProducts.bullet2'),
                      t('translation.UpgradePageProducts.bullet3'),
                    ]}
                  />
                )
              }
              hasPermissionToAccess={hasPermission('ACCESS_PRICE_LIST')}
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/products/productType/:typeId/edit/:id`}
          >
            <RouteContainer
              route={
                orgFeatures.planId === 'advanced' ? (
                  <EditProduct />
                ) : (
                  <UpgradePage
                    header={t('translation.Sidebar.label-products')}
                    title={t('translation.UpgradePageProducts.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t('translation.UpgradePageProducts.imageUrl')}
                    text={t('translation.UpgradePageProducts.description')}
                    list={[
                      t('translation.UpgradePageProducts.bullet1'),
                      t('translation.UpgradePageProducts.bullet2'),
                      t('translation.UpgradePageProducts.bullet3'),
                    ]}
                  />
                )
              }
              hasPermissionToAccess={hasPermission('ACCESS_PRICE_LIST')}
            />
          </Route>
          <Route
            exact
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/products/productType/newProductType`}
          >
            <RouteContainer
              route={
                orgFeatures.planId === 'advanced' ? (
                  isMobile ? (
                    <NewProductType />
                  ) : (
                    <ProductList />
                  )
                ) : (
                  <UpgradePage
                    header={t('translation.Sidebar.label-products')}
                    title={t('translation.UpgradePageProducts.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t('translation.UpgradePageProducts.imageUrl')}
                    text={t('translation.UpgradePageProducts.description')}
                    list={[
                      t('translation.UpgradePageProducts.bullet1'),
                      t('translation.UpgradePageProducts.bullet2'),
                      t('translation.UpgradePageProducts.bullet3'),
                    ]}
                  />
                )
              }
              showMobileFooter={false}
              hasPermissionToAccess={hasPermission('ACCESS_PRICE_LIST')}
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/products/productType/:typeId`}
          >
            <RouteContainer
              route={
                orgFeatures.planId === 'advanced' ? (
                  <ProductList orgPlan={orgFeatures.planId} />
                ) : (
                  <UpgradePage
                    header={t('translation.Sidebar.label-products')}
                    title={t('translation.UpgradePageProducts.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t('translation.UpgradePageProducts.imageUrl')}
                    text={t('translation.UpgradePageProducts.description')}
                    list={[
                      t('translation.UpgradePageProducts.bullet1'),
                      t('translation.UpgradePageProducts.bullet2'),
                      t('translation.UpgradePageProducts.bullet3'),
                    ]}
                  />
                )
              }
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_PRICE_LIST')}
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/products/productType`}
          >
            <RouteContainer
              route={<ProductList />}
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_PRICE_LIST')}
            />
          </Route>

          {/* gallery */}
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/gallery/landing`}
          >
            <RouteContainer
              route={<GalleryImages section="HEADER" />}
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_LOCATION_SETTINGS')}
            />
          </Route>

          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/gallery/showcase`}
          >
            <RouteContainer
              route={<GalleryImages section="GALLERY" />}
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_LOCATION_SETTINGS')}
            />
          </Route>
          {/*  */}

          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/edit`}
          >
            <RouteContainer
              route={<LocationGeneralInfo isNew={false} />}
              hasPermissionToAccess={hasPermission('ACCESS_LOCATION_SETTINGS')}
            />
          </Route>

          {/* LOYALTY */}

          <Route
            path={`/:orgId/:locationId/loyalty/list/giftcard/:voucherType`}
          >
            <RouteContainer
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('VIEW_GIFTCARDS')}
              route={
                orgFeatures?.planId === 'advanced' &&
                !isLoadingFeatureFlags &&
                loyaltyFeatureFlag ? (
                  <GiftCardList />
                ) : isLoadingFeatureFlags ? (
                  <Loader isComponent />
                ) : (
                  <UpgradePage
                    header={t('translation.Sidebar.label-loyalty')}
                    title={t('translation.UpgradePageLoyalty.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={'/assets/illustrations/giftcards-hr.png'}
                    text={t('translation.UpgradePageLoyalty.description')}
                    list={[
                      t('translation.UpgradePageLoyalty.bullet1'),
                      t('translation.UpgradePageLoyalty.bullet2'),
                      t('translation.UpgradePageLoyalty.bullet3'),
                    ]}
                    showComingSoonInsteadOfUpgrade
                  />
                )
              }
            />
          </Route>

          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/loyalty/list/template/:voucherType`}
          >
            <RouteContainer
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_GIFTCARDS')}
              route={
                orgFeatures?.planId === 'advanced' &&
                !isLoadingFeatureFlags &&
                loyaltyFeatureFlag ? (
                  <TemplateList />
                ) : isLoadingFeatureFlags ? (
                  <Loader isComponent />
                ) : (
                  <UpgradePage
                    header={t('translation.Sidebar.label-loyalty')}
                    title={t('translation.UpgradePageLoyalty.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={'/assets/illustrations/giftcards-hr.png'}
                    text={t('translation.UpgradePageLoyalty.description')}
                    list={[
                      t('translation.UpgradePageLoyalty.bullet1'),
                      t('translation.UpgradePageLoyalty.bullet2'),
                      t('translation.UpgradePageLoyalty.bullet3'),
                    ]}
                    showComingSoonInsteadOfUpgrade
                  />
                )
              }
            />
          </Route>

          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/loyalty/new/template/:type`}
          >
            <RouteContainer
              route={
                orgFeatures?.planId === 'advanced' &&
                !isLoadingFeatureFlags &&
                loyaltyFeatureFlag ? (
                  <NewTemplate />
                ) : isLoadingFeatureFlags ? (
                  <Loader isComponent />
                ) : (
                  <UpgradePage
                    header={t('translation.Sidebar.label-loyalty')}
                    title={t('translation.UpgradePageLoyalty.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={'/assets/illustrations/giftcards-hr.png'}
                    text={t('translation.UpgradePageLoyalty.description')}
                    list={[
                      t('translation.UpgradePageLoyalty.bullet1'),
                      t('translation.UpgradePageLoyalty.bullet2'),
                      t('translation.UpgradePageLoyalty.bullet3'),
                    ]}
                    showComingSoonInsteadOfUpgrade
                  />
                )
              }
              hasPermissionToAccess={hasPermission('ACCESS_GIFTCARDS')}
            />
          </Route>

          <Route path={`/:orgId/:locationId/loyalty/new/giftcard/:type`}>
            <RouteContainer
              route={
                orgFeatures?.planId === 'advanced' &&
                !isLoadingFeatureFlags &&
                loyaltyFeatureFlag ? (
                  <NewGiftCard />
                ) : isLoadingFeatureFlags ? (
                  <Loader isComponent />
                ) : (
                  <UpgradePage
                    header={t('translation.Sidebar.label-loyalty')}
                    title={t('translation.UpgradePageLoyalty.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={'/assets/illustrations/giftcards-hr.png'}
                    text={t('translation.UpgradePageLoyalty.description')}
                    list={[
                      t('translation.UpgradePageLoyalty.bullet1'),
                      t('translation.UpgradePageLoyalty.bullet2'),
                      t('translation.UpgradePageLoyalty.bullet3'),
                    ]}
                    showComingSoonInsteadOfUpgrade
                  />
                )
              }
              hasPermissionToAccess={hasPermission('CREATE_GIFTCARDS')}
            />
          </Route>

          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/loyalty/edit/template/:id`}
          >
            <RouteContainer
              route={
                orgFeatures?.planId === 'advanced' &&
                !isLoadingFeatureFlags &&
                loyaltyFeatureFlag ? (
                  <EditTemplate />
                ) : isLoadingFeatureFlags ? (
                  <Loader isComponent />
                ) : (
                  <UpgradePage
                    header={t('translation.Sidebar.label-loyalty')}
                    title={t('translation.UpgradePageLoyalty.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={'/assets/illustrations/giftcards-hr.png'}
                    text={t('translation.UpgradePageLoyalty.description')}
                    list={[
                      t('translation.UpgradePageLoyalty.bullet1'),
                      t('translation.UpgradePageLoyalty.bullet2'),
                      t('translation.UpgradePageLoyalty.bullet3'),
                    ]}
                    showComingSoonInsteadOfUpgrade
                  />
                )
              }
              hasPermissionToAccess={hasPermission('ACCESS_GIFTCARDS')}
            />
          </Route>
          <Route path={`/:orgId/:locationId/loyalty/edit/giftCard/:id`}>
            <RouteContainer
              route={
                orgFeatures?.planId === 'advanced' &&
                !isLoadingFeatureFlags &&
                loyaltyFeatureFlag ? (
                  <EditGiftCard />
                ) : isLoadingFeatureFlags ? (
                  <Loader isComponent />
                ) : (
                  <UpgradePage
                    header={t('translation.Sidebar.label-loyalty')}
                    title={t('translation.UpgradePageLoyalty.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={'/assets/illustrations/giftcards-hr.png'}
                    text={t('translation.UpgradePageLoyalty.description')}
                    list={[
                      t('translation.UpgradePageLoyalty.bullet1'),
                      t('translation.UpgradePageLoyalty.bullet2'),
                      t('translation.UpgradePageLoyalty.bullet3'),
                    ]}
                    showComingSoonInsteadOfUpgrade
                  />
                )
              }
              hasPermissionToAccess={hasPermission('VIEW_GIFTCARDS')}
            />
          </Route>

          {/* END LOYALTY */}

          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/locationWorkHours`}
          >
            <RouteContainer
              route={
                isMobile ? <LocationWorkHoursMobile /> : <LocationWorkHours />
              }
              hasPermissionToAccess={hasPermission('ACCESS_LOCATION_SETTINGS')}
            />
          </Route>
          {/*  <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/onlineBooking/integrations`}
          >
            <RouteContainer
              route={<LocationBookingIntegrations />}
              hasPermissionToAccess={hasPermission('ACCESS_LOCATION_SETTINGS')}
            />
          </Route> */}
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/permissions`}
          >
            <RouteContainer
              route={<LocationUserPermissions />}
              hasPermissionToAccess={hasPermission('ACCESS_PERMISSIONS')}
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/changelog/:dateFrom/:dateTo`}
          >
            <RouteContainer
              route={
                orgFeatures?.planId === 'advanced' ? (
                  <LocationChangelogScreen />
                ) : (
                  <ChangelogUpgradePage
                    isAppointmentModal={false}
                    isLocationChangeLog
                    closeModal={() => ({})}
                  />
                )
              }
              hasPermissionToAccess={hasPermission('ACCESS_LOCATION_SETTINGS')}
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/onlineBooking/prepayments/stripe/refresh`}
          >
            <RouteContainer
              route={<LocationBookingPrepaymentsStripeRefresh />}
              hasPermissionToAccess={hasPermission('ACCESS_LOCATION_SETTINGS')}
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/onlineBooking/prepayments/stripe`}
          >
            <RouteContainer
              route={<LocationBookingPrepaymentsStripe />}
              hasPermissionToAccess={hasPermission('ACCESS_LOCATION_SETTINGS')}
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/onlineBooking/prepayments`}
          >
            <RouteContainer
              route={<LocationBookingPrepayments />}
              hasPermissionToAccess={hasPermission('ACCESS_LOCATION_SETTINGS')}
            />
          </Route>

          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/onlineBooking/notifications`}
          >
            <RouteContainer
              route={
                <LocationNotifications
                  hasOnlineBooking={orgFeatures?.hasOnlineBooking}
                  setOpenBillingModal={setOpenBillingModal}
                />
              }
              hasPermissionToAccess={hasPermission('ACCESS_LOCATION_SETTINGS')}
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/onlineBooking`}
          >
            <RouteContainer
              route={
                orgFeatures?.planId !== 'free' ? (
                  <LocationBooking setOpenBillingModal={setOpenBillingModal} />
                ) : (
                  <UpgradePage
                    isAvailableOnAdvancedPlan
                    header={t(
                      'translation.SettingsMobile.label-online-booking-settings'
                    )}
                    title={t(
                      'translation.UpgradePageOnlineBookingSettings.title'
                    )}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t(
                      'translation.UpgradePageOnlineBookingSettings.imageUrl'
                    )}
                    text={t(
                      'translation.UpgradePageOnlineBookingSettings.description'
                    )}
                    list={[
                      t('translation.UpgradePageOnlineBookingSettings.bullet1'),
                      t('translation.UpgradePageOnlineBookingSettings.bullet2'),
                      t('translation.UpgradePageOnlineBookingSettings.bullet3'),
                    ]}
                  />
                )
              }
              hasPermissionToAccess={hasPermission('ACCESS_LOCATION_SETTINGS')}
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/workHours/:dayId`}
          >
            <RouteContainer
              route={
                orgFeatures?.hasSchedule ? (
                  <WorkHours />
                ) : (
                  <UpgradePage
                    isAvailableOnAdvancedPlan
                    header={t('translation.SettingsMobile.schedule')}
                    title={t('translation.UpgradePageSchedule.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t('translation.UpgradePageSchedule.imageUrl')}
                    text={t('translation.UpgradePageSchedule.description')}
                    list={[
                      t('translation.UpgradePageSchedule.bullet1'),
                      t('translation.UpgradePageSchedule.bullet2'),
                      t('translation.UpgradePageSchedule.bullet3'),
                    ]}
                  />
                )
              }
              hasPermissionToAccess={hasPermission('ACCESS_SCHEDULE')}
            />
          </Route>

          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/employees/:selectedTab`}
          >
            <RouteContainer
              route={<EmployeesList />}
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission(
                'ACCESS_EMPLOYEES_RESOURCES'
              )}
            />
          </Route>

          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/resources/:selectedTab`}
          >
            <RouteContainer
              route={
                orgFeatures.planId === 'advanced' ? (
                  !isMobile ? (
                    <ResourcesList />
                  ) : (
                    <ResourcesListMobile />
                  )
                ) : (
                  <UpgradePage
                    header={t('translation.SettingsMobile.label-resources')}
                    title={t('translation.UpgradePageResources.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t('translation.UpgradePageResources.imageUrl')}
                    text={t('translation.UpgradePageResources.description')}
                    list={[
                      t('translation.UpgradePageResources.bullet1'),
                      t('translation.UpgradePageResources.bullet2'),
                      t('translation.UpgradePageResources.bullet3'),
                    ]}
                  />
                )
              }
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission(
                'ACCESS_EMPLOYEES_RESOURCES'
              )}
            />
          </Route>

          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/holidays`}
          >
            <RouteContainer
              hasPermissionToAccess={hasPermission('ACCESS_LOCATION_SETTINGS')}
              route={
                orgFeatures.hasHolidays ? (
                  <HolidaysList />
                ) : (
                  <UpgradePage
                    isAvailableOnAdvancedPlan
                    header={t('translation.SettingsMobile.label-holidays')}
                    title={t('translation.UpgradePageHolidays.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t('translation.UpgradePageHolidays.imageUrl')}
                    text={t('translation.UpgradePageHolidays.description')}
                    list={[
                      t('translation.UpgradePageHolidays.bullet1'),
                      t('translation.UpgradePageHolidays.bullet2'),
                      t('translation.UpgradePageHolidays.bullet3'),
                    ]}
                  />
                )
              }
              showMobileFooter={true}
            />
          </Route>

          <Route
            path={`/:orgId/:locationId/settings/organization/:action/professions/edit/profession/:id`}
          >
            <RouteContainer
              route={<EditProfessionContainer />}
              hasPermissionToAccess={hasPermission(
                'ACCESS_EMPLOYEES_RESOURCES'
              )}
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/professions/new/profession/:id`}
          >
            <RouteContainer
              route={<NewProfessionContainer />}
              hasPermissionToAccess={hasPermission(
                'ACCESS_EMPLOYEES_RESOURCES'
              )}
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/professions/delete/profession/:id`}
          >
            <RouteContainer
              hasPermissionToAccess={hasPermission(
                'ACCESS_EMPLOYEES_RESOURCES'
              )}
              route={
                orgFeatures.hasProfessions ? (
                  <ProfessionsList />
                ) : (
                  <UpgradePage
                    isAvailableOnAdvancedPlan
                    header={t('translation.SettingsMobile.label-profession')}
                    title={t('translation.UpgradePageSpecialisation.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t('translation.UpgradePageSpecialisation.imageUrl')}
                    text={t(
                      'translation.UpgradePageSpecialisation.description'
                    )}
                    list={[
                      t('translation.UpgradePageSpecialisation.bullet1'),
                      t('translation.UpgradePageSpecialisation.bullet2'),
                      t('translation.UpgradePageSpecialisation.bullet3'),
                    ]}
                  />
                )
              }
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/settings/organization/locations/:selectedLocationId/professions`}
          >
            <RouteContainer
              hasPermissionToAccess={hasPermission(
                'ACCESS_EMPLOYEES_RESOURCES'
              )}
              route={
                orgFeatures.hasProfessions ? (
                  <ProfessionsList />
                ) : (
                  <UpgradePage
                    isAvailableOnAdvancedPlan
                    header={t('translation.SettingsMobile.label-profession')}
                    title={t('translation.UpgradePageSpecialisation.title')}
                    setOpenBillingModal={setOpenBillingModal}
                    image={t('translation.UpgradePageSpecialisation.imageUrl')}
                    text={t(
                      'translation.UpgradePageSpecialisation.description'
                    )}
                    list={[
                      t('translation.UpgradePageSpecialisation.bullet1'),
                      t('translation.UpgradePageSpecialisation.bullet2'),
                      t('translation.UpgradePageSpecialisation.bullet3'),
                    ]}
                  />
                )
              }
            />
          </Route>

          <Route
            path={`/:orgId/:locationId/settings/organization/locations/new`}
          >
            <RouteContainer
              route={<NewLocation />}
              orgSidebar
              hasPermissionToAccess={hasPermission('ACCESS_LOCATION_SETTINGS')}
            />
          </Route>
          <PrivateRoute path="/:orgId/:locationId/settings/organization/account/general">
            <RouteContainer route={<MyAccount />} orgSidebar />
          </PrivateRoute>
          <PrivateRoute path="/:orgId/:locationId/settings/organization/account/security">
            <RouteContainer route={<MyAccountSecurity />} orgSidebar />
          </PrivateRoute>
          <PrivateRoute path="/:orgId/:locationId/settings/organization/account/contact">
            <RouteContainer route={<MyAccountContact />} orgSidebar />
          </PrivateRoute>
          <Route path={`/:orgId/:locationId/settings/organization/locations`}>
            <RouteContainer
              route={
                <LocationsContainer setOpenBillingModal={setOpenBillingModal} />
              }
              orgSidebar
            />
          </Route>
          <Route path={`/:orgId/:locationId/settings/organization/edit`}>
            <RouteContainer
              route={<Organization />}
              orgSidebar
              hasPermissionToAccess={hasPermission('ACCESS_ORG_INFO')}
            />
          </Route>
          <Route path={`/:orgId/:locationId/settings/organization/billing`}>
            <RouteContainer
              route={<BillingContainer />}
              orgSidebar
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_BILLING')}
            />
          </Route>
          <Route path={`/:orgId/:locationId/settings/branch`}>
            <RouteContainer
              route={<BranchSettings />}
              showMobileFooter={true}
            />
          </Route>
          <Route path={`/:orgId/:locationId/settings/reports`}>
            <RouteContainer
              route={<ReportsMenuMobile />}
              showMobileFooter={true}
            />
          </Route>
          <Route path={`/:orgId/:locationId/settings`}>
            <RouteContainer
              route={<SettingsMobile />}
              showMobileFooter={true}
            />
          </Route>

          {/* WMS */}
          <Route
            path={`/:orgId/:locationId/businessPartners/edit/:businessPartnerId`}
          >
            <RouteContainer
              route={<BusinessPartnersEditScreen />}
              hasPermissionToAccess={hasPermission('ACCESS_WMS')}
            />
          </Route>
          <Route path={`/:orgId/:locationId/businessPartners/new`}>
            <RouteContainer
              route={<BusinessPartnersCreateScreen />}
              hasPermissionToAccess={hasPermission('ACCESS_WMS')}
            />
          </Route>
          <Route path={`/:orgId/:locationId/businessPartners`}>
            <RouteContainer
              route={<BusinessPartnersListScreen />}
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_WMS')}
            />
          </Route>

          <Route path={`/:orgId/:locationId/wms/goodsReceived/new`}>
            <RouteContainer
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_WMS')}
              route={<NewGoodsReceived />}
            />
          </Route>
          <Route path={`/:orgId/:locationId/wms/goodsRemoved/new`}>
            <RouteContainer
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_WMS')}
              route={<NewGoodsRemoved />}
            />
          </Route>
          <Route path={`/:orgId/:locationId/wms/goodsReceived/edit/:orderId`}>
            <RouteContainer
              showMobileFooter={false}
              hasPermissionToAccess={hasPermission('ACCESS_WMS')}
              route={<EditGoodsReceived />}
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/wms/report/:report/:warehouseId/:dateFrom/:dateTo`}
          >
            <RouteContainer
              showMobileFooter={false}
              route={<GoodsReport />}
              hasPermissionToAccess={hasPermission('ACCESS_WMS')}
            />
          </Route>
          <Route path={`/:orgId/:locationId/wms/report/priceChange/:orderId`}>
            <RouteContainer
              showMobileFooter={false}
              hasPermissionToAccess={hasPermission('ACCESS_WMS')}
              route={<PriceChangeReport />}
            />
          </Route>
          <Route
            path={`/:orgId/:locationId/wms/goodsReceived/productStock/:warehouseId/:productId`}
          >
            <RouteContainer
              showMobileFooter={false}
              hasPermissionToAccess={hasPermission('ACCESS_WMS')}
              route={<ProductStockReport />}
            />
          </Route>
          <Route path={`/:orgId/:locationId/wms/goodsReceived`}>
            <RouteContainer
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_WMS')}
              route={<GoodsReceivedList />}
            />
          </Route>
          <Route path={`/:orgId/:locationId/wms/goodsReleased/edit/:orderId`}>
            <RouteContainer
              showMobileFooter={false}
              hasPermissionToAccess={hasPermission('ACCESS_WMS')}
              route={<EditGoodsReleased />}
            />
          </Route>
          <Route path={`/:orgId/:locationId/wms/goodsRemoved/edit/:orderId`}>
            <RouteContainer
              showMobileFooter={false}
              hasPermissionToAccess={hasPermission('ACCESS_WMS')}
              route={<EditGoodsReleased />}
            />
          </Route>
          <Route path={`/:orgId/:locationId/wms/goodsReleased`}>
            <RouteContainer
              showMobileFooter={true}
              hasPermissionToAccess={hasPermission('ACCESS_WMS')}
              route={<GoodsReleasedList />}
            />
          </Route>
          <Route path={`/:orgId/:locationId/wms/currentStock/:warehouseId`}>
            <RouteContainer showMobileFooter={true} route={<CurrentStock />} />
          </Route>
          <Route
            path={`/:orgId/:locationId/wms/salesInPeriod/:warehouseId/:dateFrom/:dateTo`}
          >
            <RouteContainer
              showMobileFooter={true}
              route={<SalesInPeriod />}
              hasPermissionToAccess={hasPermission('ACCESS_WMS')}
            />
          </Route>

          {redirectToOrgId && redirectToLocationId ? (
            <Redirect
              from="/"
              to={`/${redirectToOrgId}/${redirectToLocationId}/events/day/${moment().format(
                'YYYY-MM-DD'
              )}/calendar/${isMobile ? defaultCalendarView : 'day'}`}
            />
          ) : (
            <Redirect from="/" to={`/login`} />
          )}
          <Route component={NotFound} />
        </Switch>
      </Suspense>
      {isLocked
        ? createPortal(
            <div className="z-[1000000000] flex top-0 left-0 h-full absolute w-full bg-slate-50">
              <LockScreen
                onUnlock={(userId: string) => {
                  unlockApp(userId)
                  reset()
                }}
              />
            </div>,
            document.body
          )
        : null}
    </div>
  )
}
