import { useHistory } from 'react-router-dom'
import { useHeader } from 'src/mobile/Header'
import { Button, Form, FormField, Loader } from 'components'
import React, { useContext, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useSdk } from 'sdk'
import { MobileContext } from 'src/Context'
import { trpc } from 'src/trpc'
import { FieldColumn, FieldRow } from 'views/Settings/styles'
import { getHoursInDay } from 'src/helpers'
import moment from 'moment'
import { usePopup } from 'src/sdk/tw/Popup/usePopup'
import {
  FormPageContainer,
  FormContainer,
  FormFooterButtonsContainerMobile,
} from 'src/styles'

import { FormFieldsWrapper } from 'components/Form/Form'
import { useFooter } from 'src/mobile/Footer'

type ClientRemindersFormProps = {
  isNew?: boolean
}
export const ClientRemindersForm = ({ isNew }: ClientRemindersFormProps) => {
  const { t, appServices, navigateTo } = useSdk()
  const history = useHistory()
  useHeader({
    content: isNew
      ? t('translation.ClientReminders.new-reminder')
      : t('translation.ClientReminders.reminder-label'),
    onGoBack: () => history.goBack(),
  })

  useFooter({
    showAddButton: false,
  })

  const params = useParams<any>()
  const selectedReminderId = !isNew && BigInt(params.reminderId)

  const isMobile = useContext(MobileContext)
  const formRef = useRef<any>()

  const { locationId, orgId } = useParams<any>()

  const [isBusy, setIsBusy] = useState(false)

  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  const createReminderMutation = trpc.reminder_create.useMutation()
  const cancelReminderMutation = trpc.reminder_cancel.useMutation()

  const { data: reminderData, isLoading: reminderLoading } =
    trpc.reminder_get.useQuery(
      {
        id: BigInt(selectedReminderId || 0),
        orgId,
        locationId: BigInt(locationId),
      },
      {
        enabled: !!selectedReminderId,
      }
    )

  const { data: remindersConfigData, isLoading: remindersConfigLoading } =
    trpc.remindersConfig_get.useQuery({
      orgId,
      locationId: BigInt(locationId),
    })

  const reminderTypesOptions =
    selectedReminderId && reminderData
      ? [
          ...(remindersConfigData || []),
          {
            id: null,
            name: t('translation.ClientReminders.appointment-notification'),
          },
        ]
      : remindersConfigData || []
  //region INITIAL VALUES & VALUE CHANGE

  const initialValues = isNew
    ? {
        reminderTime: '08:00',
        reminderConfigId:
          remindersConfigData?.length === 1
            ? remindersConfigData[0]?.id
            : undefined,
      }
    : {
        reminderConfigId: reminderData?.remindersConfigId,

        reminderDate: moment(reminderData?.notificationTimeUtc).format(
          'YYYY-MM-DD'
        ),
        reminderTime: moment(reminderData?.notificationTimeUtc).format('HH:mm'),
        messageToClient:
          JSON.parse(reminderData?.meta || 'null')?.messageToClient || '',
      }

  //endregion
  const trpcContext = trpc.useContext()

  //endregion

  const handleCancelReminder = async () => {
    if (!selectedReminderId) return
    setIsBusy(true)
    try {
      const result = await cancelReminderMutation.mutateAsync({
        reminderId: BigInt(selectedReminderId),
      })

      trpcContext.reminders_get.invalidate()
      appServices.toast.success(
        t('translation.ClientReminders.cancelation-success-toast')
      )
      navigateTo.clientReminders(params)
    } catch (err) {
      appServices.toast.danger(
        t('translation.ClientReminders.cancelation-error-toast')
      )
    } finally {
      setIsBusy(false)
    }
  }
  //region SUBMIT
  const handleSubmit = async (values, form) => {
    setIsBusy(true)
    try {
      const result = await createReminderMutation.mutateAsync({
        remindersConfigId: BigInt(values?.reminderConfigId),
        reminderDate: moment(values?.reminderDate).format('YYYY-MM-DD'),
        reminderTime: values?.reminderTime,
        clientId: BigInt(params.id),
        messageToClient: values?.messageToClient || '',
      })
      trpcContext.reminders_get.invalidate()

      appServices.toast.success(
        t('translation.ClientReminders.reminder-successfully-created')
      )
      navigateTo.clientReminders(params)
    } catch (err) {
      appServices.toast.danger(t('translation.ClientReminders.error-toast'))
    } finally {
      setIsBusy(false)
    }
  }
  //endregion
  const selectedReminderTime = moment(reminderData?.notificationTimeUtc).format(
    'HH:mm'
  )
  const hoursInDay = getHoursInDay(15)

  const reminderTimeOptions =
    selectedReminderId && reminderData
      ? hoursInDay?.find(x => x.id === selectedReminderTime)
        ? hoursInDay
        : [
            { id: selectedReminderTime, title: selectedReminderTime },
            ...hoursInDay,
          ]
      : hoursInDay

  const isLoading =
    remindersConfigLoading || (selectedReminderId && reminderLoading) || isBusy

  return (
    <>
      <FormPageContainer>
        {isLoading ? (
          <Loader isComponent />
        ) : (
          <Form
            initialValues={initialValues}
            validationSchema={undefined}
            isInitialValid={true}
            onSubmit={handleSubmit}
          >
            {form => {
              formRef.current = form
              return (
                <ClientRemindersFormFields
                  selectedReminderId={selectedReminderId}
                  handleCancelReminder={handleCancelReminder}
                  reminderData={reminderData}
                  reminderTimeOptions={reminderTimeOptions}
                  reminderTypesOptions={reminderTypesOptions}
                />
              )
            }}
          </Form>
        )}
      </FormPageContainer>
      {isMobile ? (
        <FormFooterButtonsContainerMobile>
          <Button
            footerButton
            buttonType={'text'}
            name="closeForm"
            type={'button'}
            label={t('translation.AppointmentModal.button-close')}
            onClick={() => navigateTo.clientReminders(params)}
          />
          {isNew && (
            <Button
              onClick={async () => {
                Object.values(formRef.current.errors).length
                  ? setSelectedTabIndex(0)
                  : formRef.current.submitForm()
              }}
              buttonType={'primary'}
              name="saveChanges"
              label={t('translation.AppointmentModal.button-save')}
            />
          )}
        </FormFooterButtonsContainerMobile>
      ) : null}
    </>
  )
}

export const ClientRemindersFormFields = ({
  selectedReminderId,
  reminderData,
  handleCancelReminder,
  reminderTypesOptions,
  reminderTimeOptions,
}) => {
  const { showPopup } = usePopup()
  const { t } = useSdk()

  return (
    <FormFieldsWrapper>
      <FormContainer>
        {selectedReminderId && reminderData?.status === 'SCHEDULED' ? (
          <div className="flex justify-end">
            <Button
              onClick={async e => {
                e.preventDefault()
                showPopup({
                  titleText: t(
                    'translation.ClientReminders.cancelation-confirm'
                  ),
                  onConfirm: handleCancelReminder,
                })
              }}
              buttonType={'secondaryRed'}
              name={'cancel'}
              type="button"
              disabled={false}
              label={t('translation.AppointmentModal.button-cancelRequest')}
            />
          </div>
        ) : null}
        <FieldRow>
          <FieldColumn>
            <FormField.Select
              label={t('translation.ClientReminders.reminder-type')}
              name="reminderConfigId"
              isClearable={false}
              parseValue={val => reminderTypesOptions?.find(e => e.id === val)}
              isDisabled={!!selectedReminderId}
              formatValue={val => val?.id}
              options={reminderTypesOptions}
              isSearchable={false}
            />
          </FieldColumn>
        </FieldRow>
        <FieldRow>
          <FieldColumn>
            <FormField.DateInput
              label={t('translation.ClientReminders.reminder-date')}
              name="reminderDate"
              disabled={!!selectedReminderId}
              min={
                selectedReminderId ? undefined : moment().format('YYYY-MM-DD')
              }
              displayFormat="DD.MM.YYYY."
            />
          </FieldColumn>
          <FieldColumn>
            <FormField.Select
              label={t('translation.ClientReminders.reminder-time')}
              name="reminderTime"
              isClearable={false}
              isDisabled={!!selectedReminderId}
              parseValue={val => reminderTimeOptions?.find(e => e.id === val)}
              styles={{
                control: styles => ({
                  ...styles,
                  height: '46px !important',
                }),
              }}
              formatValue={val => val?.id}
              options={reminderTimeOptions || []}
              isSearchable={true}
            />
          </FieldColumn>
        </FieldRow>

        <FieldRow>
          <FieldColumn>
            <FormField.TextArea
              rows={2}
              disabled={!!selectedReminderId}
              label={t('translation.ClientReminders.email-message')}
              name="messageToClient"
            />
          </FieldColumn>
        </FieldRow>
      </FormContainer>
    </FormFieldsWrapper>
  )
}
