import React, { useEffect } from 'react'
import { FormContentContainer, Button, FormField } from 'components'
import { useSdk } from 'sdk'
import { PrepaymentsUpgradePage } from 'components/UpgradePage/UpgradePage'
import { FieldRow, FieldColumn } from 'views/Settings/styles'

import { Email } from '../../../components/Icon/Icons/Email'
import { PhoneBold } from '../../../components/Icon/Icons/PhoneBold'
import moment from 'moment'
import styled from 'styled-components'
import { icons } from 'components/Icon/index'
import { HelpHint } from 'src/styles'
import { Loader } from 'components'
import {
  Text,
  Wrapper,
  TitleRow,
  Container,
  Row,
  Label,
  Column,
} from 'src/views/AppointmentModal/components/UpdateStatusModal'
import { EventContainer, EventDateTitle } from 'views/AppointmentModal/styles'
import { roundNumberWithTwoDecimals } from 'src/helpers'
import { useFormikContext } from 'formik'

type TPaymentLinkWrapper = { complexBooking: boolean }
const PaymentLinkWrapper = styled.div<TPaymentLinkWrapper>`
  width: 100%;
  max-width: 450px;
  margin-left: ${props => (props.complexBooking ? '0' : '20px')};
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${props => props.theme.colors.lightBackground};
  border-radius: 4px;
  align-items: center;
`

const PaymentLink = styled.p`
  margin-right: 20px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`

export const AppointmentHotelPaymentNotification = ({
  flattenedServices,
  values,
  closeModal,
  totalServicesPrice,
  location,
  org,
  isBusy,
  hasPrepaymentsCredentials,
  stripeAccountInfo,
  hasInvoicingData,
}) => {
  const { t, isMobile, appServices } = useSdk()
  const { setFieldValue } = useFormikContext()

  const advanceAmountPercentage =
    location?.prepaymentsConfig?.advanceAmountPercentage
  const advanceAmount = roundNumberWithTwoDecimals(
    (totalServicesPrice * advanceAmountPercentage) / 100
  )

  if (org?.subscriptionPlan !== 'advanced') {
    return (
      <PrepaymentsUpgradePage
        isAppointmentModal
        complexBooking={true}
        closeModal={closeModal}
        footerContent={<></>}
      />
    )
  }
  useEffect(() => {
    if (
      location &&
      values?.client?.email &&
      (!values?.posPaymentRequest ||
        values?.posPaymentRequest?.status === 'CANCELED')
    ) {
      setFieldValue(
        'cancelAppointmentOnExpiredPaymentRequest',
        location?.prepaymentsConfig?.cancelAppointmentOnExpiredPaymentRequest
      )
    }
  }, [location, values])

  return (
    <>
      {hasPrepaymentsCredentials ? (
        <>
          <Wrapper>
            {isBusy ? <Loader isComponent /> : null}
            <TitleRow>
              <EventDateTitle>
                {t('translation.AppointmentModal.paymentRequest')}
              </EventDateTitle>
            </TitleRow>
            <Container>
              <Column>
                <Row>
                  <Label>{t('translation.UpdateStatusModal.client')} </Label>
                  <Text>
                    {values?.client?.firstName
                      ? values?.client?.firstName +
                        ' ' +
                        values?.client?.lastName
                      : t('translation.UpdateStatusModal.noClient')}
                  </Text>
                </Row>
                {values?.client?.email && (
                  <Row>
                    <Label>{t('translation.UpdateStatusModal.email')} </Label>
                    <Text
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        textDecoration: 'underline',
                        color: '#6a4ee1',
                      }}
                    >
                      <Email size={'smaller'} style={{ marginRight: 10 }} />
                      <a
                        href={'mailto:' + values?.client?.email}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {values?.client?.email}
                      </a>
                    </Text>
                  </Row>
                )}
                {values?.client?.mobilePhone && (
                  <Row>
                    <Label>
                      {t('translation.SelectedLocation.label-phone')}{' '}
                    </Label>
                    <Text
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        textDecoration: 'underline',
                        color: '#6a4ee1',
                      }}
                    >
                      <PhoneBold size={'smaller'} style={{ marginRight: 10 }} />
                      <a
                        href={'tel:' + values?.client?.mobilePhone}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {values?.client?.mobilePhone}
                      </a>
                    </Text>
                  </Row>
                )}
              </Column>

              <Column>
                <Row>
                  <Label>{t('translation.UpdateStatusModal.date')}</Label>
                  <Text>
                    {moment(values.date).format(
                      t('regionalFormats.date.momentFormatDate')
                    )}
                  </Text>
                </Row>
                <Row>
                  <Label>
                    {t('translation.AppointmentModal.label-service')}:
                  </Label>
                  <Text>
                    {
                      flattenedServices?.find(
                        x => x?.id === values?.serviceId?.toString()
                      )?.name
                    }
                  </Text>
                </Row>
                {values?.posPaymentRequest?.paymentRequestExpirationDate ? (
                  <Row>
                    <Label>
                      {t('translation.UpdateStatusModal.payment-deadline')}
                    </Label>
                    <Text style={{ fontWeight: 'bold' }}>
                      {moment(
                        values?.posPaymentRequest?.paymentRequestExpirationDate
                      ).format(t('regionalFormats.date.momentFormatDateTime'))}
                    </Text>
                  </Row>
                ) : null}
                {values?.posPaymentRequest?.amount ? (
                  <Row>
                    <Label>
                      {t('translation.AppointmentModal.paymentRequest-total')}
                    </Label>
                    <Text style={{ fontWeight: 'bold' }}>
                      {parseFloat(
                        values?.posPaymentRequest?.amount || '0'
                      )?.toFixed(2)}{' '}
                      {org?.currency?.currencyCode}
                    </Text>
                  </Row>
                ) : (
                  <Row>
                    <Label>
                      {t('translation.AppointmentModal.paymentRequest-total')}
                    </Label>
                    <Text style={{ fontWeight: 'bold' }}>
                      {advanceAmount?.toFixed(2)} {org?.currency?.currencyCode}
                    </Text>
                  </Row>
                )}
                {values.notes && (
                  <Row>
                    <Label>{t('translation.UpdateStatusModal.note')}</Label>
                    <Text>{values.notes}</Text>
                  </Row>
                )}
              </Column>
            </Container>
          </Wrapper>

          <FormContentContainer>
            <EventContainer style={{ minHeight: 220 }}>
              <FieldRow style={{ marginBottom: '50px' }}>
                <FieldColumn>
                  <p>
                    {values?.posPaymentRequest?.status === 'PENDING_PAYMENT'
                      ? t(
                          'translation.AppointmentModal.paymentRequest-text-already-send',
                          {
                            email: values?.client?.email,
                          }
                        )
                      : t(
                          'translation.AppointmentModal.paymentRequest-text-send',
                          {
                            email: values?.client?.email,
                          }
                        )}
                  </p>
                </FieldColumn>
              </FieldRow>

              {!values?.posPaymentRequest ||
              values?.posPaymentRequest?.status === 'CANCELED' ? (
                <FieldRow style={{ marginBottom: '50px', marginTop: '-20px' }}>
                  <FieldColumn>
                    <FormField.Checkbox
                      label={t(
                        'translation.AppointmentModal.cancelBookingOnExpired'
                      )}
                      name="cancelAppointmentOnExpiredPaymentRequest"
                      type={'checkbox'}
                      helpText={t(
                        'translation.AppointmentModal.cancelBookingOnExpiredTooltip'
                      )}
                    />
                  </FieldColumn>
                </FieldRow>
              ) : null}

              {values?.posPaymentRequest?.status === 'PENDING_PAYMENT' ? (
                isMobile ? (
                  <FieldRow>
                    <FieldColumn>
                      {t('translation.AppointmentModal.paymentLink')}:
                    </FieldColumn>
                    <FieldColumn>
                      <PaymentLinkWrapper complexBooking>
                        <PaymentLink>
                          {values?.posPaymentRequest?.paymentRequestUrl}
                        </PaymentLink>
                        <Button
                          buttonType={'text'}
                          hasIconOnly={true}
                          tooltip={t(
                            'translation.AppointmentModal.tooltip-save-to-clipboard'
                          )}
                          iconComponent={<icons.Copy size={'small'} />}
                          size={'small'}
                          onClick={e => {
                            e.preventDefault()
                            navigator.clipboard.writeText(
                              values?.posPaymentRequest?.paymentRequestUrl
                            )
                            appServices.toast.success(
                              t(
                                'translation.AppointmentModal.toast-link-saved-to-clipboard'
                              )
                            )
                          }}
                        />
                      </PaymentLinkWrapper>
                    </FieldColumn>
                  </FieldRow>
                ) : (
                  <FieldRow>
                    <FieldColumn
                      style={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      {t('translation.AppointmentModal.paymentLink')}
                      <PaymentLinkWrapper complexBooking={true}>
                        <PaymentLink>
                          {values?.posPaymentRequest?.paymentRequestUrl}
                        </PaymentLink>
                        <Button
                          buttonType={'text'}
                          hasIconOnly={true}
                          tooltip={t(
                            'translation.AppointmentModal.tooltip-save-to-clipboard'
                          )}
                          iconComponent={<icons.Copy size={'small'} />}
                          size={'small'}
                          onClick={e => {
                            e.preventDefault()
                            navigator.clipboard.writeText(
                              values?.posPaymentRequest?.paymentRequestUrl
                            )
                            appServices.toast.success(
                              t(
                                'translation.AppointmentModal.toast-link-saved-to-clipboard'
                              )
                            )
                          }}
                        />
                      </PaymentLinkWrapper>
                    </FieldColumn>
                  </FieldRow>
                )
              ) : null}

              {!values?.client ? (
                <div className="mb-[10px] flex items-center mt-[50px]">
                  <span className="text-[16px] mr-[10px]">
                    <icons.Error
                      size="small"
                      color="red"
                      data-cy={'isError-merchantId'}
                    />
                  </span>
                  <span className="text-[14px] text-zoyya-secondaryDarker">
                    {t('translation.AppointmentModal.label-client-missing')}
                  </span>
                </div>
              ) : !values?.client?.email ? (
                <div className="mb-[10px] flex items-center mt-[50px]">
                  <span className="text-[16px] mr-[10px]">
                    <icons.Error
                      size="small"
                      color="red"
                      data-cy={'isError-merchantId'}
                    />
                  </span>
                  <span className="text-[14px] text-zoyya-secondaryDarker">
                    {t(
                      'translation.AppointmentModal.label-client-missing-email'
                    )}
                  </span>
                </div>
              ) : null}

              {!values?.serviceId ? (
                <div
                  className={`mb-[10px] flex items-center ${
                    !values?.client || !values?.client?.email ? '' : 'mt-[50px]'
                  }`}
                >
                  <span className="text-[16px] mr-[10px]">
                    <icons.Error
                      size="small"
                      color="red"
                      data-cy={'isError-merchantId'}
                    />
                  </span>
                  <span className="text-[14px] text-zoyya-secondaryDarker">
                    {t('translation.AppointmentModal.label-service-missing')}
                  </span>
                </div>
              ) : null}
            </EventContainer>
          </FormContentContainer>
        </>
      ) : (
        <>
          <TitleRow>
            <EventDateTitle>
              {t('translation.AppointmentModal.paymentRequest')}
            </EventDateTitle>
          </TitleRow>
          <Wrapper style={{ padding: '20px' }}>
            {org?.prepaymentsProvider === 'STRIPE' ? (
              <>
                <div className="mb-[10px] flex items-center">
                  <span className="text-[16px] mr-[10px]">
                    {org?.stripeConnectAccountId &&
                    stripeAccountInfo?.hasFinishedOnboarding &&
                    stripeAccountInfo?.hasChargesEnabled ? (
                      <icons.CheckBox
                        size="small"
                        color="green"
                        data-cy={'isValid-apiKey'}
                      />
                    ) : (
                      <icons.Error
                        size="small"
                        color="red"
                        data-cy={'isError-apiKey'}
                      />
                    )}
                  </span>
                  <span className="text-[14px] text-zoyya-secondaryDarker">
                    {t(
                      'translation.Prepayments.label-stripe-onboarding-completed'
                    )}
                  </span>
                </div>
                <div className="mb-[10px] flex items-center">
                  <span className="text-[16px] mr-[10px]">
                    {hasInvoicingData ? (
                      <icons.CheckBox
                        size="small"
                        color="green"
                        data-cy={'isValid-apiKey'}
                      />
                    ) : (
                      <icons.Error
                        size="small"
                        color="red"
                        data-cy={'isError-apiKey'}
                      />
                    )}
                  </span>
                  <span className="text-[14px] text-zoyya-secondaryDarker">
                    {t('translation.Prepayments.label-fiscalization')}
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="mb-[10px] flex items-center">
                  <span className="text-[16px] mr-[10px]">
                    {org?.hasVivaMerchantId ? (
                      <icons.CheckBox
                        size="small"
                        color="green"
                        data-cy={'isValid-merchantId'}
                      />
                    ) : (
                      <icons.Error
                        size="small"
                        color="red"
                        data-cy={'isError-merchantId'}
                      />
                    )}
                  </span>
                  <span className="text-[14px] text-zoyya-secondaryDarker">
                    {t('translation.Prepayments.label-merchantId')}
                  </span>
                </div>

                <div className="mb-[10px] flex items-center">
                  <span className="text-[16px] mr-[10px]">
                    {org?.hasVivaApiKey ? (
                      <icons.CheckBox
                        size="small"
                        color="green"
                        data-cy={'isValid-apiKey'}
                      />
                    ) : (
                      <icons.Error
                        size="small"
                        color="red"
                        data-cy={'isError-apiKey'}
                      />
                    )}
                  </span>
                  <span className="text-[14px] text-zoyya-secondaryDarker">
                    {t('translation.Prepayments.label-apiKey')}
                  </span>
                </div>

                <div className="mb-[10px] flex items-center">
                  <span className="text-[16px] mr-[10px]">
                    {hasInvoicingData ? (
                      <icons.CheckBox
                        size="small"
                        color="green"
                        data-cy={'isValid-apiKey'}
                      />
                    ) : (
                      <icons.Error
                        size="small"
                        color="red"
                        data-cy={'isError-apiKey'}
                      />
                    )}
                  </span>
                  <span className="text-[14px] text-zoyya-secondaryDarker">
                    {t('translation.Prepayments.label-fiscalization')}
                  </span>
                </div>
              </>
            )}

            <div className="mb-[20px] flex items-center">
              <HelpHint>
                <icons.Info size="small" style={{ marginRight: '10px' }} />
                {t('translation.LocationBookingPrepayments.viva-helpText')}
                {/* <a
                href={'mailto:support@zoyya.com'}
                target="_blank"
                rel="noopener noreferrer"
              >
                {'support@zoyya.com'}.
              </a> */}
              </HelpHint>
            </div>
          </Wrapper>
        </>
      )}
    </>
  )
}
