import { Button } from 'components'
import * as PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { MobileContext } from 'src/Context'
import { getStatusColor, getStatusIcon, getStatusLabel } from 'src/helpers'
import { useACL } from 'src/sdk/acl'
import { useAppServices } from 'src/sdk/appServices'
import styled, { ThemeContext } from 'styled-components'
import { device } from '../../../constants'
import { useSdk } from '../../../sdk'

type THeader = {
  values: any
  options: any
  status: string
  handleStatusUpdate: any
}
export const AppointmentHotelStatusHeader = (props: THeader) => {
  const { t } = useSdk()
  const { options, status, handleStatusUpdate } = props

  const selectedTheme = useContext(ThemeContext)

  const isMobile = useContext(MobileContext)
  const { hasPermission } = useACL()

  const canEditAppointment = hasPermission('EDIT_APPOINTMENT')

  return (
    <>
      <div className="flex flex-col justify-between items-center w-[80%] lg:w-[unset] ">
        <div
          className={`flex max-md:w-full max-md:self-start max-md:flex-row max-md:items-center
            max-md:justify-between cursor-[initial] !justify-center`}
        >
          {isMobile ? (
            <div className="mr-0 lg:mr-[15px] items-center flex">
              <div
                className="text-[1.714rem] rounded-[9.5px] flex items-center justify-center w-[38px] h-[38px] min-w-[38px]"
                style={{
                  color:
                    props.values.id &&
                    getStatusColor(props?.values?.status, selectedTheme),
                }}
              >
                {props.values.id && getStatusIcon(props?.values?.status)}
              </div>
              <div
                className={`flex lg:block`}
                style={{
                  color:
                    props.values.id &&
                    getStatusColor(props?.values?.status, selectedTheme),
                }}
              >
                {props.values.id &&
                  getStatusLabel(
                    props?.values?.status,
                    t,
                    false,
                    props.values.reason
                  )}
              </div>
            </div>
          ) : null}
        </div>

        {canEditAppointment ? (
          status === 'canceled' && !isMobile ? (
            <div className="flex self-start w-full lg:w-[auto] mt-[20px] lg:mt-0 justify-between lg:justify-start grow-1">
              <div className="mr-0 lg:mr-[15px] items-center flex w-full justify-between">
                <div
                  className={`flex items-center justify-between top-[2px] mb-[0] mr-[10px] w-[auto] bg-white z-9 relative p-[10px_10px_0_10px] lg:p-0`}
                >
                  <div
                    className="text-[1.714rem] rounded-[9.5px] flex items-center justify-center w-[38px] h-[38px] min-w-[38px]"
                    style={{
                      color:
                        props.values.id &&
                        getStatusColor(props?.values?.status, selectedTheme),
                    }}
                  >
                    {props.values.id && getStatusIcon(props?.values?.status)}
                  </div>

                  <div
                    className={`flex lg:block`}
                    style={{
                      color:
                        props.values.id &&
                        getStatusColor(props?.values?.status, selectedTheme),
                    }}
                  >
                    {props.values.id &&
                      getStatusLabel(
                        props?.values?.status,
                        t,
                        false,
                        props.values.reason
                      )}
                  </div>
                </div>
                {options
                  ?.filter(x => x?.id === 'confirm')
                  ?.map(x => (
                    <Button
                      label={x.title}
                      buttonType={'secondaryGreen'}
                      onClick={e => {
                        e.preventDefault()
                        handleStatusUpdate(x?.id)
                      }}
                    />
                  ))}
              </div>
            </div>
          ) : (
            <div className="flex self-start w-full lg:w-[auto] mt-[15px] justify-between lg:justify-start">
              {options?.length
                ? options?.map((x, idx) => (
                    <Button
                      key={x?.id}
                      label={x.title}
                      buttonType={
                        x?.id === 'confirmBooking' ||
                        x?.id === 'confirm' ||
                        x?.id === 'conclude'
                          ? 'secondaryGreen'
                          : 'secondaryRed'
                      }
                      style={{
                        marginRight: isMobile
                          ? idx === 1 || options?.length === 1
                            ? '0px'
                            : '10px'
                          : '5px',
                        flex: isMobile ? 1 : null,
                        justifyContent: isMobile ? 'center' : null,
                      }}
                      onClick={e => {
                        e.preventDefault()
                        handleStatusUpdate(x?.id)
                      }}
                    />
                  ))
                : null}
            </div>
          )
        ) : null}
      </div>
    </>
  )
}

AppointmentHotelStatusHeader.propTypes = {
  ref: PropTypes.any,
  booking: PropTypes.any,
  client: PropTypes.bool,
  mobile: PropTypes.bool,
  values: PropTypes.any,
  onClick: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.any),
  new: PropTypes.any,
}

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: sticky;
  top: -2px;
  background: white;
  z-index: 9;
  margin-bottom: 20px;
  @media ${device.tablet} {
    position: relative;
    margin-bottom: 0px;
    padding: 20px 0px;
    border-bottom: 1px solid ${props => props.theme.colors.outline};
    flex-direction: column-reverse;
    background: transparent;
  }

  .react-select__dropdown-indicator {
    color: ${props => props.theme.colors.light} !important;
  }
`

export const HeaderWrapper = styled(HeaderContainer)<{
  hasBorderBottom: boolean
  flexEnd?: boolean
  flexDirection?: string
}>`
  border-bottom: ${props =>
    props.hasBorderBottom ? `1px solid ${props.theme.colors.outline}` : null};
  justify-content: ${props => (props.flexEnd ? 'space-between' : 'flex-end')};
  padding-bottom: 20px;

  @media ${device.tablet} {
    flex-direction: ${props => props.flexDirection};
  }
`

type THeaderTitleProps = {
  isDisabled: boolean
}
export const HeaderTitle = styled.div<THeaderTitleProps>`
  font-size: 22px;
  cursor: ${props => (props?.isDisabled ? 'initial' : 'pointer')};
  color: ${props => props.theme.colors.text};
  display: flex;
  align-items: center;
  svg {
    margin-left: 8px;
  }

  span {
    display: inline;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
  }

  @media ${device.tablet} {
    //width: 100%;
    span {
      display: inline;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
      white-space: nowrap;
      font-size: 20px;
      margin-right: 10px;
      font-weight: 500;
    }

    svg {
      margin-right: 8px;
      margin-left: 0px;
    }
  }
`
