import React, { useState } from 'react'
import { useParams } from 'react-router'
import { t } from 'src/localization'
import { trpc } from 'src/trpc'
import { useHeader } from 'src/mobile/Header'
import { useSdk } from 'src/sdk'
import { getOrganizationByIdGql } from 'src/state/query/getOrganizationById'
import { useQuery } from '@apollo/client'
import { GiftCardForm } from './GiftCardForm'
import moment from 'moment'
import { serialize } from 'src/helpers'

export const NewGiftCard = () => {
  const params = useParams<{
    locationId: string
    orgId: string
    type: string
  }>()
  const { locationId, orgId, type } = params
  const [isBusy, setIsBusy] = useState<boolean>(false)
  const { navigateTo, appServices } = useSdk()

  useHeader({
    content: type === 'giftcard' ? 'Poklon bon' : 'Personalizirani program',
    onGoBack: () => navigateTo.listGiftCard({ type }),
  })

  const createGiftCardMutation = trpc.giftCard_create.useMutation()
  const trpcContext = trpc.useContext()

  const { data: organizationData, loading: loadingOrgData } = useQuery(
    getOrganizationByIdGql,
    {
      variables: { id: orgId },
    }
  )
  const org = organizationData?.organization?.get

  const handleSubmit = async values => {
    try {
      setIsBusy(true)

      const res = await createGiftCardMutation.mutateAsync({
        orgId,
        locationId: BigInt(locationId),
        name: values?.name,
        giftCardTemplateId: values?.giftCardTemplateId?.id,
        clientId: values?.client?.id ? BigInt(values?.client?.id) : null,
        description: serialize(values?.description),
        terms: values?.terms,
        status: values?.status?.id.toUpperCase(),
        sendEmailToClient: values?.sendEmailToClient,
        validTo: values?.validTo
          ? moment(values?.validTo).format('YYYY-MM-DD')
          : null,
      })
      if (res) {
        appServices.toast.success('Poklon bon je uspješno kreiran')
        await trpcContext.giftCard_pos_findMany.invalidate(undefined, {
          refetchType: 'all',
        })

        navigateTo.listGiftCard({ type: 'giftcard', voucherType: type })
      } else appServices.toast.danger('Poklon bon nije uspješno kreiran')
    } catch (e) {
      appServices.toast.danger('Poklon bon nije uspješno kreiran')
    } finally {
      setIsBusy(false)
    }
  }

  return (
    <GiftCardForm
      initialValues={{
        name: '',
        giftCardTemplateId: null,
        clientId: null,
        terms: '',
        status: {
          id: 'inactive',
          name: t('translation.GiftCardList.status-single-inactive'),
        },
        client: null,
        sendEmailToClient: false,
        isSold: false,
      }}
      onSubmit={handleSubmit}
      loading={isBusy}
      currency={org?.currency?.id}
      language={org?.language}
      giftCardInitialClientId={null}
      isGiftCardUsed={false}
    />
  )
}

export default NewGiftCard
