import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import Cross from '../Icon/Icons/Cross'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const DiscountPopup = ({ onClose }) => {
  const { t } = useTranslation()
  return (
    <div className="z-[9999999999] absolute top-0 left-0 justify-center items-center w-full h-full flex bg-black/30 animate-fading-in">
      <div className="p-7 pb-0 overflow-hidden shadow-sm md:p-[30px] relative bg-white w-[90%] h-auto md:w-[600px] md:h-[400px] rounded-xl ">
        <button
          className="absolute bg-white/50 p-[3px] rounded-full w-[30px] h-[30px] top-4 right-4"
          onClick={onClose}
        >
          <Cross size="extraSmall" className="!stroke-[3]" />
        </button>

        <div className="text-center md:text-left">
          <h2 className="text-[24px] md:text-[30px] font-bold text-zoyya-primary">
            {t('translation.DiscountPopup.title')}
          </h2>
          <p className="md:mt-[14px] md:mb-[27px] mt-3 mb-6">
            {t('translation.DiscountPopup.text')}
          </p>
          <ButtonTW
            size="large"
            label={t('translation.DiscountPopup.button-label')}
            className="px-16 mx-auto md:mx-0"
            onClick={() => {
              window.open(
                `https://zoyya.com/partner/popust-pozovi-prijatelja`,
                '_blank'
              )
            }}
          />
        </div>
        <div className="md:absolute flex justify-center relative bottom-[-2px] md:right-[30px] mt-14">
          <img
            src={'/assets/discount.svg'}
            alt="discount promotion image"
            className="w-[450px]"
          />
        </div>
      </div>
    </div>
  )
}
