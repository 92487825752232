import { FormFooterContainer } from 'components'
import Button, { ButtonStyled } from 'components/Button/Button'
import { StyledFieldsContainer } from 'components/Form/Form'
import {
  StyledTabContainer,
  TabContentContainer,
} from 'components/TabView/TabView'
import React, { useContext } from 'react'
import { useSdk } from 'sdk'
import { MobileContext } from 'src/Context'
import { easeOutQuart } from 'src/components/style/animations'
import { FormsContainer } from 'src/styles'
import styled, { keyframes } from 'styled-components'
import { HeaderContainer } from 'views/Appointment/components/Header'
import { device } from '../../constants'

export const ModalAside = ({
  handleOnCloseEvent,
  handleOnSaveEvent,
  children,
  isAbsolute = true,
  width,
}: {
  children: any
  handleOnCloseEvent?: () => void
  handleOnSaveEvent?: () => void
  isAbsolute?: boolean
  width?: number
}) => {
  const { t } = useSdk()

  const isMobile = useContext(MobileContext)

  const markup = (
    <Container>
      <Modal width={width}>
        <ModalWrapper>
          <FormWrapper>{children}</FormWrapper>

          <FormFooterContainer hasShadow>
            <Button
              buttonType={isMobile ? 'secondary' : 'text'}
              name={'close'}
              label={t('translation.AppointmentModal.button-close')}
              footerButton
              onClick={handleOnCloseEvent}
            />

            <div className={`${isMobile ? 'flex-1' : 'self-end'}`}>
              <Button
                onClick={handleOnSaveEvent}
                tooltipPosition="top"
                buttonType={'primary'}
                name={'saveChanges'}
                disabled={false}
                label={t('translation.AppointmentModal.button-save')}
                className={`${isMobile ? 'w-full' : ''}`}
              />
            </div>
          </FormFooterContainer>
        </ModalWrapper>
      </Modal>
    </Container>
  )
  return isAbsolute ? (
    <aside
      className={`absolute flex flex-col top-0 right-0 h-full max-h-full min-w-[${
        width ? width : 640
      }px] w-[${width ? width : 640}px] bg-white z-[12200]`}
    >
      {markup}
    </aside>
  ) : (
    markup
  )
}

const stagingAnimation = keyframes`
    0% {
      transform: scale(.5);
      opacity: 0;
    }
  
    100% {
      transform: scale(1);
      opacity: 1;
    }
  `

const fadeIn = keyframes`
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  `

export const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${HeaderContainer} {
    position: relative;
  }
`

export const Container = styled.div`
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100%;
  align-items: center;
  max-width: 640px;
  box-sizing: border-box;
  //width: 100%;
  z-index: 100000;
  flex-direction: column;
  top: 0px;
  left: 0px;
  overflow: auto;
  animation: 200ms ${fadeIn} ${easeOutQuart};

  @media ${device.tablet} {
    max-width: 100%;
    padding-top: 0px;
    z-index: 2147483643;
    position: fixed;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    ${TabContentContainer} {
      overflow-x: hidden;
    }
  }

  // to keep billing plans modal scrollable (and confirm/cancel buttons accessible)
  @media screen and (min-width: 1020px) and (max-height: 760px) {
    overflow-y: scroll;
  }
`

export const FormWrapper = styled.div`
  flex: 1;
  overflow: auto;
  background: white;

  form {
    height: 100%;
  }

  ${StyledFieldsContainer} {
    height: 100%;
  }

  ${StyledTabContainer} {
    height: 100%;
  }

  ${FormsContainer} {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`

export const Modal = styled.section<{ width?: number }>`
  background-size: 100% auto;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  margin: 0 0 1rem;
  width: ${props => (props.width ? `${props.width}px` : '640px')};
  height: 100vh;
  overflow: inherit;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: inherit;
  flex-direction: column;
  flex-grow: 0;
  outline: none;
  right: 0;
  animation: 300ms ${stagingAnimation} ${easeOutQuart};

  @media ${device.tablet} {
    max-height: 100%;
    height: 100%;
    width: 100%;
    border-radius: 0;
    overflow: auto;

    ${FormFooterContainer} {
      background: ${props => props.theme.colors.light} !important;
      justify-content: space-around;
      padding-left: 15px;
      ${ButtonStyled} {
        height: 50px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;

        :first-child {
          margin-right: 10px;
        }
      }
    }
  }

  // to keep billing plans modal scrollable (and confirm/cancel buttons accessible)
  @media screen and (min-width: 1020px) and (max-height: 760px) {
    overflow: unset;
  }
`
