import React, { useCallback, useContext, useEffect, useState } from 'react'
import { DatePicker } from './DatePicker'
import moment from 'moment'
import { getCurrentLanguage } from '../../../localization'
import { ChevronDown } from '../../../components/Icon/Icons/ChewronDownLight'
import * as PropTypes from 'prop-types'
import { useSdk } from '../../../sdk'
import { device } from '../../../constants'
import { Button } from 'components'
import {
  getStatusIcon,
  getStatusColor,
  getStatusLabel,
  getServiceDurationInMinutes,
  getServiceWithLatestEnding,
  changeServiceDurations,
  getServiceHoursAndMinutes,
  getFullReportSrc,
} from 'src/helpers'
import styled, { ThemeContext } from 'styled-components'
import { MobileContext } from 'src/Context'
import { FieldArray, useFormikContext } from 'formik'
import { useACL } from 'src/sdk/acl'
import { icons } from 'components/Icon'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import SelectMenu from 'components/Select/Select'
import { useFeatureFlags } from 'src/state/local/appStore'
import * as JSPM from 'jsprintmanager'
import { printPDF } from 'src/state/local/posReceiptStore'
import { useAppServices } from 'src/sdk/appServices'
import { useFormContext } from 'src/components/Form/Form'

/**
 * Created by ivanazuberold on 15/01/2021.
 */

type THeader = {
  showStatusOptions: boolean
  booking: any
  client: any
  ref: any
  date: any
  selectedDayId: any
  values: any
  options: any
  status: string
  setActiveForm: any
  handleStatusUpdate: any
  isNew: any
  timeSlotDuration: number
  onCopyAppointmentClick: () => any
  onProcessingStatusUpdate?: (key: string) => any
}
export const Header = (props: THeader) => {
  const { t } = useSdk()
  const {
    ref,
    booking,
    client,
    showStatusOptions,
    selectedDayId,
    date,
    values,
    options,
    status,
    handleStatusUpdate,
    isNew,
    timeSlotDuration,
    onCopyAppointmentClick,
    onProcessingStatusUpdate,
  } = props

  const selectedTheme = useContext(ThemeContext)
  const sendPaymentNotificationButton =
    !!options?.find(x => x?.id === 'sendPaymentNotification') &&
    values?.client?.email

  const isMobile = useContext(MobileContext)
  const { hasPermission } = useACL()

  const canEditAppointment = hasPermission('EDIT_APPOINTMENT')

  const services = values?.services
    ?.sort((a, b) =>
      moment(
        moment().format('YYYY-MM-DD').toString() +
          ' ' +
          a?.startTime?.id.toString()
      ).diff(
        moment(
          moment().format('YYYY-MM-DD').toString() +
            ' ' +
            b?.startTime?.id.toString()
        )
      )
    )
    .map((item, idx) => {
      return {
        ...item,
        id: idx,
      }
    })

  const processingStatusTitleMapper = {
    WAITING_ARRIVAL: t('translation.Header.waiting-arrival'),
    IN_WAITING_ROOM: t('translation.Header.in-waiting-room'),
    IN_PROCESSING: t('translation.Header.in-processing'),
    PROCEDURE_APPROVED: t('translation.Header.procedure-approved'),
    PROCEDURE_CANCELED: t('translation.Header.procedure-canceled'),
    WAITING_RESULTS: t('translation.Header.waiting-results'),
    CONCLUDED: t('translation.helpers.concluded'),
  }

  const isDisabled = (action: '+' | '-') => {
    return action === '-'
      ? services[0]?.startTime?.id === '00:00' ||
          getServiceDurationInMinutes(services[0]?.startTime?.id) ===
            timeSlotDuration
      : services[0]?.endTime === '00:00' ||
          services[0]?.endTime ===
            getServiceHoursAndMinutes(
              getServiceDurationInMinutes('24:00') - timeSlotDuration
            )
  }

  const featuresFlags = useFeatureFlags()
  const printClient = featuresFlags.printClient
  const printAppointment = featuresFlags.printAppointment
  const processingStatusEnabled = featuresFlags.processingStatus === 'true'

  const [installedPrinters, setInstalledPrinters] = useState<any>([])
  const appServices = useAppServices()

  const getPrinters = useCallback(async () => {
    try {
      if (printClient === 'neodynamicPrintService') {
        if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
          JSPM.JSPrintManager.getPrinters().then(function (printersList) {
            setInstalledPrinters(
              //@ts-ignore
              printersList?.map((x, idx) => {
                return {
                  id: idx,
                  title: x,
                  onItemClick: async () => {
                    const result = await printPDF(
                      printClient,
                      getFullReportSrc(
                        `printTermina&name=${printAppointment}&resourceBookingId=${values?.id}`,
                        true
                      ),
                      x,
                      'printTermina.pdf',
                      false,
                      'Dokument poslan na pisač'
                    )

                    result?.success
                      ? appServices.toast.success(result?.text)
                      : appServices.toast.danger(result?.text)
                  },
                }
              })
            )
          })
        }
      } else {
        const printResponse = await fetch(
          'http://localhost:7969/print/printers',
          {
            method: 'GET',
          }
        )
        const result:
          | { name: string; isDefault: boolean; status: string }[]
          | [] = await printResponse.json()

        setInstalledPrinters(
          result?.map((x, idx) => {
            return {
              id: idx,
              title: x?.name,
              onItemClick: () => {
                console.log(x?.name)
              },
            }
          })
        )
      }
    } finally {
    }
  }, [])

  useEffect(() => {
    if (!!printAppointment) getPrinters()
  }, [getPrinters])

  return (
    <FieldArray name="services">
      {({ replace }) => (
        <>
          <HeaderWrapper
            hasBorderBottom={showStatusOptions}
            flexEnd
            flexDirection={
              isMobile && status === 'concluded'
                ? 'column'
                : showStatusOptions
                ? 'column'
                : !showStatusOptions && values?.client
                ? 'column'
                : 'row'
            }
            style={{
              paddingBottom: !showStatusOptions && !isMobile ? '0' : '20px',
            }}
          >
            <div
              className={`flex max-md:w-full max-md:self-start max-md:flex-row max-md:items-center ${
                isNew ? 'max-md:justify-[unset]' : 'max-md:justify-between'
              } ${!booking && !client ? 'cursor-pointer' : 'cursor-[initial]'}`}
              ref={ref}
            >
              <div>
                <DatePicker
                  isAbsolute
                  view={'day'}
                  setDate={date}
                  selectedDayId={selectedDayId}
                  isDisabled={
                    !canEditAppointment ||
                    status === 'canceled' ||
                    values.status === 'DRAFT'
                  }
                  dontGoToCalendarAfterMonthChange
                  inputComponent={
                    <HeaderTitle
                      isDisabled={
                        !canEditAppointment ||
                        status === 'canceled' ||
                        values.status === 'DRAFT'
                      }
                    >
                      <span
                        data-cy={'appointmentDate'}
                        className="text-justify"
                      >
                        {moment(values.date)
                          .locale(getCurrentLanguage())
                          .format(
                            t(
                              'regionalFormats.date.momentFormatAppointmentModalHeader'
                            )
                          )}
                      </span>
                      {canEditAppointment ? (
                        <ChevronDown color={'grayDark'} isChevron />
                      ) : null}
                    </HeaderTitle>
                  }
                />
                {services?.length ? (
                  <div className="flex items-center mt-[10px] justify-between w-full">
                    {canEditAppointment ? (
                      <button
                        onClick={e => {
                          e.preventDefault()
                          changeServiceDurations(
                            '-',
                            services[0],
                            services,
                            timeSlotDuration,
                            replace,
                            true
                          )
                        }}
                        type="button"
                        className={`w-[30px] h-[30px] rounded-full bg-zoyya-grayDarker ${
                          isDisabled('-') ? 'opacity-[0.3]' : 'opacity-[1]'
                        }`}
                        disabled={isDisabled('-')}
                      >
                        <span className="text-[20px]">-</span>
                      </button>
                    ) : null}

                    <p>{`${services[0]?.startTime
                      ?.id} - ${getServiceWithLatestEnding(services)}`}</p>

                    {canEditAppointment ? (
                      <button
                        disabled={isDisabled('+')}
                        onClick={e => {
                          e.preventDefault()
                          changeServiceDurations(
                            '+',
                            services[0],
                            services,
                            timeSlotDuration,
                            replace,
                            true
                          )
                        }}
                        type="button"
                        className={`w-[30px] h-[30px] rounded-full bg-zoyya-grayDarker ${
                          isDisabled('+') ? 'opacity-[0.3]' : 'opacity-[1]'
                        }`}
                      >
                        <span className="text-[20px]">+</span>
                      </button>
                    ) : null}
                  </div>
                ) : null}
              </div>
              {isNew && isMobile ? (
                !services?.length ? (
                  <div>({values?.startTime + 'h'})</div>
                ) : (
                  <div>({services[0]?.startTime?.title + 'h'})</div>
                )
              ) : null}

              {isMobile ? (
                <div className="mr-0 lg:mr-[15px] items-center flex">
                  <div
                    className="text-[1.714rem] rounded-[9.5px] flex items-center justify-center w-[38px] h-[38px] min-w-[38px]"
                    style={{
                      color:
                        props.values.id &&
                        getStatusColor(props?.values?.status, selectedTheme),
                    }}
                  >
                    {props.values.id && getStatusIcon(props?.values?.status)}
                  </div>
                  <div
                    className={`flex lg:block`}
                    style={{
                      color:
                        props.values.id &&
                        getStatusColor(props?.values?.status, selectedTheme),
                    }}
                  >
                    {props.values.id &&
                      getStatusLabel(
                        props?.values?.status,
                        t,
                        false,
                        props.values.reason
                      )}
                  </div>
                </div>
              ) : null}
            </div>

            {canEditAppointment ? (
              status === 'canceled' && !isMobile && showStatusOptions ? (
                <div className="flex self-start w-full lg:w-[auto] mt-[20px] lg:mt-0 justify-between lg:justify-start grow-1">
                  <div className="mr-0 lg:mr-[15px] items-center flex w-full justify-between">
                    <div
                      className={`flex items-center justify-between top-[2px] mb-[0] mr-[10px] w-[auto] bg-white z-9 relative p-[10px_10px_0_10px] lg:p-0`}
                    >
                      <div
                        className="text-[1.714rem] rounded-[9.5px] flex items-center justify-center w-[38px] h-[38px] min-w-[38px]"
                        style={{
                          color:
                            props.values.id &&
                            getStatusColor(
                              props?.values?.status,
                              selectedTheme
                            ),
                        }}
                      >
                        {props.values.id &&
                          getStatusIcon(props?.values?.status)}
                      </div>

                      <div
                        className={`flex lg:block`}
                        style={{
                          color:
                            props.values.id &&
                            getStatusColor(
                              props?.values?.status,
                              selectedTheme
                            ),
                        }}
                      >
                        {props.values.id &&
                          getStatusLabel(
                            props?.values?.status,
                            t,
                            false,
                            props.values.reason
                          )}
                      </div>
                    </div>
                    {options
                      ?.filter(x => x?.id === 'confirm')
                      ?.map(x => (
                        <ButtonTW
                          label={x.title}
                          type="button"
                          variant={'secondaryGreen'}
                          onClick={e => {
                            e.preventDefault()
                            handleStatusUpdate(x?.id)
                          }}
                        />
                      ))}
                  </div>
                </div>
              ) : (
                <div className="flex self-start w-full lg:w-[auto] mt-[20px] lg:mt-0 justify-between lg:justify-start">
                  {!!printAppointment &&
                  installedPrinters?.length &&
                  !isMobile &&
                  printClient === 'neodynamicPrintService' &&
                  !isNew ? (
                    <SelectMenu
                      style={{ width: 'auto' }}
                      isFullHeight
                      hideSearch
                      yPosition={'bottom'}
                      capitalizeItemLabel={false}
                      xPosition="left"
                      // fullWidth
                      customButton={handleClick => (
                        <ButtonTW
                          variant="primaryOutline"
                          icon={<icons.Print size="small" color={'#6a4ee1'} />}
                          iconClassName="py-[8px] px-[12px]"
                          onClickDropDown={handleClick}
                          onClickIcon={async () => {
                            const result = await printPDF(
                              printClient,
                              getFullReportSrc(
                                `printTermina&name=${printAppointment}&resourceBookingId=${values?.id}`,
                                true
                              ),
                              null,
                              'printTermina.pdf',
                              true,
                              'Dokument poslan na pisač'
                            )

                            result?.success
                              ? appServices.toast.success(result?.text)
                              : appServices.toast.danger(result?.text)
                          }}
                          name="printAppointment"
                          dropDown
                          className="mr-[5px] px-[0] "
                        />
                      )}
                      items={[
                        ...installedPrinters,
                        {
                          id: 'downloadPDF',
                          title: t(
                            'translation.Appointment.label-download-pdf'
                          ),
                          onItemClick: async () => {
                            window.open(
                              getFullReportSrc(
                                `printTermina&name=${printAppointment}&resourceBookingId=${values?.id}`
                              ),
                              '_blank'
                            )
                          },
                        },
                      ]}
                    />
                  ) : null}

                  {values.status !== 'DRAFT' && !isNew ? (
                    <ButtonTW
                      label={t('translation.Header.label-copyAppointment')}
                      variant="primaryOutline"
                      onClick={e => {
                        e.preventDefault()
                        onCopyAppointmentClick()
                      }}
                      name="copyAppointment"
                      className={`mr-[5px] ${
                        isMobile ? 'flex-1' : ''
                      } !pr-3 !pl-3`}
                    />
                  ) : null}

                  {options?.length && showStatusOptions
                    ? options
                        ?.filter(x => x?.id !== 'sendPaymentNotification')
                        ?.map((x, idx) =>
                          x?.id === 'conclude' && processingStatusEnabled ? (
                            <SelectMenu
                              style={{ width: 'auto' }}
                              hideSearch
                              yPosition={'bottom'}
                              capitalizeItemLabel={false}
                              isNarrow
                              xPosition="left"
                              customButton={handleClick => (
                                <ButtonTW
                                  variant="secondaryGreen"
                                  icon={
                                    // workaroung
                                    <span className="px-3">
                                      {
                                        processingStatusTitleMapper[
                                          values?.processingStatus
                                        ]
                                      }
                                    </span>
                                  }
                                  //label={'U čekaonici'}
                                  onClickDropDown={handleClick}
                                  onClick={handleClick}
                                  onClickIcon={handleClick}
                                  name="processingStatus"
                                  dropDown
                                  className="mr-[5px] px-[0] "
                                />
                              )}
                              items={Object.entries(
                                processingStatusTitleMapper
                              )?.map(([key, value]) => {
                                return {
                                  id: key,
                                  title: value,
                                  onItemClick: async () => {
                                    await onProcessingStatusUpdate?.(key)
                                  },
                                }
                              })}
                            />
                          ) : (
                            <ButtonTW
                              key={x?.id}
                              label={x.title}
                              type="button"
                              variant={
                                x?.id === 'confirmBooking' ||
                                x?.id === 'confirm' ||
                                x?.id === 'conclude'
                                  ? 'secondaryGreen'
                                  : 'secondaryRed'
                              }
                              className={`lg:mr-[5px] mr-[${
                                idx === 1 ||
                                options?.filter(
                                  x => x?.id !== 'sendPaymentNotification'
                                )?.length === 1
                                  ? '0px'
                                  : '10px'
                              }] flex-1 lg:flex-none justify-center lg:justify-start !pr-3 !pl-3`}
                              onClick={e => {
                                e.preventDefault()
                                handleStatusUpdate(x?.id)
                              }}
                            />
                          )
                        )
                    : null}
                </div>
              )
            ) : null}
          </HeaderWrapper>
        </>
      )}
    </FieldArray>
  )
}

Header.propTypes = {
  ref: PropTypes.any,
  booking: PropTypes.any,
  client: PropTypes.bool,
  date: PropTypes.func,
  selectedDayId: PropTypes.any,
  mobile: PropTypes.bool,
  values: PropTypes.any,
  onClick: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.any),
  new: PropTypes.any,
}

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: sticky;
  top: -2px;
  background: white;
  z-index: 9;
  margin-bottom: 20px;
  @media ${device.tablet} {
    position: relative;
    margin-bottom: 0px;
    padding: 20px 0px;
    border-bottom: 1px solid ${props => props.theme.colors.outline};
    flex-direction: column-reverse;
    background: transparent;
  }

  .react-select__dropdown-indicator {
    color: ${props => props.theme.colors.light} !important;
  }
`

export const HeaderWrapper = styled(HeaderContainer)<{
  hasBorderBottom: boolean
  flexEnd?: boolean
  flexDirection?: string
}>`
  border-bottom: ${props =>
    props.hasBorderBottom ? `1px solid ${props.theme.colors.outline}` : null};
  justify-content: ${props => (props.flexEnd ? 'space-between' : 'flex-end')};
  padding-bottom: 20px;

  @media ${device.tablet} {
    flex-direction: ${props => props.flexDirection};
  }
`

type THeaderTitleProps = {
  isDisabled: boolean
}
export const HeaderTitle = styled.div<THeaderTitleProps>`
  font-size: 22px;
  cursor: ${props => (props?.isDisabled ? 'initial' : 'pointer')};
  color: ${props => props.theme.colors.text};
  display: flex;
  align-items: center;
  svg {
    margin-left: 8px;
  }

  span {
    display: inline;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
  }

  @media ${device.tablet} {
    //width: 100%;
    span {
      display: inline;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
      white-space: nowrap;
      font-size: 20px;
      margin-right: 10px;
      font-weight: 500;
    }

    svg {
      margin-right: 8px;
      margin-left: 0px;
    }
  }
`
