import {
  FormikComputedProps,
  FormikHandlers,
  FormikHelpers,
  FormikProps,
  FormikRegistration,
  FormikSharedConfig,
  FormikState,
} from 'formik'
import React, { useState, useRef, useEffect, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  Form,
  FormField,
  Loader,
  Tab,
  TabView,
  ToolTip,
  icons,
} from 'src/components'
import {
  formatPhone,
  getISODate,
  getOptionStatus,
  getServiceAmounts,
} from 'src/helpers'
import { useSdk } from 'src/sdk'
import {
  useClientUpdateMutation,
  useClientsAddMutation,
  useGetOrganizationByIdQuery,
  useGetServiceTypesQuery,
  useGetSmsBalanceQuery,
  useLocationGetQuery,
  usePosActiveJournalGetQuery,
  usePrepaymentsGetStripeConnectAccountInfoQuery,
  usePrepaymentsResendPaymentNotificationMutation,
  usePrepaymentsSendPaymentNotificationV2Mutation,
  useResourceBookingLocationScheduleLazyQuery,
} from 'src/state/graphql'
import { trpc } from 'src/trpc'
import { PaymentNotification } from '../AppointmentModal/components/PaymentNotification'
import { ClientDetails } from '../AppointmentModal/components/ClientDetails'
import { useACL } from 'src/sdk/acl'
import { useQuery } from '@apollo/client'
import { Row } from 'src/styles'
import { ClientSelect } from 'src/sdk/tw/components/ClientSelect'
import {
  ServicesRow,
  StyledFieldColumn,
  StyledFieldRow,
  StyledTextArea,
  Container,
  Modal,
  ModalWrapper,
  FormWrapper,
} from '../Appointment/Appointment'
import {
  getStatusColor,
  getStatusLabel,
  roundNumberWithTwoDecimals,
} from 'src/helpers'
import { ThemeContext } from 'styled-components'
import { HeaderWrapper } from '../Appointment/components/Header'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import { AppointmentChangelog } from '../Appointment/AppointmentChangelog'
import { AppointmentAttachments } from '../AppointmentModal/components/AppointmentAttachments'
import { ClientFormWrapper } from '../AppointmentModal/components/ClientFormWrapper'
import { chain, orderBy, set, some } from 'lodash'
import { AppointmentHotelFooter } from './components/AppointmentHotelFooter'
import { useValidationSchema } from 'src/sdk/useValidationSchema'
import { HotelDateRangePicker } from './components/HotelDateRangePicker/AppointmentHotelDateRangePicker'
import moment from 'moment'
import { AppointmentHotelService } from './components/AppointmentHotelServiceForm'
import { searchClients, useInviteUser } from 'src/state/queries'
import { AppointmentHotelStatusHeader } from './components/AppointmentHotelStatusHeader'
import { UpdateStatusModal } from '../Appointment/UpdateStatus'
import { t } from 'src/localization'
import { AppointmentHotelPaymentNotification } from './components/AppointmentHotelPaymentNotification'
import { useFooter } from 'src/mobile/Footer'
import { PosReceiptStore, usePosStore } from 'src/state/local/posReceiptStore'
import { NewReceipt } from '../CashRegister/NewReceipt/NewReceipt'

export const getStatusUpdateOptions = booking => {
  if (booking?.status === 'DRAFT') {
    return [
      {
        id: 'confirmBooking',
        title: t('translation.AppointmentModal.confirmBooking'),
      },
      {
        id: 'cancelBooking',
        title: t('translation.AppointmentModal.cancelBooking'),
      },
    ]
  } else if (booking?.status === 'CONFIRMED') {
    if (moment(booking.startTime).isSameOrBefore(moment())) {
      return [
        {
          id: 'conclude',
          title: t('translation.AppointmentModal.concludeEvent'),
        },
        {
          id: 'cancel',
          title: t('translation.AppointmentModal.cancelEvent'),
        },
      ]
    } else {
      return [
        {
          id: 'cancel',
          title: t('translation.AppointmentModal.cancelEvent'),
        },
      ]
    }
  } else return []
}

export const AppointmentHotelEdit = (props: {
  bookingId: bigint
  onClose: () => void
}) => {
  const { orgId, locationId } = useParams<{
    orgId: string
    locationId: string
  }>()
  const { bookingId, onClose } = props
  const { isMobile, t, toast, appServices, navigateTo } = useSdk()

  const { ClientSchema } = useValidationSchema()

  const trpcContext = trpc.useContext()

  const [activeForm, setActiveForm] = useState<
    'appointment' | 'client' | 'status' | 'paymentNotification'
  >('appointment')

  const selectedTheme = useContext(ThemeContext)

  const [isBusy, setIsBusy] = useState(false)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [clientsSortBy, setClientsSortBy] = useState('')
  const [appointmentStatus, setAppointmentStatus] = useState<
    | ''
    | 'cancelBooking'
    | 'cancel'
    | 'confirmBooking'
    | 'conclude'
    | 'backToConfirm'
  >('')
  const [isNewClient, setIsNewClient] = useState(false)

  const formRef = useRef<any>(null)

  const { hasPermission } = useACL()
  const canAccessInvoicing = hasPermission('ACCESS_INVOICING')
  const canEditAppointment = hasPermission('EDIT_APPOINTMENT')

  const {
    data: booking,
    isLoading: bookingLoading,
    refetch: refetchAppointment,
  } = trpc.hotel_bookingFind.useQuery({
    orgId,
    locationId: BigInt(locationId),
    bookingId: bookingId,
  })

  const { data: locationData, loading: locationLoading } = useLocationGetQuery({
    variables: {
      locationId,
    },
  })

  const { data: smsBalanceData, loading: smsBalanceLoading } =
    useGetSmsBalanceQuery({ fetchPolicy: 'network-only' })

  useEffect(() => {
    const clientsSortBy = localStorage.getItem('clientsSortBy')
    if (clientsSortBy) {
      setClientsSortBy(clientsSortBy)
    } else setClientsSortBy('id_desc')
  }, [])

  const smsBalance = smsBalanceData?.payment?.getSmsBalance?.balance || 0

  const location = locationData?.location?.get

  const currentDayOpenTime =
    location?.workWeek?.[moment().locale('en').format('ddd').toLowerCase()][0]
      ?.openTime

  const currentDayCloseTime =
    location?.workWeek?.[moment().locale('en').format('ddd').toLowerCase()][0]
      ?.closeTime

  const { data: organizationData, loading: loadingOrgData } =
    useGetOrganizationByIdQuery({
      variables: { id: orgId },
    })

  const org = organizationData?.organization?.get

  // #region PREPAYMENTS_INFO
  const {
    data: stripeConnectAccountData,
    loading: loadingStripeConnectAccountData,
  } = usePrepaymentsGetStripeConnectAccountInfoQuery({
    skip: !org?.stripeConnectAccountId,
  })
  const hasStripeChargesEnabled =
    stripeConnectAccountData?.prepayments?.getStripeConnectAccountInfo
      ?.hasChargesEnabled

  const hasInvoicingData =
    location?.prepaymentsConfig?.userId &&
    location?.prepaymentsConfig?.operatorId &&
    location?.prepaymentsConfig?.prepaymentsDeviceId &&
    location?.prepaymentsConfig?.prepaymentServiceId

  const hasPrepaymentsCredentials =
    org?.prepaymentsProvider === 'STRIPE'
      ? hasStripeChargesEnabled && hasInvoicingData
      : org?.hasVivaMerchantId && org?.hasVivaApiKey && hasInvoicingData

  const hasPrepaymentsEnabled = location?.prepaymentsConfig?.prepaymentsEnabled

  // #endregion PREPAYMENTS_INFO

  const serviceTypesData = useGetServiceTypesQuery({
    variables: { locationId },
    fetchPolicy: 'network-only',
  })

  const serviceTypes = serviceTypesData.data?.serviceType?.search

  const services = useMemo(() => {
    return serviceTypes?.map(type => {
      const sortedServices = type?.services?.slice()?.sort(
        (a: any, b: any) =>
          a?.sequence?.localeCompare(b?.sequence, undefined, {
            numeric: true,
          })
      )
      const options = sortedServices?.map(service => {
        return {
          ...service,
          id: service?.id,
          label: service?.name,
          currency: org?.currency?.id || '',
          resourceTypeId: service?.resourceType?.id,
        }
      })
      return {
        ...type,
        label: type?.name,
        options: orderBy(options, 'hasProfession'),
      }
    })
  }, [serviceTypes, org])

  const flattenedServices = useMemo(
    () => chain(serviceTypes).map('services').flatten().value(),
    [serviceTypes]
  )
  const resourcesData = location?.resources

  /*  const employees = useMemo(
    () => resourcesData?.filter(x => x.showInCalendar && x.kind === 'USER'),
    [resourcesData]
  ) */

  const resources = useMemo(
    () => resourcesData?.filter(x => x.kind === 'RESOURCE'),
    [resourcesData]
  )

  // ISSUE RECEIPT DATA
  const selectedPosDevice = usePosStore(store => store.pos.selectedPosDevice)
  const { data: journalData, loading: journalLoading } =
    usePosActiveJournalGetQuery({
      variables: {
        input: {
          fisLocationId: selectedPosDevice?.fisLocationId as any,
          fisDeviceId: selectedPosDevice?.fisDeviceId as any,
        },
      },
      skip: !selectedPosDevice?.fisLocationId,
    })
  const journal = journalData?.posActiveJournal_get

  const { selectClient, addItem, resetReceipt, setReceiptResourceBookingId } =
    PosReceiptStore

  const [selectedReceipt, setSelectedReceipt] = useState<string | null>(null)
  const [isIssueReceipt, setIsIssueReceipt] = useState(false)
  const [preparingForIssueReceipt, setPreparingForIssueReceipt] =
    useState(false)

  const [resourceAvailabilityParams, setResourceAvailabilityParams] = useState({
    dateFrom: '',
    dateTo: '',
    startTime: '',
    endTime: '',
  })

  useEffect(() => {
    if (booking) {
      setResourceAvailabilityParams({
        dateFrom: moment(booking?.startTime).format('YYYY-MM-DD'),
        dateTo: moment(booking?.endTime).format('YYYY-MM-DD'),
        startTime: moment(booking?.startTime).format('HH:mm'),
        endTime: moment(booking?.endTime).format('HH:mm'),
      })
    }
  }, [booking])

  const {
    data: resourcesAvailabilityData,
    isLoading: resourcesAvailabilityLoading,
    refetch: refetchResourcesAvailability,
  } = trpc.hotel_getResourceAvailability.useQuery(
    {
      locationId: BigInt(locationId),
      dateFrom: moment(resourceAvailabilityParams.dateFrom).toDate(),
      dateTo: moment(resourceAvailabilityParams.dateTo).toDate(),
      startTime: resourceAvailabilityParams.startTime,
      endTime: resourceAvailabilityParams.endTime,
    },
    {
      enabled:
        !!resourceAvailabilityParams.dateFrom &&
        !!resourceAvailabilityParams.dateTo &&
        !!resourceAvailabilityParams.startTime &&
        !!resourceAvailabilityParams.endTime,
    }
  )

  //region MUTATIONS
  const [clientAddMutation, { loading: loadingAddClient }] =
    useClientsAddMutation()

  const [updateClient, { loading: loadingUpdateClient }] =
    useClientUpdateMutation()

  const [inviteUser] = useInviteUser()

  const [
    resendPaymentNotificationMutation,
    { loading: loadingResendPaymentNotificationMutation },
  ] = usePrepaymentsResendPaymentNotificationMutation()
  const [
    sendPaymentNotificationMutation,
    { loading: loadingSendPaymentNotificationMutation },
  ] = usePrepaymentsSendPaymentNotificationV2Mutation()

  const updateHotelAppointmentMutation = trpc.hotel_bookingUpdate.useMutation()
  const confirmHotelBookingMutation = trpc.hotel_bookingConfirm.useMutation()
  const cancelHotelBookingMutation = trpc.hotel_bookingCancel.useMutation()
  const cancelHotelAppointmentMutation =
    trpc.hotel_appointmentCancel.useMutation()
  const concludeHotelAppointmentMutation =
    trpc.hotel_appointmentConclude.useMutation()

  const sendPaymentNotification = async (values, showSuccessToast = true) => {
    try {
      setIsBusy(true)
      if (!values?.id) return

      const results =
        !values?.posPaymentRequest ||
        values?.posPaymentRequest?.status === 'CANCELED'
          ? await sendPaymentNotificationMutation({
              variables: {
                id: values?.id,
                cancelAppointmentOnExpiredPaymentRequest:
                  values?.cancelAppointmentOnExpiredPaymentRequest,
              },
            })
          : await resendPaymentNotificationMutation({
              variables: {
                id: values?.id,
              },
            })

      if (!results.errors) {
        if (showSuccessToast) {
          toast.success(t('translation.AppointmentModal.paymentRequest-sent'))
        }
        refetchAppointment()
        trpcContext.hotel_calendarDataGet.invalidate()
      } else toast.danger(results?.errors?.[0]?.message)
    } catch (err) {
      console.log(err)
    } finally {
      setIsBusy(false)
    }
  }

  async function handleUpdateHotelAppointment(
    values,
    dontShowToast = false,
    doGoBack = true
  ) {
    try {
      if (!booking) return

      const result = await updateHotelAppointmentMutation.mutateAsync({
        id: booking.id,
        clientId: values?.client?.id ? BigInt(values?.client?.id) : null,
        sendAppointmentEmail: values?.shouldSendPaymentRequest
          ? false
          : values?.sendAppointmentEmail,
        sendAppointmentSms: values?.shouldSendPaymentRequest
          ? false
          : values?.sendAppointmentSms,
        notes: values?.internalNote,
        messageToClient: values?.sendAppointmentEmail
          ? values?.messageToClient
          : null,
        cancelAppointmentOnExpiredPaymentRequest:
          values?.appointment_cancelAppointmentOnExpiredPaymentRequest,
        services: [
          {
            dateFrom: moment(values?.dateFrom).format('YYYY-MM-DD'),
            dateTo: moment(values?.dateTo).format('YYYY-MM-DD'),
            serviceId: BigInt(values?.serviceId),
            resourceId: BigInt(values?.resourceId),
            //resourceId: values?.resourceId,
            startTime: values?.startTime?.title,
            endTime: values?.endTime?.title,
            quantity: values?.quantity || 1,
            discountAmount: parseFloat(values?.discountAmount) || 0,
            discountType: values?.discountType || 'percent',
            discountRate: parseFloat(values?.discountRate) || 0,
            totalAmount: parseFloat(values?.totalAmount) || 0,
            price: parseFloat(values?.price) || 0,
          },
        ],
      })

      trpcContext.hotel_calendarDataGet.invalidate()
      refetchAppointment()
      if (doGoBack) onClose()
      if (!dontShowToast)
        appServices.toast.success(
          t('translation.AppointmentModal.toast-bookingChanged')
        )
    } catch (err) {
      toast.danger('Došlo je do pogreške')
    } finally {
    }
  }

  async function confirmBooking(args: {
    values: any
    message: string
    sendEmail: boolean
    sendSms: boolean
  }) {
    try {
      const { values, message, sendEmail, sendSms } = args
      let result = await confirmHotelBookingMutation.mutateAsync({
        id: values?.id,
        message: message,
        sendEmail,
        sendSms,
      })

      onClose()
      trpcContext.hotel_calendarDataGet.invalidate()
      toast.success(t('translation.AppointmentModal.toast-bookingAccepted'))
    } catch (err) {
      toast.danger('Došlo je do pogreške')
    }
  }

  async function cancelAppointment(args: {
    reason: string
    cancelNotes: string
    sendEmail: boolean
    sendSms: boolean
    shouldRefundPaymentRequest: boolean
    clientId: string
  }) {
    try {
      const {
        reason,
        cancelNotes,
        sendEmail,
        sendSms,
        shouldRefundPaymentRequest,
        clientId,
      } = args

      let result = await cancelHotelAppointmentMutation.mutateAsync({
        id: BigInt(booking?.id!),
        reason,
        cancelNotes,
        sendEmail,
        sendSms,
        shouldRefundPaymentRequest,
      })

      trpcContext.hotel_calendarDataGet.invalidate()
      onClose()

      toast.success(t('translation.AppointmentModal.toast-rezervationCanceled'))
    } catch (err) {
      console.log('Došlo je do pogreške')
    }
  }

  async function cancelBooking(args: {
    reason: string
    message: string
    sendEmail: boolean
    sendSms: boolean
    shouldRefundPaymentRequest: boolean
  }) {
    try {
      const {
        reason,
        message,
        sendEmail,
        sendSms,
        shouldRefundPaymentRequest,
      } = args

      let result = await cancelHotelBookingMutation.mutateAsync({
        id: booking!.id,
        reason,
        message,
        sendEmail,
        sendSms,
        shouldRefundPaymentRequest: !!shouldRefundPaymentRequest,
      })

      trpcContext.hotel_calendarDataGet.invalidate()
      onClose()

      toast.success(t('translation.AppointmentModal.toast-rezervationCanceled'))
    } catch (err) {
      console.log('Došlo je do pogreške')
    }
  }

  async function concludeAppointment(values, message?: string) {
    try {
      let result = await concludeHotelAppointmentMutation.mutateAsync({
        id: values?.id,
        message,
        sendReviewRequestSms: values.sendReviewRequestSms,
        sendReviewRequestEmail: values.sendReviewRequestEmail,
      })

      trpcContext.hotel_calendarDataGet.invalidate()
      onClose()

      toast.success(t('translation.AppointmentModal.concludeAppointment'))
    } catch (err) {
      console.log('Došlo je do pogreške')
    }
  }

  async function createClient(values) {
    try {
      setIsBusy(true)

      if (isNewClient) {
        const clientResult = await clientAddMutation({
          variables: {
            input: {
              firstName: values.firstName?.trim(),
              lastName: values.lastName?.trim() || '',
              fullName:
                values.firstName?.trim() +
                ' ' +
                (values.lastName?.trim() || ''),
              email: values.email ? values.email?.trim() : '',
              countryId: values.country?.id,
              address: values.address,
              zipCode: values.zipCode,
              language: org?.language || 'hr',
              city: values.city,
              comment: values.comment,
              mobilePhone: formatPhone(values.mobilePhone),
              gender: values.gender,
              identifier: values.identifier,
              vatId: values.vatId,
              birthDate: values.birthDate
                ? moment(values.birthDate).format('YYYY-MM-DD')
                : null,
              sendReminderEmail: values?.sendReminderEmail,
              sendReminderSms: values?.sendReminderSms,
              sendBookingEmail: true,
              sendNewsEmail: true,
              sendHelpEmail: true,
              paymentRequired: values?.paymentRequired,
            },
          },
          refetchQueries: [
            {
              query: searchClients,
              variables: {
                pageNumber: 1,
                pageSize: 50,
                searchTerm: '',
                sortBy: clientsSortBy,
              },
            },
          ],
        })
        if (values?.sendInviteViaEMail) {
          await inviteUser({
            variables: {
              input: {
                firstName: values.firstName?.trim(),
                lastName: values.lastName?.trim() || '',
                email: values.email,
                gender: values.gender || 'Unspecified',
                mobilePhone: formatPhone(values.mobilePhone),
                role: 'CLIENT',
                clientId: clientResult?.data?.clients?.add?.payload?.id!,
              },
            },
          })
        }

        if (!clientResult?.errors) {
          toast.success(
            (t('translation.AppointmentModal.toast-newClientEntered'),
            {
              firstName: values.firstName,
              lastName: values.lastName || '',
            })
          )
          return clientResult?.data?.clients?.add?.payload
        } else {
          toast.danger(t('translation.AppointmentModal.toast-newClientError'))
        }
      } else {
        let clientResult = await updateClient({
          variables: {
            input: {
              id: values.client?.id,
              firstName: values.firstName?.trim(),
              lastName: values.lastName?.trim() || '',
              fullName:
                values.firstName?.trim() +
                ' ' +
                (values.lastName?.trim() || ''),
              email: values.email ? values.email?.trim() : '',
              countryId: values.country?.id,
              address: values.address,
              zipCode: values.zipCode,
              city: values.city,
              comment: values.comment,
              gender: values.gender,
              mobilePhone: formatPhone(values.mobilePhone),
              identifier: values.identifier,
              vatId: values.vatId,
              birthDate: values.birthDate
                ? moment(values.birthDate).format('YYYY-MM-DD')
                : null,
              sendReminderEmail: values?.sendReminderEmail,
              sendReminderSms: values?.sendReminderSms,
              paymentRequired: values?.paymentRequired,
            },
          },
          refetchQueries: [
            {
              query: searchClients,
              variables: {
                pageNumber: 1,
                pageSize: 50,
                searchTerm: '',
                sortBy: clientsSortBy,
              },
            },
          ],
        })

        if (clientResult) {
          toast.success(
            (t('translation.EditClient.toast-editClient'),
            {
              firstName: values.firstName,
              lastName: values.lastName || '',
            })
          )
          return clientResult?.data?.clients?.update?.payload
        }
      }
    } finally {
      setIsBusy(false)
    }
  }

  function getOnNewClient(
    form: FormikSharedConfig<{}> &
      FormikState<any> &
      FormikHelpers<any> &
      FormikHandlers &
      FormikComputedProps<any> &
      FormikRegistration & { submitForm: () => Promise<any> }
  ) {
    return () => {
      form.setFieldValue('client', null)
      setActiveForm('client')
      setIsNewClient(true)
      form.setFieldValue('firstName', '')
      form.setFieldValue('lastName', '')
      form.setFieldValue('email', '')
      form.setFieldValue('mobilePhone', '')
      form.setFieldValue('address', '')
      form.setFieldValue('zipCode', '')
      form.setFieldValue('city', '')
      form.setFieldValue('gender', '')
      form.setFieldValue('comment', '')
      form.setFieldValue('paymentRequired', true)
    }
  }
  function getOnEditClient(
    form: FormikSharedConfig<{}> &
      FormikState<any> &
      FormikHelpers<any> &
      FormikHandlers &
      FormikComputedProps<any> &
      FormikRegistration & { submitForm: () => Promise<any> },
    values
  ) {
    return () => {
      setIsNewClient(false)
      setActiveForm('client')
      form.setFieldValue('firstName', values?.client?.firstName)
      form.setFieldValue('lastName', values?.client?.lastName)
      form.setFieldValue('email', values?.client?.email)
      form.setFieldValue('mobilePhone', values?.client?.mobilePhone)
      form.setFieldValue('address', values?.client?.address)
      form.setFieldValue('zipCode', values?.client?.zipCode)
      form.setFieldValue('city', values?.client?.city)
      form.setFieldValue('gender', values?.client?.gender)
      form.setFieldValue('comment', values?.client?.comment)
      form.setFieldValue('birthDate', values?.client?.birthDate)
      form.setFieldValue('vatId', values?.client?.vatId)
      form.setFieldValue('country', values?.client?.country)
      form.setFieldValue('sendReminderEmail', values?.client?.sendReminderEmail)
      form.setFieldValue('sendReminderSms', values?.client?.sendReminderSms)
      form.setFieldValue('sendBookingEmail', values?.client?.sendBookingEmail)
      form.setFieldValue('sendNewsEmail', values?.client?.sendNewsEmail)
      form.setFieldValue('sendHelpEmail', values?.client?.sendHelpEmail)
      form.setFieldValue('paymentRequired', values?.client?.paymentRequired)
    }
  }

  const handleIssueReceipt = async values => {
    setIsBusy(true)
    await handleUpdateHotelAppointment(values, true, false)

    setPreparingForIssueReceipt(true)
    resetReceipt()
    setSelectedReceipt(null)

    if (booking?.Client) {
      selectClient(booking?.Client)
    }
    setReceiptResourceBookingId(booking!.id.toString())
    const updatedAppointmentData = await refetchAppointment()
    const updatedServices =
      updatedAppointmentData?.data?.ResourceBookingServices?.filter(
        x => x?.Service
      ) || []

    // only one service
    for (const service of updatedServices) {
      addItem({
        resourceBookingServiceId: service?.id?.toString(),
        price: +service?.price || 0,
        quantity: +service?.quantity || 1,
        itemDescription: service?.Service?.name || '',
        serviceId: service?.Service?.id?.toString(),
        vatRate: Number(service?.Service?.vatRate) || 0,
        serviceType: service?.Service?.ServiceTypes?.name,
        finalPrice: values?.totalPrice,
        total: service?.totalAmount, // taken from values because we do not save it
        discountType: service?.discountType,
        discountAmount: service?.discountAmount,
        discountRate: +service?.discountRate,
      })
    }
    if (booking?.posPaymentRequest?.status === 'PAID') {
      const prepaymentServiceId =
        location?.prepaymentsConfig?.prepaymentServiceId

      const prepaymentServiceType = services?.find(x => {
        return x?.services?.find(y => y?.id === prepaymentServiceId)
      })

      const prepaymentServiceRecord = prepaymentServiceType?.services?.find(
        x => x?.id === prepaymentServiceId
      )

      if (prepaymentServiceRecord) {
        addItem({
          quantity: -1,
          discountAmount: 0,
          discountRate: 0,
          price: Number(booking.posPaymentRequest.amount!),
          total: -Number(booking.posPaymentRequest.amount!),
          finalPrice: -Number(booking.posPaymentRequest.amount!),
          serviceId: prepaymentServiceRecord.id,
          paymentRequestId: booking.posPaymentRequest.id.toString(),
          paymentRequestReceiptNo: booking.posPaymentRequest.receiptNo,
          itemDescription: prepaymentServiceRecord.name,
          vatRate: prepaymentServiceRecord?.vatRate || 0,
        })
      }
    }

    setIsIssueReceipt(true)
    setPreparingForIssueReceipt(false)
    setIsBusy(false)
  }

  const shouldShowSendNotificationEmailCheckBox = values => {
    if (!values) return
    return (
      !!values.client?.email &&
      (values.dateFrom !== moment(booking?.startTime).format('YYYY-MM-DD') ||
        values.dateTo !== moment(booking?.endTime).format('YYYY-MM-DD'))
    )
  }

  const shouldShowSendNotificationSmsCheckBox = values => {
    if (!values) return

    return (
      !!values.client?.mobilePhone &&
      (values.dateFrom !== moment(booking?.startTime).format('YYYY-MM-DD') ||
        values.dateTo !== moment(booking?.endTime).format('YYYY-MM-DD'))
    )
  }

  const getSaveButtonLabel = () => {
    return activeForm === 'paymentNotification'
      ? !hasPrepaymentsCredentials
        ? t('translation.AppointmentModal.button-goToPrepayments')
        : formRef.current.values.posPaymentRequest?.status === 'PENDING_PAYMENT'
        ? t('translation.AppointmentModal.resendPaymentNotification')
        : t('translation.AppointmentModal.sendPaymentNotification')
      : t('translation.AppointmentModal.button-save')
  }

  const handleStatusUpdate = type => {
    if (type === 'conclude') {
      setActiveForm('status')
      setAppointmentStatus('conclude')
    } else if (type === 'cancel') {
      setActiveForm('status')
      setAppointmentStatus('cancel')
    } else if (type === 'confirm') {
      setActiveForm('status')
      setAppointmentStatus('backToConfirm')
    } else if (type === 'confirmBooking') {
      setActiveForm('status')
      setAppointmentStatus('confirmBooking')
    } else if (type === 'cancelBooking') {
      setActiveForm('status')
      setAppointmentStatus('cancelBooking')
    } else if (type === 'sendPaymentNotification')
      setActiveForm('paymentNotification')
  }

  const { totalPrice } = getServiceAmounts({
    discountType:
      booking?.ResourceBookingServices?.[0]?.discountType || ('percent' as any),
    discountValue:
      booking?.ResourceBookingServices?.[0]?.discountType === 'percent'
        ? Number(booking?.ResourceBookingServices?.[0]?.discountRate || 0)
        : Number(booking?.ResourceBookingServices?.[0]?.discountAmount || 0),
    price: Number(booking?.ResourceBookingServices?.[0]?.price),
    quantity: Number(booking?.ResourceBookingServices?.[0]?.quantity) || 1,
  })

  const initialValues = {
    ...booking,
    dateFrom: moment(booking?.startTime).format('YYYY-MM-DD'),
    dateTo: moment(booking?.endTime).format('YYYY-MM-DD'),
    endTime: {
      id: moment(booking?.endTime).format('HH:mm'),
      title: moment(booking?.endTime).format('HH:mm'),
    },
    startTime: {
      id: moment(booking?.startTime).format('HH:mm'),
      title: moment(booking?.startTime).format('HH:mm'),
    },
    attachments: [], // need fix
    shouldRefundPaymentRequest: false,
    /*  booking?.status === 'DRAFT' // booking request
            ? true
            : !moment().isAfter(
                booking?.posPaymentRequest?.paymentRequestDueDate
              ), */
    sendEmail:
      booking?.status !== 'CONCLUDED' && booking?.status !== 'concluded',
    sendReviewRequestSms: location?.notifications_smsReviewRequest,
    sendReviewRequestEmail: location?.notifications_emailReviewRequest,
    sendAppointmentEmail:
      location?.sendAppointmentEmailsToClients && !!booking?.Client?.email,
    appointmentCopySendAppointmentEmail:
      location?.sendAppointmentEmailsToClients && !!booking?.Client?.email,
    // default value for send sms checkbox on booking screen (create or update start time)
    sendAppointmentSms:
      location?.sendAppointmentSmsToClients && !!booking?.Client?.mobilePhone,
    // default value for send sms checkbox on update status modal
    sendSms:
      location?.sendAppointmentSmsToClients && !!booking?.Client?.mobilePhone,
    resourceBookingId: booking?.id,
    price: Number(booking?.ResourceBookingServices?.[0]?.price),
    totalPrice: totalPrice,
    discountAmount: booking?.ResourceBookingServices?.[0]?.discountAmount,
    discountRate: Number(
      booking?.ResourceBookingServices?.[0]?.discountRate || 0
    ),
    discountType: booking?.ResourceBookingServices?.[0]?.discountType,
    quantity: Number(booking?.ResourceBookingServices?.[0]?.quantity) || 1,
    discountValue:
      booking?.ResourceBookingServices?.[0]?.discountType === 'percent'
        ? Number(booking?.ResourceBookingServices?.[0]?.discountRate || 0)
        : Number(booking?.ResourceBookingServices?.[0]?.discountAmount || 0),
    currencyId: org?.currency?.id,
    totalAmount: booking?.ResourceBookingServices?.[0]?.totalAmount,
    resourceId: booking?.ResourceBookingServices?.[0]?.Resource?.id?.toString(),
    serviceId: booking?.ResourceBookingServices?.[0]?.Service?.id?.toString(),
    missingProfession:
      !booking?.ResourceBookingServices?.[0]?.Service?.Professions?.id,
    appointment_cancelAppointmentOnExpiredPaymentRequest:
      booking?.cancelAppointmentOnExpiredPaymentRequest,
  }

  const handleValueChange = async (name, value, values, form) => {
    if (
      name === 'dateFrom' ||
      name === 'dateTo' ||
      name === 'startTime' ||
      name === 'endTime'
    ) {
      const startDateTime = moment(
        `${moment(values?.dateFrom).format('YYYY-MM-DD')} ${values?.startTime
          ?.title}`
      )
      const endDateTime = moment(
        `${moment(values?.dateTo).format('YYYY-MM-DD')} ${values?.endTime
          ?.title}`
      )

      const rangeDurationDays = Math.ceil(
        moment.duration(endDateTime.diff(startDateTime)).asDays()
      )
      const { totalAmount, totalPrice, discountAmount, discountRate } =
        getServiceAmounts({
          price: values?.price,
          quantity: rangeDurationDays,
          discountValue: values?.discountValue,
          discountType: values?.discountType,
        })

      form.setFieldValue('quantity', rangeDurationDays)
      form.setFieldValue('totalAmount', totalAmount)
      form.setFieldValue('totalPrice', totalPrice)
      form.setFieldValue('discountAmount', discountAmount)
      form.setFieldValue('discountRate', discountRate)

      setResourceAvailabilityParams({
        ...resourceAvailabilityParams,
        [name]:
          name === 'startTime' || name === 'endTime' ? value?.title : value,
      })

      await refetchResourcesAvailability()
    }

    if (
      activeForm === 'client' &&
      name === 'email' &&
      value !== value?.trim()
    ) {
      form.setFieldValue('email', value?.trim())
      formRef.current?.validateForm()
    }

    if (name === 'client') {
      const total = parseFloat(values?.totalAmount || '0')

      if (hasPrepaymentsCredentials && total > 0 && hasPrepaymentsEnabled) {
        form.setFieldValue('sendPaymentRequest', value?.paymentRequired)
      }

      form.setFieldValue(
        'sendAppointmentEmail',
        location?.sendAppointmentEmailsToClients && !!values?.client?.email
      )
    }
  }

  const handleSubmit = async (values, form) => {
    if (activeForm === 'appointment') {
      await handleUpdateHotelAppointment(values)
    }

    if (activeForm === 'client') {
      const client = await createClient(values)
      // for some reason form.setFieldValue('client', client) doesn't work properly
      formRef.current.setFieldValue('client', client)
      formRef.current.setFieldValue('services', values?.services)
      formRef.current.setFieldValue('internalNote', values?.internalNote)
      formRef.current.setFieldValue(
        'sendAppointmentEmail',
        values?.sendAppointmentEmail
      )
      if (!location?.sendAppointmentEmailsToClients) {
        form.setFieldValue('sendAppointmentEmail', false)
      } else {
        formRef.current.setFieldValue(
          'sendAppointmentEmail',
          values?.sendAppointmentEmail
        )
      }
      formRef.current.setFieldValue(
        'sendPaymentRequest',
        values?.sendPaymentRequest
      )
      setActiveForm('appointment')
    }
    if (activeForm === 'paymentNotification') {
      if (booking?.status !== 'DRAFT') {
        await handleUpdateHotelAppointment({ ...values }, true)
      }

      sendPaymentNotification(values)
      setActiveForm('appointment')
    }
    if (activeForm === 'status') {
      if (appointmentStatus === 'confirmBooking') {
        await handleUpdateHotelAppointment(values, true)
        return confirmBooking({
          values,
          message: values.cancelNotes,
          sendEmail: values.sendEmail,
          sendSms: values.sendSms,
        })
      } else if (appointmentStatus === 'cancelBooking') {
        return cancelBooking({
          reason: values.cancelReason,
          message: values.cancelNotes,
          sendEmail: values.sendEmail,
          sendSms: values.sendSms,
          shouldRefundPaymentRequest: values.shouldRefundPaymentRequest,
        })
      } else if (appointmentStatus === 'cancel') {
        return cancelAppointment({
          reason: values.cancelReason,
          cancelNotes: values.cancelNotes,
          sendEmail: values.sendEmail,
          sendSms: values.sendSms,
          shouldRefundPaymentRequest: values.shouldRefundPaymentRequest,
          clientId: values.client?.id,
        })
      } else if (appointmentStatus === 'conclude') {
        return concludeAppointment(values, values.cancelNotes)
      }
    }
  }

  const issueReceiptButtonDisabled =
    journal?.status === 'CLOSED' ||
    !moment(getISODate(journal?.date)).isSame(getISODate(new Date())) ||
    !journal

  const isLoading =
    bookingLoading ||
    locationLoading ||
    //smsBalanceLoading ||
    loadingOrgData ||
    loadingStripeConnectAccountData ||
    serviceTypesData.loading

  const isUpdating =
    loadingAddClient ||
    loadingUpdateClient ||
    resourcesAvailabilityLoading ||
    updateHotelAppointmentMutation.isLoading

  if (isIssueReceipt) {
    return (
      <NewReceipt
        setOpenNewReceiptModal={setIsIssueReceipt}
        isAppointment
        handleOnCloseEvent={() => {
          refetchAppointment()
          onClose()
        }}
        setIsIssueReceipt={setIsIssueReceipt}
        setSelectedReceipt={setSelectedReceipt}
        selectedReceipt={selectedReceipt}
      />
    )
  }

  return (
    <div>
      <Container>
        <Modal>
          <ModalWrapper>
            {isLoading ? (
              <Loader isComponent />
            ) : (
              <>
                <FormWrapper>
                  <Form
                    onValueChange={handleValueChange}
                    initialValues={initialValues}
                    validationSchema={
                      activeForm === 'client' ? ClientSchema : null
                    }
                    // enableReinitialize

                    validateOnMount={false}
                    onSubmit={handleSubmit}
                  >
                    {form => {
                      const { values, errors, setFieldValue, validateForm } =
                        form
                      formRef.current = form

                      const newTotalPrice = roundNumberWithTwoDecimals(
                        values?.totalAmount
                      )
                      const showSendPaymentNotificationButton = values
                        ?.posPaymentRequest?.id
                        ? values?.posPaymentRequest?.status ===
                            'PENDING_PAYMENT' ||
                          values?.posPaymentRequest?.status === 'CANCELED'
                        : !!values?.client?.email

                      return activeForm === 'appointment' ? (
                        <>
                          <TabView
                            selectedTabIndex={selectedTabIndex}
                            setSelectedTabIndex={setSelectedTabIndex}
                            isSingleTab={false}
                            hasFooter={false}
                          >
                            <Tab
                              hasFooter={false}
                              title={
                                isMobile
                                  ? t('translation.Appointment.termin')
                                  : t(
                                      'translation.Appointment.title-view-appointment'
                                    )
                              }
                            >
                              {(isBusy || isUpdating) && <Loader isComponent />}

                              <div className="py-3 px-4 lg:py-6 lg:px-8">
                                <Row className="mb-4 pb-4 lg:pb-0 lg:border-none border-b-1 border-b-[#f0f0f0] lg:flex-row flex-col">
                                  <HotelDateRangePicker
                                    dateFrom={values?.dateFrom}
                                    dateTo={values?.dateTo}
                                    onDateSet={period => {
                                      setFieldValue('dateFrom', period.dateFrom)
                                      setFieldValue('dateTo', period.dateTo)
                                    }}
                                    // minDate={moment().format('YYYY-MM-DD')}
                                  />

                                  <AppointmentHotelStatusHeader
                                    handleStatusUpdate={handleStatusUpdate}
                                    status={values?.status}
                                    values={values}
                                    options={getStatusUpdateOptions(booking)}
                                  />
                                </Row>

                                <Row>
                                  <ClientSelect
                                    onItemSelected={client =>
                                      form.setFieldValue('client', client)
                                    }
                                    selectedItem={values.client}
                                    disabled={
                                      !canEditAppointment ||
                                      values.status === 'canceled' ||
                                      values.status === 'DRAFT'
                                    }
                                    onNewClient={getOnNewClient(form)}
                                  />

                                  {values?.client?.comment ? (
                                    <p className="line-clamp-4 text-zoyya-secondaryDark text-sm">
                                      {values?.client?.comment}
                                    </p>
                                  ) : null}
                                </Row>

                                <ServicesRow
                                  className="lg:mt-6 mt-3"
                                  style={{ borderBottom: 'unset' }}
                                >
                                  <AppointmentHotelService
                                    flattenedServices={flattenedServices}
                                    isMobile={isMobile}
                                    values={values}
                                    /* employees={
                                      employees?.map(x => {
                                        const isAvailable = resourcesAvailabilityData?.find(
                                          r =>
                                            r?.resourceId?.toString() ===
                                              x?.id && r?.available
                                        )
                                        return {
                                          ...x,
                                          isBusy: !isAvailable,
                                          isInactive: false,
                                          disabled: !isAvailable,
                                        }
                                      }) || []
                                    } */
                                    resources={
                                      resources?.map(x => {
                                        const isAvailable =
                                          resourcesAvailabilityData?.find(
                                            r =>
                                              r?.resourceId?.toString() ===
                                                x?.id && r?.available
                                          )
                                        return {
                                          ...x,
                                          isBusy: !isAvailable,
                                          isInactive: false,
                                          //disabled: !isAvailable,
                                        }
                                      }) || []
                                    }
                                    setFieldValue={setFieldValue}
                                    isLoading={isLoading}
                                    services={services!}
                                    timeSlotDuration={
                                      location?.timeSlotDuration!
                                    }
                                  />
                                </ServicesRow>

                                <StyledTextArea
                                  style={{ marginTop: 0, paddingTop: 0 }}
                                >
                                  <FormField.TextArea
                                    label={t(
                                      'translation.AppointmentModal.placeholderText'
                                    )}
                                    placeholder={t(
                                      'translation.Appointment.vidljivo-samo-zaposlenicima'
                                    )}
                                    readOnly={!canEditAppointment}
                                    name="internalNote"
                                    rows={2}
                                  />
                                </StyledTextArea>
                                <div className="flex flex-col lg:flex-row">
                                  {shouldShowSendNotificationEmailCheckBox(
                                    values
                                  ) ? (
                                    <StyledFieldRow style={{ flex: 1 }}>
                                      <StyledFieldColumn>
                                        <FormField.Checkbox
                                          label={t(
                                            'translation.UpdateStatusModal.sendClientEmail'
                                          )}
                                          name="sendAppointmentEmail"
                                          readOnly={!canEditAppointment}
                                          type={'checkbox'}
                                          helpText={t(
                                            'translation.UpdateStatusModal.sendEmailHint'
                                          )}
                                        />
                                      </StyledFieldColumn>
                                    </StyledFieldRow>
                                  ) : null}

                                  {shouldShowSendNotificationSmsCheckBox(
                                    values
                                  ) && !smsBalanceLoading ? (
                                    <StyledFieldRow style={{ flex: 1 }}>
                                      <StyledFieldColumn>
                                        {smsBalance > 0 ? (
                                          <FormField.Checkbox
                                            label={t(
                                              'translation.UpdateStatusModal.sendClientSms'
                                            )}
                                            name="sendAppointmentSms"
                                            disabled={smsBalance <= 0}
                                            type={'checkbox'}
                                            helpText={t(
                                              'translation.UpdateStatusModal.sendSmsHint'
                                            )}
                                          />
                                        ) : (
                                          <ToolTip
                                            label={t(
                                              'translation.Appointment.no-sms-available'
                                            )}
                                          >
                                            <FormField.Checkbox
                                              label={t(
                                                'translation.UpdateStatusModal.sentClientSms'
                                              )}
                                              name="sendAppointmentSms"
                                              disabled={smsBalance <= 0}
                                              type={'checkbox'}
                                              helpText={t(
                                                'translation.UpdateStatusModal.sendSmsHint'
                                              )}
                                            />
                                          </ToolTip>
                                        )}
                                      </StyledFieldColumn>
                                    </StyledFieldRow>
                                  ) : null}
                                </div>
                                {!values?.sendPaymentRequest &&
                                shouldShowSendNotificationEmailCheckBox(
                                  values
                                ) &&
                                values?.sendAppointmentEmail ? (
                                  <StyledTextArea>
                                    <FormField.TextArea
                                      label={t(
                                        'translation.AppointmentModal.messageToClient'
                                      )}
                                      placeholder={t(
                                        'translation.Appointment.vidljivo-klijentima'
                                      )}
                                      name="messageToClient"
                                      readOnly={!canEditAppointment}
                                      rows={2}
                                    />
                                  </StyledTextArea>
                                ) : null}

                                {values.clientNote ? (
                                  <div className="p-3 bg-zoyya-grayLighter rounded-md mt-3">
                                    <label className="font-medium">
                                      {t(
                                        'translation.AppointmentModal.client-note'
                                      )}
                                    </label>
                                    <div>{values.clientNote}</div>
                                  </div>
                                ) : null}

                                {values?.posPaymentRequest ||
                                values?.client?.email ? (
                                  <HeaderWrapper
                                    style={{
                                      zIndex: 1,
                                      marginBottom: '0',
                                      marginTop: '20px',
                                      borderBottom: 'none',
                                    }}
                                    hasBorderBottom={false}
                                    flexEnd={!!values?.posPaymentRequest?.id}
                                    flexDirection={'row'}
                                  >
                                    {values?.posPaymentRequest?.id ? (
                                      <div
                                        className={`flex lg:block`}
                                        style={{
                                          color:
                                            values.id &&
                                            getStatusColor(
                                              values?.posPaymentRequest?.status,
                                              selectedTheme
                                            ),
                                        }}
                                      >
                                        {values.id &&
                                          getStatusLabel(
                                            values?.posPaymentRequest?.status,
                                            t,
                                            false,
                                            values.reason
                                          )}
                                      </div>
                                    ) : null}
                                    {canEditAppointment &&
                                    showSendPaymentNotificationButton ? (
                                      <ButtonTW
                                        onClick={() =>
                                          setActiveForm('paymentNotification')
                                        }
                                        variant={'primary'}
                                        name={'sendPaymentNotification'}
                                        label={t(
                                          'translation.AppointmentModal.sendPaymentNotification'
                                        )}
                                      />
                                    ) : null}
                                  </HeaderWrapper>
                                ) : null}
                                {values?.posPaymentRequest?.id &&
                                values?.posPaymentRequest?.status ===
                                  'PENDING_PAYMENT' &&
                                values?.status !== 'DRAFT' ? (
                                  <StyledFieldRow style={{ marginTop: 0 }}>
                                    <StyledFieldColumn>
                                      <FormField.Checkbox
                                        label={t(
                                          'translation.AppointmentModal.cancelBookingOnExpired'
                                        )}
                                        name="appointment_cancelAppointmentOnExpiredPaymentRequest"
                                        type={'checkbox'}
                                        helpText={t(
                                          'translation.AppointmentModal.cancelBookingOnExpiredTooltip'
                                        )}
                                      />
                                    </StyledFieldColumn>
                                  </StyledFieldRow>
                                ) : null}
                              </div>
                            </Tab>
                            {values.client && (
                              <Tab
                                hasFooter={false}
                                title={
                                  isMobile
                                    ? t(
                                        'translation.AppointmentModal.label-client'
                                      )
                                    : t(
                                        'translation.AppointmentModal.clientDetails'
                                      )
                                }
                              >
                                <ClientDetails
                                  initialValues={values}
                                  setActiveForm={setActiveForm}
                                  isNew={isNewClient}
                                  onEdit={getOnEditClient(form, values)}
                                  isHotel
                                />
                              </Tab>
                            )}
                            <Tab
                              title={
                                isMobile
                                  ? 'Log'
                                  : t('translation.Appointment.changelog')
                              }
                              hasFooter={false}
                            >
                              <AppointmentChangelog
                                isActiveTab={
                                  values?.client
                                    ? selectedTabIndex === 2
                                    : selectedTabIndex === 1
                                }
                                closeModal={onClose}
                                org={org}
                                booking={booking}
                                onCloseForm={onClose}
                                onSaveForm={() => {
                                  activeForm === 'appointment' &&
                                  Object.values(formRef.current.errors).length
                                    ? setSelectedTabIndex(0)
                                    : formRef.current.submitForm()
                                }}
                              />
                            </Tab>
                            {/* {values.client && values?.id && (
                              <Tab
                                title={t('translation.Appointment.privitci')}
                                hasFooter={false}
                              >
                                <AppointmentAttachments
                                  initialValues={initialValues}
                                  isLoading={false}
                                  values={values}
                                  //booking={booking}
                                  appointment={{}}
                                  setFieldValue={setFieldValue}
                                  //refetchAppointment={refetchAppointment}
                                  closeModal={onClose}
                                  onSaveForm={() => {
                                    activeForm === 'appointment' &&
                                    Object.values(formRef.current.errors).length
                                      ? setSelectedTabIndex(0)
                                      : formRef.current.submitForm()
                                  }}
                                />
                              </Tab>
                            )} */}
                          </TabView>
                        </>
                      ) : activeForm === 'client' ? (
                        <ClientFormWrapper
                          values={values}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          loadingAddClient={loadingAddClient}
                          isNew={isNewClient}
                          setIsNewClient={setIsNewClient}
                          setActiveForm={setActiveForm}
                          onGoBack={() => setActiveForm('appointment')}
                          title={`${values?.client?.firstName} ${values.client?.lastName}`}
                          clientDetails={true}
                          isAppointment
                        />
                      ) : activeForm === 'paymentNotification' ? (
                        <AppointmentHotelPaymentNotification
                          flattenedServices={flattenedServices}
                          totalServicesPrice={newTotalPrice}
                          location={location}
                          values={values}
                          isBusy={
                            loadingSendPaymentNotificationMutation ||
                            loadingResendPaymentNotificationMutation
                          }
                          org={org}
                          hasPrepaymentsCredentials={hasPrepaymentsCredentials}
                          closeModal={onClose}
                          stripeAccountInfo={
                            stripeConnectAccountData?.prepayments
                              ?.getStripeConnectAccountInfo
                          }
                          hasInvoicingData={hasInvoicingData}
                        />
                      ) : (
                        <UpdateStatusModal
                          appointmentStatus={appointmentStatus}
                          event={values}
                          setFieldValue={setFieldValue}
                          smsBalance={smsBalance}
                          smsBalanceLoading={smsBalanceLoading}
                          isLoading={
                            confirmHotelBookingMutation.isLoading ||
                            cancelHotelBookingMutation.isLoading ||
                            cancelHotelAppointmentMutation.isLoading ||
                            concludeHotelAppointmentMutation.isLoading
                          }
                        />
                      )
                    }}
                  </Form>
                </FormWrapper>
                <AppointmentHotelFooter
                  onSaveClick={() => {
                    formRef.current?.submitForm()
                  }}
                  saveButtonLabel={getSaveButtonLabel()}
                  showIssueReceiptButton={
                    selectedTabIndex === 0 &&
                    !isMobile &&
                    activeForm === 'appointment' &&
                    org?.subscriptionPlan === 'advanced' &&
                    booking?.status !== 'DRAFT' &&
                    canAccessInvoicing &&
                    !isLoading
                  }
                  issueReceiptButtonProps={{
                    label:
                      booking?.paymentStatus === 'PAID'
                        ? 'Ponovi izdavanje računa'
                        : 'Izdaj račun',
                    onClick: () => {
                      issueReceiptButtonDisabled
                        ? navigateTo.posScreen({ locationId, orgId })
                        : handleIssueReceipt(formRef?.current?.values)
                    },
                    isLoading: preparingForIssueReceipt,
                  }}
                  onCloseClick={() => {
                    if (activeForm === 'client') {
                      setActiveForm('appointment')
                    } else if (activeForm === 'paymentNotification') {
                      setActiveForm('appointment')
                    } else if (activeForm === 'appointment') {
                      onClose()
                    } else if (activeForm === 'status') {
                      setActiveForm('appointment')
                    }
                  }}
                />
              </>
            )}
          </ModalWrapper>
        </Modal>
      </Container>
    </div>
  )
}
