import { toBlob } from 'html-to-image'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ColorPicker, ColorService, useColor } from 'react-color-palette'
import 'react-color-palette/css'
import Draggable from 'react-draggable'
import ImageCropEditor from 'src/components/ImageCropEditor/ImageCropEditor'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import { CloudDownloadOutlined } from '@ant-design/icons'
import { useSdk } from 'src/sdk'
import Input from 'src/components/Input'
import { Select } from 'src/components/Select2/Select'
import { Light } from 'src/components/Icon/Icons/Light'
import { components } from 'react-select'
import { ModalAside } from 'src/components/ModalAside/ModalAside'
import { Button, Loader, Tab, TabView } from 'src/components'
import { useParams } from 'react-router-dom'
import { CheckboxTW } from 'src/sdk/tw/CheckboxTW'
import {
  getGiftCardValidLabel,
  useOnClickOutsideWithoutTriggeringAction,
} from 'src/helpers'
import { EmptyList } from 'src/components/EmptyList/EmptyList'
import {
  PopupContainer,
  ServiceSelect,
} from 'src/views/CashRegister/NewReceipt/styles'
import { ArrowLeft } from 'src/components/Icon/Icons/ArrowLeft'
import { trpc } from 'src/trpc'
import { debounce } from 'lodash'

const GiftCardImageEditorModal = ({
  handleOnCloseEvent,
  giftCardTemplate,
  refetch,
  locationName,
  currency,
}) => {
  const params = useParams<any>()
  const { orgId, locationId } = params
  const { appServices } = useSdk()

  const giftCardImageConfig = JSON.parse(
    giftCardTemplate?.giftCardImageConfig || 'null'
  )

  const [imageFile, setImageFile] = useState<Blob | null>(null)
  const [isBusy, setIsBusy] = useState(false)
  const [imageSrc, setImageSrc] = useState<any>(
    giftCardImageConfig?.baseImageId
      ? `${
          import.meta.env.VITE_API_URL
        }/api/giftCardTemplate/${giftCardTemplate?.id}/${
          giftCardImageConfig.baseImageId
        }`
      : ''
  )
  const { t } = useSdk()
  const [backgroundPosition, setBackgroundPosition] = useState({ x: 0, y: 0 })
  const [textToEditId, setTextToEditId] = useState<any>(null)
  const [textToEditColor, setTextToEditColor] = useColor('#561ecb')
  const [imageScale, setImageScale] = useState(1)
  const [hasOpenedTextEditor, setHasOpenedTextEditor] = useState<any>(false)
  const [baseImageBlob, setBaseImageBlob] = useState<Blob | null>(null)
  const [showTextComponents, setShowTextComponents] = useState(
    !!giftCardImageConfig?.fullImageId
  )
  const [showExistingDesignsModal, setshowExistingDesignsModal] =
    useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [filterText, setFilterText] = useState<string>('')

  const isNew = !giftCardTemplate

  const { data: templatesData, isLoading: templatesLoading } =
    trpc.giftCardTemplate_findMany.useQuery(
      {
        orgId: orgId,
        locationId: BigInt(locationId),
        voucherType: ['GIFTCARD', 'VOUCHER'],
        searchTerm: searchValue,
      },
      {
        enabled: showExistingDesignsModal,
      }
    )

  const templates = templatesData?.filter(x => !!x.giftCardImageConfig)

  const [textComponents, setTextComponents] = useState(
    giftCardImageConfig
      ? giftCardImageConfig?.textComponents
      : [
          {
            id: 1,
            text: locationName,
            type: 'title',
            fontSize: 35,
            fontFamily: 'Poppins, sans-serif',
            color: '#ffffff',
            position: {
              x: 223,
              y: 116,
            },
            width: 399,
            height: 53,
            isVisible: true,
          },
          {
            id: 2,
            text: 'Podnaslov',
            type: 'subtitle',
            fontSize: 30,
            fontFamily: 'Poppins, sans-serif',
            color: '#333333',
            position: {
              x: 223,
              y: 216,
            },
            width: 399,
            height: 53,
            isVisible: false,
          },
          {
            id: 3,
            text: `${giftCardTemplate.value} ${currency}`,
            type: 'value',
            fontSize: 49,
            fontFamily: 'Poppins, sans-serif',
            color: '#40108A',
            position: {
              x: 424,
              y: 30,
            },
            width: 194,
            height: 74,
            isVisible: giftCardTemplate.voucherType === 'GIFTCARD',
          },
          {
            id: 4,
            text: `Poklon bon potrebno je iskoristiti u roku od ${getGiftCardValidLabel(
              giftCardTemplate.validAmount,
              giftCardTemplate.validIterator
            )} od izdavanja`,
            //text: 'Poklon bon potrebno je iskoristiti do 12.01.2025.',
            type: 'expirationDate',
            fontSize: 15,
            fontFamily: 'Poppins, sans-serif',
            color: '#40108A',
            position: {
              x: 129,
              y: 384,
            },
            width: 383,
            height: 23,
            isVisible: true,
          },
          {
            id: 5,
            text: 'XXXX-XXXX',
            type: 'code',
            fontSize: 40,
            fontFamily: 'Poppins, sans-serif',
            color: '#333333',
            position: {
              x: 393,
              y: 274,
            },
            width: 225,
            height: 60,
            isVisible: true,
          },
        ]
  )
  const [usedColors, setUsedColors] = useState<string[]>(
    Array.from(new Set(textComponents?.map(x => x.color)))
  )

  const fonts = [
    { id: 'Poppins, sans-serif', name: 'Poppins' },
    { id: 'Roboto, sans-serif', name: 'Roboto' },
    { id: 'Arial, sans-serif', name: 'Arial' },
    { id: 'Arial Black, sans-serif', name: 'Arial Black' },
    { id: 'Times New Roman, serif', name: 'Times New Roman' },
    { id: 'Courier New, monospace', name: 'Courier New' },
    { id: 'Noto Sans, sans-serif', name: 'Noto' },
    { id: 'Verdana, sans-serif', name: 'Verdana' },
    { id: 'Georgia, serif', name: 'Georgia' },
    { id: 'Tahoma, sans-serif', name: 'Tahoma' },
    { id: 'Montserrat, sans-serif', name: 'Montserrat' },
    { id: 'Source Sans Pro, sans-serif', name: 'Source Sans Pro' },
    { id: 'EB Garamond, serif', name: 'EB Garamond' },
  ]

  const textData = textToEditId
    ? textComponents?.find(x => x.id === textToEditId)
    : null

  const updateTextComponent = (id, updatedProps) => {
    setTextComponents(prevText =>
      prevText.map(item =>
        item.id === id ? { ...item, ...updatedProps } : item
      )
    )
  }

  const handleClickOutside = e => {
    if (
      !e.target.closest(`#settings-wrapper`) &&
      !e.target.closest(`#text-editor-${textToEditId}`) &&
      !e.target.closest(`.react-select__option`)
    ) {
      setTextToEditId(null)
    }
  }

  useEffect(() => {
    if (textToEditId) {
      const color = textData?.color!
      const rgb = ColorService.hex2rgb(color)
      const hsv = ColorService.rgb2hsv(rgb)

      setTextToEditColor({
        hex: color,
        hsv,
        rgb,
      })

      document.addEventListener('mousedown', handleClickOutside)

      if (!hasOpenedTextEditor) {
        setHasOpenedTextEditor(true)
      }
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [textToEditId])

  const handleSave = async () => {
    if (!imageSrc) return
    /*   toJpeg(document.getElementById('my-node')!, { quality: 1 }).then(
      function (dataUrl) {
        var link = document.createElement('a')
        link.download = 'my-image-name.png'
        link.href = dataUrl
        link.click()
      }
    )
    return */
    setIsBusy(true)

    try {
      // full image with text
      setTextComponents(
        textComponents?.map(x => ({
          ...x,
          isVisible: x.type === 'code' ? false : x.isVisible,
        }))
      )

      const blob = await toBlob(document.getElementById('my-node')!, {
        width: 649,
        height: 409,
        pixelRatio: 1,
        quality: 1,
      })

      const formData = new FormData()

      formData.append('textConfig', JSON.stringify({ textComponents }))
      formData.append('files', blob as Blob)
      formData.append('files', baseImageBlob as Blob)

      const response = await fetch(
        `${
          import.meta.env.VITE_API_URL
        }/api/upload/${orgId}/${giftCardTemplate?.id}/giftCardTemplateImageUpload`,
        {
          method: 'POST',
          body: formData,
          credentials: 'include',
        }
      )
      const result = await response.json()

      if (result.error) {
        appServices.toast.danger('Došlo je do pogreške')
      } else {
        appServices.toast.success('Slika uspješno spremljena')
        refetch()
        handleOnCloseEvent()
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsBusy(false)
    }
  }

  const onSetPreviewUrl = async previewUrl => {
    setImageSrc(previewUrl)
    setShowTextComponents(false)
    setIsBusy(true)
    const blob = await toBlob(document.getElementById('my-node')!, {
      width: 649,
      height: 409,
      pixelRatio: 1,
      quality: 1,
    })
    setBaseImageBlob(blob)
    setIsBusy(false)

    setBackgroundPosition({ x: 0, y: 0 })
    setImageScale(1)
    setTextComponents(
      textComponents?.map(x => ({
        ...x,
        color: '#333333',
      }))
    )
    setShowTextComponents(true)
  }
  const onSetDefaultImage = async () => {
    setShowTextComponents(false)
    setImageSrc('/assets/ZoyyaTemplateGiftCard.png')
    setIsBusy(true)
    const blob = await toBlob(document.getElementById('my-node')!, {
      width: 649,
      height: 409,
      pixelRatio: 1,
      quality: 1,
    })
    setBaseImageBlob(blob)
    setIsBusy(false)
    setShowTextComponents(true)
  }

  const onSelectFromExistingDesigns = async (templateId, imageConfig) => {
    setshowExistingDesignsModal(false)

    setIsBusy(true)
    setImageSrc(
      `${import.meta.env.VITE_API_URL}/api/giftCardTemplate/${templateId}/${
        imageConfig.baseImageId
      }`
    )
    setTextComponents(textComponents?.map(x => ({ ...x, isVisible: false })))

    const blob = await toBlob(document.getElementById('my-node')!, {
      width: 649,
      height: 409,
      pixelRatio: 1,
      quality: 1,
    })

    setBaseImageBlob(blob)

    setShowTextComponents(true)
    setUsedColors(
      Array.from(new Set(imageConfig?.textComponents?.map(x => x.color)))
    )
    setTextComponents(
      imageConfig?.textComponents?.map(comp => {
        if (comp.type === 'expirationDate') {
          return {
            ...comp,
            text: `Poklon bon potrebno je iskoristiti u roku od ${getGiftCardValidLabel(
              giftCardTemplate.validAmount,
              giftCardTemplate.validIterator as 'day' | 'week' | 'month'
            )} od izdavanja`,
          }
        } else if (comp.type === 'value') {
          return {
            ...comp,
            isVisible: giftCardTemplate.voucherType === 'GIFTCARD',
            text:
              giftCardTemplate?.voucherType === 'GIFTCARD'
                ? `${giftCardTemplate?.price || 0} ${currency}`
                : '',
          }
        } else return comp
      })
    )
    setIsBusy(false)
  }

  const wrapperRef = useRef<any>(null)

  const debouncedFiltering = useCallback(
    debounce(text => {
      setSearchValue(text)
    }, 250),
    []
  )

  useEffect(() => {
    debouncedFiltering(filterText)
  }, [filterText, debouncedFiltering])

  useOnClickOutsideWithoutTriggeringAction(wrapperRef, () =>
    setshowExistingDesignsModal(false)
  )

  return (
    <ModalAside
      handleOnCloseEvent={handleOnCloseEvent}
      handleOnSaveEvent={handleSave}
      width={700}
    >
      <TabView
        selectedTabIndex={0}
        setSelectedTabIndex={() => {}}
        isSingleTab={true}
        hasFooter={false}
      >
        <Tab
          hasFooter={false}
          title={isNew ? 'Dodaj sliku predloška' : 'Uredi sliku predloška'}
        >
          {isBusy && <Loader isComponent />}

          <div className="p-8">
            <div className="w-full flex flex-col justify-start gap-4">
              <div className="flex flex-col gap-4">
                <div
                  className={`relative ${
                    !imageSrc ? 'border' : ''
                  } rounded-[35px]`}
                  id="my-node"
                  style={{
                    width: '649px',
                    height: '409px',
                    overflow: 'hidden',
                    position: 'relative',
                  }}
                  /*  onWheel={e => {
                    e.stopPropagation()
                    if (!imageSrc) return
                    if (e.deltaY < 0) {
                      setImageScale(imageScale + 0.05)
                    } else if (e.deltaY > 0) {
                      setImageScale(imageScale - 0.05)
                    }
                  }} */
                >
                  {/* Draggable Background */}
                  {/* <Draggable
                    position={backgroundPosition}
                    onDrag={(e, data) =>
                      setBackgroundPosition({ x: data.x, y: data.y })
                    }
                    //disabled={!imageSrc}
                    disabled
                  > */}
                  <div
                    //src={background!}
                    //alt="s"
                    draggable={false}
                    style={{
                      backgroundImage: `url(${
                        imageSrc || '/assets/noImage.svg'
                      })`,
                      backgroundSize: imageSrc ? 'cover' : 'contain',
                      //backgroundOrigin: 'border-box',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      scale: imageScale?.toFixed(1),

                      width: '100%',
                      height: '100%',
                      //cursor: 'move',
                      position: 'absolute',
                    }}
                  />
                  {/* </Draggable> */}

                  {/* Text Components */}
                  {showTextComponents
                    ? textComponents.map(textComp => (
                        <TextComponent
                          key={textComp.id}
                          textData={textComp}
                          updateText={updateTextComponent}
                          textToEditId={textToEditId}
                          setTextToEditId={setTextToEditId}
                        />
                      ))
                    : null}
                </div>

                <div className="mt-2 flex justify-between flex-row">
                  <div className="flex gap-2 flex-1 justify-between">
                    <div className="flex gap-2">
                      <ButtonTW
                        label="Odaberi postojeći dizajn"
                        variant="secondary"
                        onClick={() => {
                          setshowExistingDesignsModal(true)
                        }}
                      />
                      {imageSrc !== '/assets/ZoyyaTemplateGiftCard.png' ? (
                        <ButtonTW
                          label="Upotrijebi zadanu sliku"
                          variant="secondary"
                          onClick={onSetDefaultImage}
                        />
                      ) : null}
                    </div>

                    <ImageCropEditor
                      setBlob={blob => {
                        setImageFile(blob)
                      }}
                      setPreviewUrl={onSetPreviewUrl}
                      aspect={649 / 409}
                      width={649}
                      height={409}
                      maxSize={20 * 1024 * 1024}
                      customButton={
                        <ButtonTW
                          variant={'secondary'}
                          label={t(
                            'translation.AppointmentAttachments.prenesi'
                          )}
                          icon={<CloudDownloadOutlined />}
                          name="uploadButton"
                        />
                      }
                    />

                    {/* {imageSrc &&
                    (imageScale !== 1 ||
                      backgroundPosition.x !== 0 ||
                      backgroundPosition.y !== 0) ? (
                      <ButtonTW
                        variant={'secondary'}
                        label={'Resetiraj pozadinu'}
                        name="resetBg"
                        onClick={() => {
                          setBackgroundPosition({ x: 0, y: 0 })
                          setImageScale(1)
                        }}
                      />
                    ) : null} */}
                  </div>
                </div>
              </div>

              {!textData && imageSrc ? (
                <div className="flex flex-col gap-4">
                  <CheckboxTW
                    label="Prikaži naslov"
                    checked={
                      textComponents.find(x => x.type === 'title')?.isVisible!
                    }
                    name="hideTitle"
                    onChange={() => {
                      const textComponent = textComponents.find(
                        x => x.type === 'title'
                      )
                      const nextIsVisible = !textComponent?.isVisible
                      updateTextComponent(textComponent?.id, {
                        isVisible: nextIsVisible,
                        text:
                          nextIsVisible && !textComponent?.text
                            ? 'Naslov'
                            : textComponent.text,
                      })
                    }}
                  />
                  <CheckboxTW
                    label="Prikaži podnaslov"
                    checked={
                      textComponents.find(x => x.type === 'subtitle')
                        ?.isVisible!
                    }
                    name="hideSubTitle"
                    onChange={() => {
                      const textComponent = textComponents.find(
                        x => x.type === 'subtitle'
                      )
                      const nextIsVisible = !textComponent?.isVisible
                      updateTextComponent(textComponent?.id, {
                        isVisible: nextIsVisible,
                        text:
                          nextIsVisible && !textComponent?.text
                            ? 'Podnaslov'
                            : textComponent.text,
                      })
                    }}
                  />
                </div>
              ) : null}

              {!hasOpenedTextEditor && imageSrc ? (
                <div className="text-zoyya-text/70 flex border rounded-md p-4 mt-2 bg-zoyya-background/30">
                  <div className="w-[35px] flex items-center">
                    <Light size="medium" color="orange" />
                  </div>
                  <div className=" flex flex-col flex-1 gap-2">
                    <span className="ml-2 flex-1">
                      <span className="mr-3"> {'•'}</span>
                      {
                        'Klikni na određeni tekst za otvaranje sučelja za uređivanje'
                      }
                    </span>
                    <span className="ml-2 flex-1">
                      <span className="mr-3"> {'•'}</span>
                      {
                        'Pozicije teksta se mogu mijenjati tako da se klikne na određeni tekst i povuče na željeno mjesto'
                      }
                    </span>
                  </div>
                </div>
              ) : null}

              {textData ? (
                <div className="p-2 w-full" id="settings-wrapper">
                  {textData.type === 'title' || textData.type === 'subtitle' ? (
                    <div className="flex  flex-col mb-4 -mt-2">
                      <label
                        className="font-medium mb-1"
                        id="font-picker-label "
                      >
                        Sadržaj teksta
                      </label>
                      <Input
                        name="text"
                        type={'text'}
                        value={textData.text}
                        onChange={e => {
                          updateTextComponent(textData.id, {
                            text: e.target.value,
                          })
                          if (!e.target.value) {
                            updateTextComponent(textData.id, {
                              isVisible: false,
                            })
                          }
                        }}
                      />
                    </div>
                  ) : null}
                  <div
                    className={`flex gap-4 ${
                      textData.type === 'title' || textData.type === 'subtitle'
                        ? ''
                        : '-mt-2'
                    }`}
                  >
                    <div className="flex flex-col flex-1">
                      <label
                        className="block mb-1 font-medium"
                        id="font-picker-label "
                      >
                        Veličina teksta
                      </label>
                      <Input
                        name="fontSize"
                        type={'number'}
                        value={textData.fontSize?.toString()}
                        min={5}
                        max={60}
                        onChange={e =>
                          updateTextComponent(textData.id, {
                            fontSize: parseInt(e.target.value),
                          })
                        }
                      />

                      <label className="block font-medium mb-1 mt-4">
                        Font
                      </label>
                      <Select
                        options={fonts}
                        id="font-select"
                        name="font"
                        components={{
                          Option: props => {
                            return (
                              <components.Option {...props}>
                                <div
                                  style={{
                                    fontFamily: (props as any)?.value as any,
                                  }}
                                >
                                  {
                                    props?.options?.find(
                                      option =>
                                        option.id === (props as any)?.value
                                    )?.name
                                  }
                                </div>
                              </components.Option>
                            )
                          },
                          ValueContainer: props => {
                            const value = props.getValue()
                            return (
                              <components.ValueContainer {...props}>
                                <div style={{ fontFamily: value[0]?.id }}>
                                  {props.children}
                                </div>
                              </components.ValueContainer>
                            )
                          },
                        }}
                        styles={{
                          control: provided => ({
                            ...provided,
                            minHeight: '45px !important',
                          }),
                          valueContainer: provided => ({
                            ...provided,
                            minHeight: '45px !important',
                          }),
                        }}
                        isClearable={false}
                        value={fonts?.find(
                          font => font.id === textData.fontFamily
                        )}
                        onChange={val => {
                          updateTextComponent(textData.id, {
                            fontFamily: val?.id,
                          })
                        }}
                      />

                      <label className="block font-medium mb-2 mt-4">
                        Korištene boje
                      </label>

                      <div className="flex flex-wrap gap-2">
                        {usedColors?.map(color => {
                          return (
                            <div
                              className={`p-2 w-[40px] h-[40px] border rounded-md bg-[${color}] cursor-pointer`}
                              key={color}
                              style={{
                                backgroundColor: color,
                              }}
                              onClick={() => {
                                const rgb = ColorService.hex2rgb(color)
                                const hsv = ColorService.rgb2hsv(rgb)

                                setTextToEditColor({
                                  hex: color,
                                  hsv,
                                  rgb,
                                })

                                updateTextComponent(textData.id, {
                                  color: color,
                                })
                              }}
                            />
                          )
                        })}
                      </div>
                    </div>

                    <div className="flex flex-col flex-1">
                      <label
                        className="block mb-4 font-medium"
                        id="font-picker-label "
                      >
                        Boja teksta
                      </label>
                      <ColorPicker
                        color={textToEditColor}
                        key={textData.type}
                        onChangeComplete={color => {
                          requestAnimationFrame(() => {
                            setUsedColors(
                              Array.from(new Set([...usedColors, color.hex]))
                            )
                          })
                        }}
                        onChange={color => {
                          requestAnimationFrame(() => {
                            setTextToEditColor(color)
                            updateTextComponent(textData.id, {
                              color: color.hex,
                            })
                          })
                        }}
                        hideInput={['rgb', 'hsv']}
                        height={100}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </Tab>
      </TabView>
      {showExistingDesignsModal ? (
        <PopupContainer>
          <div
            className="w-11/12 h-5/6 bg-white rounded-lg flex flex-col overflow-hidden"
            ref={wrapperRef}
          >
            <div className="flex items-center">
              <Button
                type="button"
                buttonType={'text'}
                onClick={() => {
                  setshowExistingDesignsModal(false)
                  setFilterText('')
                }}
                size="small"
                iconComponent={<ArrowLeft />}
                style={{
                  paddingLeft: 15,
                  paddingRight: 0,
                  margin: '0 5px',
                }}
              />
              <ServiceSelect
                placeholder={t('translation.ServicesList.label-search')}
                onChange={e => setFilterText(e.target.value)}
                value={filterText}
                autoFocus={false}
                style={{
                  marginLeft: 10,
                  marginBottom: 0,
                }}
              ></ServiceSelect>
            </div>
            <div className="flex text-center ">
              <div
                className={`flex-1 border-b-2  py-2  border-zoyya-primary text-zoyya-primary`}
              >
                {'Slike postojećih predložaka'}
              </div>
            </div>

            <div className="overflow-auto px-3 flex-1 relative py-2 flex flex-col gap-2">
              {templatesLoading ? (
                <Loader isComponent />
              ) : templates?.length ? (
                <>
                  {templates?.map((template, pageIdx) => {
                    const imageConfig = JSON.parse(
                      template?.giftCardImageConfig || 'null'
                    )
                    return (
                      <div
                        key={template?.id.toString()}
                        onClick={async () =>
                          await onSelectFromExistingDesigns(
                            template.id,
                            imageConfig
                          )
                        }
                        className="flex h-[180px] w-full border rounded-md p-2 gap-2 cursor-pointer shadow-sm hover:bg-zoyya-background/30"
                      >
                        <div>
                          <img
                            className="lg:w-[260px] lg:min-w-[260px] lg:max-w-[260px] w-[150px] max-w-[150px] min-w-[150px] h-[164px]  object-cover rounded-sm"
                            src={`${
                              import.meta.env.VITE_API_URL
                            }/api/giftCardTemplate/${template?.id}/${
                              imageConfig.fullImageId
                            }`}
                          />
                        </div>
                        <div className="flex flex-col gap-1">
                          <div className=" font-medium">{template.name}</div>
                          <div className="text-sm text-zoyya-text/60">
                            {template.voucherType === 'GIFTCARD'
                              ? 'Predložak poklon bona'
                              : 'Predložak paketa'}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </>
              ) : (
                <EmptyList title={'Nema spremljenih predložaka'} />
              )}
            </div>
          </div>
        </PopupContainer>
      ) : null}
    </ModalAside>
  )
}

const TextComponent = ({
  textData,
  updateText,
  textToEditId,
  setTextToEditId,
}) => {
  const [isDragging, setIsDragging] = useState(false) // Track if the component has moved
  const [startPos, setStartPos] = useState({ x: 0, y: 0 })
  const isEditing = textToEditId === textData.id

  // Handle drag start: track initial position
  const handleDragStart = (e, data) => {
    setIsDragging(false) // Reset drag state
    setStartPos({ x: data.x, y: data.y }) // Set starting position of drag
  }

  const handleDrag = (e, data) => {
    // If the component has moved by more than a few pixels, it's a drag, not a click
    const distanceMoved = Math.sqrt(
      Math.pow(data.x - startPos.x, 2) + Math.pow(data.y - startPos.y, 2)
    )

    if (distanceMoved > 0) {
      setIsDragging(true) // Component is considered "dragging"
    }
  }

  // Handle drag stop: determine if it was a click or a drag
  const handleDragStop = (e, data) => {
    if (!isDragging) {
      // Treat as a click if not dragged significantly
      if (e.target.id?.startsWith('text-')) {
        setTextToEditId(
          textToEditId === null
            ? textData.id
            : textToEditId !== textData.id
            ? textData.id
            : null
        )
      }
    } else {
      updateText(textData.id, {
        position: { x: data.x, y: data.y },
      })
    }
  }
  if (!textData.isVisible) return null
  return (
    <Draggable
      position={textData.position}
      bounds="parent"
      onDrag={handleDrag}
      onStart={handleDragStart}
      onStop={handleDragStop}
      handle={`.drag`}
    >
      <div
        id={`text-editor-${textData.id}`}
        className="absolute z-10"
        // onClick={handleShowSettings}
      >
        <div
          style={{
            fontSize: textData.fontSize,
            color: textData.color,
            fontFamily: textData.fontFamily,
            cursor: 'move',
            //width: textData.width,
            //height: textData.height,
            fontWeight: 300,
            letterSpacing: 0.15,
          }}
          className={`drag ${
            isEditing ? 'outline-2 outline-dashed outline-gray-400' : ''
          }`}
          id={`text-${textData.id}`}
        >
          {textData.text}
        </div>
      </div>
    </Draggable>
  )
}

export default GiftCardImageEditorModal
