import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg<any>`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const ProcedureApproved = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        stroke="inherit"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        clipPath="url(#clip0_24_40)"
      >
        <path d="M9.5 16.5h-7a2 2 0 01-2-2v-12a2 2 0 012-2h16a2 2 0 012 2v8M23.5 17.5a6 6 0 11-6-6"></path>
        <path d="M23.5 13l-5.47 5.469a.749.749 0 01-1.06 0l-1.72-1.719M15 7a1 1 0 00-1-1h-2V4a1 1 0 00-1-1h-1a1 1 0 00-1 1v2H7a1 1 0 00-1 1v1a1 1 0 001 1h2v2a1 1 0 001 1h1a1 1 0 001-1V9h2a1 1 0 001-1V7z"></path>
      </g>
      <defs>
        <clipPath id="clip0_24_40">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </StyledIcon>
  )
}
