/* eslint-disable no-nested-ternary */
import styled from 'styled-components'
import {
  grayShadow,
  grayLighter,
  white,
  gray,
  grayLight,
  grayDarker,
} from '../style/colors'
import { fontFamily } from '../style/fonts'

export const Wrapper = styled.div`
  outline-style: none;
  :focus {
    outline-style: ${props => (props.isSplit ? 'auto' : '0')};
  }
  width: ${props => (props.isSplit || props.fullWidth ? '100%' : 'auto')};
  height: ${props => (props.isSplit ? '100%' : 'auto')};
  max-height: ${props => (props.isSplit ? '100%' : '')};
  position: ${props => (props.isSplit ? 'initial' : 'relative')};
  display: inline-block;
`

export const SelectStyled = styled.div`
  position: absolute;
  z-index: 99999999999999999;
  border: 1px solid ${gray};
  box-sizing: border-box;
  box-shadow: ${grayShadow};
  pointer-events: ${props => (props.disabled ? 'none' : 'initial')};
  border-radius: 0.286rem;
  display: ${props => (props.isOpen ? 'initial' : 'none')};
  min-width: ${props =>
    props.isWide
      ? '650px'
      : props.isNarrow
      ? ' 170px'
      : props.isMediumWide
      ? '350px'
      : '250px'};
  max-width: ${props =>
    props.fullWidth ? '' : props.isNarrow ? '200px' : '200px'};
  width: ${props => (props.fullWidth ? '100%' : '200px')};
  background-color: ${white};
  bottom: ${props => (props.yPosition === 'top' ? '100%' : 'initial')};
  top: ${props => (props.yPosition === 'bottom' ? '100%' : 'initial')};
  margin-bottom: ${props => (props.yPosition === 'top' ? '0.571rem' : '0')};
  margin-top: ${props =>
    props.marginTop
      ? props.marginTop
      : props.yPosition === 'bottom' || props.xPosition === 'right'
      ? '0.571rem'
      : '0'};
  transform: translateX(
    ${props => (props.horizontalOffset ? props.horizontalOffset : '0')}
  );
  left: ${props => (props.xPosition === 'left' ? 0 : '')};
  right: ${props => (props.xPosition === 'right' ? 0 : '')};
`

export const SelectItems = styled.ul`
  overflow-y: auto;
  position: relative;
  z-index: -1;
  background: ${white};
  border-radius: 0.286rem;
  list-style: none;
  margin: 0.571rem 0px;
  padding: 0;
  min-height: 140px;
  max-height: ${props => (props.isFullHeight ? '100%' : '400px')};
`

export const SelectItemDivider = styled.li`
  background-color: ${grayLighter};
  height: 1px;
  margin-bottom: 15px;
  margin-top: 20px;
  padding: 0;
  pointer-events: none;
  width: 100%;
`

export const SelectItemDividerTitle = styled.span`
  position: relative;
  top: -0.786rem;
  margin-left: 0.571rem;
  background: white;
  padding: 0 0.571rem;
  font-size: 14px;
  font-family: ${fontFamily};
  color: ${props => props.theme.colors.secondaryDark};
`

export const SearchBarWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid #ecf0f3;
  align-items: center;
  padding-left: 1.143rem;
  color: ${props => props.theme.colors.secondaryDarker};

  svg {
    color: ${props => props.theme.colors.secondaryDarker};
  }

  button {
    margin-right: 15px;
  }
`

export const NoMatchesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    width: 116px;
    height: 1.214rem;
    margin: 0 auto;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 1rem;
    letter-spacing: -0.233333px;
    color: ${gray};
  }
`

export const CustomItemContainer = styled.li`
  min-height: 32px;
  align-items: center;
  color: ${grayDarker};
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
  margin-left: 0.571rem;
  margin-right: 0.571rem;
  border-radius: 0.286rem;
  padding-left: 0.571rem;
  padding-right: 0.571rem;
  &:hover {
    background-color: ${grayLight};
  }
  background-color: ${props =>
    props.isCustomItemFocused ? `${grayLight}` : ''};
`
