import React, { useState, useRef, useContext, useEffect } from 'react'
import { createPortal } from 'react-dom'
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { canvasPreview } from './canvasPreview'
import Dropzone, { DropzoneRef } from 'react-dropzone'
import { Modal, icons } from 'src/components'
import { ButtonTW } from 'src/sdk/tw/ButtonTW'
import { MobileContext } from 'src/Context'
import { useSdk } from 'src/sdk'
import { useLocation } from 'react-router-dom'
import Resizer from 'react-image-file-resizer'
// @ts-expect-error https://github.com/onurzorluer/react-image-file-resizer/issues/68
const resizer: typeof Resizer = Resizer.default || Resizer

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
  outputWidth?: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: 'px',
        width: outputWidth ? outputWidth : aspect === 1 ? 300 : 700,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

type ImageCropEditorProps = {
  setBlob: any
  setPreviewUrl: any
  aspect: number
  maxSize?: number
  customButton?: any
  imageBase64?: string
  width?: number
  height?: number
}

const ImageCropEditor = (props: ImageCropEditorProps) => {
  const {
    setBlob,
    setPreviewUrl,
    aspect,
    customButton,
    imageBase64,
    height,
    width,
  } = props
  const { pathname } = useLocation()
  const myAccount = pathname.endsWith(`/settings/account/general`)
  const { appServices, t } = useSdk()
  const isMobile = useContext(MobileContext)
  const [isBusy, setIsBusy] = useState<boolean>(false)
  const [imgSrc, setImgSrc] = useState<string | null>(imageBase64 || '')
  const imgRef = useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState<number>(1)
  const [rotate, setRotate] = useState<number>(0)
  const [imageWidth, setImageWidth] = useState<number>(0)

  const blobUrlRef = useRef<any>('')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)

  const imageInput = useRef<DropzoneRef>(null)
  const MAX_SIZE = props.maxSize || 2 * 1024 * 1024 // 2 MB

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const {
      width: mediaWidth,
      height: mediaHeight,
      naturalWidth,
    } = e.currentTarget
    setImageWidth(naturalWidth)
    setCrop(centerAspectCrop(mediaWidth, mediaHeight, aspect, width))
  }

  const resizeFile = (file, aspect) =>
    new Promise(resolve => {
      resizer.imageFileResizer(
        file,
        aspect === 1 ? 300 : width || 700,
        aspect === 1 ? 300 : height || 330,
        'png',
        100,
        0,
        uri => {
          resolve(uri)
        },
        'blob'
      )
    })

  const onDownloadCropClick = async e => {
    try {
      e.preventDefault()
      setIsBusy(true)
      const image = imgRef.current
      const previewCanvas = previewCanvasRef.current
      if (!image || !previewCanvas || !completedCrop) {
        throw new Error('Crop canvas does not exist')
      }

      // This will size relative to the uploaded image size. If you want to size according to what they are looking at on screen, remove scaleX + scaleY
      const scaleX = image.naturalWidth / image.width
      const scaleY = image.naturalHeight / image.height
      const offscreen = new OffscreenCanvas(
        completedCrop.width * scaleX,
        completedCrop.height * scaleY
      )

      const ctx = offscreen.getContext('2d')
      if (!ctx) {
        throw new Error('No 2d context')
      }

      ctx.drawImage(
        previewCanvas,
        0,
        0,
        previewCanvas.width,
        previewCanvas.height,
        0,
        0,
        offscreen.width,
        offscreen.height
      )
      // You might want { type: "image/jpeg", quality: <0 to 1> } to reduce image size
      const blob = await offscreen.convertToBlob({
        type: 'image/png',
        quality: 0.5,
      })

      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current)
      }

      const resizedImage = await resizeFile(blob, aspect)
      blobUrlRef.current = URL.createObjectURL(resizedImage as Blob)

      setPreviewUrl(blobUrlRef.current)
      setBlob(resizedImage)
      setImgSrc(null)
    } catch (e) {
      appServices.toast.danger(
        t('translation.AppointmentAttachments.uploadWarning')
      )
    } finally {
      setIsBusy(false)
    }
  }

  const handleImageDrop = acceptedFiles => {
    if (!acceptedFiles) return
    if (acceptedFiles.find(file => file.size > MAX_SIZE)) {
      appServices.toast.danger(
        t('translation.Gallery.max-size', {
          maxSize: `${MAX_SIZE / 1024 / 1024}MB`,
        })
      )
      return
    }

    setCrop(undefined) // Makes crop preview update between images.
    const reader = new FileReader()
    reader.addEventListener('load', () =>
      setImgSrc(reader.result?.toString() || '')
    )
    reader.readAsDataURL(
      acceptedFiles.map(file => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      })[0]
    )
  }

  useEffect(() => {
    if (imageBase64) {
      setImgSrc(imageBase64)
    }
  }, [imageBase64])

  return (
    <div>
      {customButton ? (
        React.cloneElement(customButton, {
          onClick: event => {
            event.preventDefault()
            event.stopPropagation()
            imageInput?.current?.open()
          },
        })
      ) : (
        <ButtonTW
          className={`!py-[10px] ${!myAccount ? '!px-0' : ''}`}
          variant={myAccount ? 'secondary' : 'link'}
          size={'large'}
          label={t('translation.LocationGeneralInfo.label-change-picture')}
          onClick={event => {
            event.preventDefault()
            event.stopPropagation()
            imageInput?.current?.open()
          }}
        />
      )}

      <Dropzone
        ref={imageInput}
        onDrop={handleImageDrop}
        accept="image/*"
        multiple={false}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
          </div>
        )}
      </Dropzone>

      {!!imgSrc &&
        createPortal(
          <Modal
            width={500}
            dismissible={false}
            isMobile={isMobile}
            closeModalOnClickOutside
            closeButton={{
              callback: () => {
                setImgSrc(null)
              },
            }}
          >
            <div className=" lg:h-[auto] h-[100dvh]  flex flex-col ">
              <div
                className={`overflow-hidden lg:max-h-[80vh] lg:min-w-[500px] lg:h-[auto] h-[100dvh] flex justify-center ${
                  imageWidth >= 500 ? '' : 'pt-[50px]'
                }`}
              >
                {/* @ts-ignore */}
                <ReactCrop
                  crop={crop}
                  aspect={aspect}
                  keepSelection={true}
                  disabled={isBusy}
                  onChange={(pixelCrop, percentCrop) => {
                    setCrop(pixelCrop)
                  }}
                  onComplete={c => setCompletedCrop(c)}
                >
                  <img
                    alt="Crop me"
                    className={`${
                      imageWidth >= 500 ? 'lg:h-[80hv]' : ''
                    } max-w-[900px] object-scale-down !max-h-[80vh]`}
                    ref={imgRef}
                    src={imgSrc}
                    onLoad={onImageLoad}
                    style={{
                      transform: `scale(${scale}) rotate(${rotate}deg)`,
                    }}
                  />
                </ReactCrop>
              </div>

              <div className="p-[20px] flex justify-between">
                <div className="flex items-center">
                  <ButtonTW
                    size="small"
                    type="button"
                    variant="primaryOutline"
                    className="text-[25px] border-none"
                    disabled={isBusy}
                    icon={
                      <icons.ChevronLeft
                        color="#778ca2"
                        size="size14"
                        stroke={3}
                      />
                    }
                    onClick={() =>
                      setRotate(prevState =>
                        Math.min(180, Math.max(-180, Number(prevState - 2)))
                      )
                    }
                  />
                  <span className="mx-[8px] text-gray-500">Rotiraj</span>
                  <ButtonTW
                    size="small"
                    type="button"
                    variant="primaryOutline"
                    className="text-[25px] border-none"
                    disabled={isBusy}
                    icon={
                      <icons.ChevronRight
                        color="#778ca2"
                        size="size14"
                        stroke={3}
                      />
                    }
                    onClick={() =>
                      setRotate(prevState =>
                        Math.min(180, Math.max(-180, Number(prevState + 2)))
                      )
                    }
                  />
                </div>
                <div className="flex items-center">
                  <ButtonTW
                    size="small"
                    type="button"
                    variant="text"
                    className="!text-[20px] text-gray-500 border-zoyya-secondaryDark"
                    label="+"
                    disabled={isBusy}
                    onClick={() =>
                      setScale(prevState => Number(prevState + 0.1))
                    }
                  />
                  <span className="mx-[8px] text-gray-500">Zoom</span>
                  <ButtonTW
                    size="small"
                    type="button"
                    variant="text"
                    className="!text-[20px] text-gray-500 border-zoyya-secondaryDark"
                    label="-"
                    disabled={isBusy}
                    onClick={() =>
                      setScale(prevState => Number(prevState - 0.1))
                    }
                  />
                </div>
                {!!imgRef?.current ? (
                  <ButtonTW
                    size="medium"
                    type="button"
                    variant="primary"
                    className={'!px-[10px]'}
                    isLoading={isBusy}
                    disabled={isBusy}
                    onClick={e => {
                      canvasPreview(
                        imgRef?.current!,
                        previewCanvasRef.current!,
                        completedCrop!,
                        scale,
                        rotate
                      )
                      onDownloadCropClick(e)
                    }}
                    label="Postavi"
                  />
                ) : null}

                {!isBusy ? (
                  <ButtonTW
                    size="small"
                    type="button"
                    variant="primaryOutline"
                    className="!absolute !top-5 !right-5  !px-[10px]"
                    icon={<icons.Cross size="size14" stroke={3} />}
                    onClick={() => {
                      setImgSrc(null)
                      setScale(1)
                      setRotate(0)
                    }}
                  />
                ) : null}
              </div>
            </div>
            <canvas
              ref={previewCanvasRef}
              style={{
                display: 'none',
                border: '1px solid black',
                objectFit: 'contain',
                width: completedCrop?.width || 0,
                height: completedCrop?.height || 0,
              }}
            />
          </Modal>,
          document.getElementById('react-select-portal-target')!
        )}
    </div>
  )
}

export default ImageCropEditor
