import React, { useState, useEffect, useRef, useContext } from 'react'
import { FormField, FormFooterContainer, icons, Loader } from 'components'
import { FormsContainer, ModalBodyContainer } from 'src/styles'
import { EventBodyWrapper } from 'views/AppointmentModal/styles'
import { useSdk } from 'sdk'
import { ThemeContext } from 'styled-components'
import Button from 'components/Button/Button'
import { useAppServices } from 'sdk/appServices'
import {
  dataUrlToFile,
  getAttachmentImage,
  useOnClickOutsideWithoutTriggeringAction,
} from 'src/helpers'
import Dropzone from 'react-dropzone'
import { FieldRow, FieldColumn } from 'views/Settings/styles'
import { OrganizationAvatarRow } from 'views/SignupFlow/styles'
import {
  AppointmentGetDocument,
  ResourceBookingGetDocument,
  useClientUpdateAttachmentMutation,
  useClientDeleteAttachmentsMutation,
  AppointmentFindDocument,
  useConfigFeaturesQuery,
} from 'state/graphql'
import { Download } from 'components/Icon/Icons/Download'
import Tooltip from 'components/Tooltip/Tooltip'
import { Edit } from 'components/Icon/Icons/Edit'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { Trash } from 'components/Icon/Icons/Trash'
import {
  AttachmentItemImage,
  AttachmentItemInfo,
  AttachmentItemLink,
  AttachmentItemsGrid,
  AttachmentItemWrapper,
  AttachmentPreviewImage,
  BottomIcon,
  BottomSectionButtonsWrapper,
  BottomShareIcon,
  CheckboxWrapper,
  WebcamWrapper,
  AttachmentHeader,
  FormFieldsWrapperAttachments,
  ClientAttachmentsWrapper,
  AttachmentUploadButton,
  AttachmentColumn,
  FormButtons,
  AttachmentsFormContainer,
} from 'views/Clients/ClientAttachments'
import Webcam from 'react-webcam'
import { SyncArrowsSquare } from 'components/Icon/Icons/SyncArrowsSquare'
import Share from 'components/Icon/Icons/Share'
import { CameraOutlined, CloudDownloadOutlined } from '@ant-design/icons'
import Cross from '../../../components/Icon/Icons/Cross'
import { AttachmentsUpgradePage } from 'components/UpgradePage/UpgradePage'
import { useParams } from 'react-router-dom'
import { getOrganizationByIdGql } from 'state/query/getOrganizationById'
import { useQuery } from '@apollo/client'
import { usePopup } from 'src/sdk/tw/Popup/usePopup'
import moment from 'moment'
import { useFeatureFlags } from 'src/state/local/appStore'
import { useACL } from 'src/sdk/acl'

const MAX_SIZE = 5002880 * 2

export const AppointmentAttachments = ({
  initialValues,
  isLoading,
  values,
  booking,
  appointment,
  setFieldValue,
  refetchAppointment,
  onSaveForm,
  closeModal,
}) => {
  const { t, isMobile } = useSdk()
  const { hasPermission } = useACL()
  const canEditAppointment = hasPermission('EDIT_APPOINTMENT')

  const [updateAttachment] = useClientUpdateAttachmentMutation()
  const [deleteAttachments] = useClientDeleteAttachmentsMutation()
  const { showPopup } = usePopup()

  const imageInput = useRef<any>(null)
  const [attachmentFile, setAttachmentFile] = useState<File | null>(null)
  const [clientAttachments, setClientAttachments] = useState<any>([])
  const [appointmentAttachments, setAppointmentAttachments] = useState<any>([])
  const [isBusy, setIsBusy] = useState(false)
  const [attachmentPreview, setAttachmentPreview] = useState<any>([])
  const [uploadDrawerOpen, setUploadDrawerOpen] = useState<any>(false)
  const [isUpdateMode, setIsUpdateMode] = useState<any>(false)
  const [attachmentToUpdate, setAttachmentToUpdate] = useState<any>(null)
  const [selectedAttachments, setSelectedAttachments] = useState<any>([])
  const [cameraOrientation, setCameraOrientation] = useState<any>({
    exact: 'environment',
  })
  const [zoomedImage, setZoomedImage] = useState<any>(null)
  const { orgId, locationId } = useParams<any>()
  const { data: organizationData, loading: loadingOrg } = useQuery(
    getOrganizationByIdGql,
    { variables: { id: orgId } }
  )

  const org = organizationData?.organization?.get
  const selectedTheme = useContext(ThemeContext)

  const [isCameraMode, setIsCameraMode] = useState<any>(false)

  const canShare =
    !!navigator.share &&
    //@ts-ignore
    !!navigator.canShare &&
    //@ts-ignore
    navigator.canShare({ files: [new File(['foo'], 'foo.txt')] })

  const featuresFlags = useFeatureFlags()
  const calendarVersion = featuresFlags.calendarVersion

  const appServices = useAppServices()
  const webcamRef = useRef<any>(null)
  const containerRef = useRef<any>()

  const capture = React.useCallback(async () => {
    const imageSrc = webcamRef?.current?.getScreenshot()
    setAttachmentPreview([{ preview: imageSrc }])
    const file = await dataUrlToFile(imageSrc, `attachment_${Date.now()}.jpeg`)
    if (file.size > MAX_SIZE) {
      appServices.toast.danger(
        t('translation.AppointmentAttachments.uploadError')
      )
      return
    } else {
      setAttachmentFile(file)
    }
  }, [webcamRef])

  const handleImageSelect = async acceptedFiles => {
    if (acceptedFiles.find(file => file.size > MAX_SIZE)) {
      appServices.toast.danger(
        t('translation.AppointmentAttachments.uploadError')
      )
      return
    }
    setAttachmentFile(acceptedFiles[0])
    // dodat provjeru ako nije slika
    if (acceptedFiles?.[0]?.type?.includes('image')) {
      setAttachmentPreview(
        acceptedFiles.map(file => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        })
      )
    } else if (acceptedFiles?.[0]?.type?.includes('pdf')) {
      setAttachmentPreview([{ preview: '/assets/pdf-logo.png' }])
    }
    setFieldValue('attachmentTitle', acceptedFiles[0].name)
  }

  const handleUpload = async (e, footerButtonClicked) => {
    e.stopPropagation()
    e.preventDefault()
    if (!attachmentFile) return
    setIsBusy(true)

    const formData = new FormData()

    // file must be appended last for fastify-multipart to work
    formData.append('title', values?.attachmentTitle)
    formData.append('description', values?.attachmentDescription)
    if (!booking) {
      formData.append('appointmentId', values?.id)
    } else {
      formData.append('bookingRequestId', values?.id)
    }
    formData.append('clientId', values?.client?.id)
    formData.append('locationId', locationId)
    formData.append('file', attachmentFile)

    const response = await fetch(
      `${
        import.meta.env.VITE_API_URL
      }/api/upload/${orgId}/clientAttachmentUpload`,
      {
        method: 'POST',
        body: formData,
        credentials: 'include',
      }
    )
    const result = await response.json()

    if (!response.ok || !result?.success) {
      appServices.toast.danger(
        t('translation.AppointmentAttachments.uploadWarning')
      )
    } else {
      appServices.toast.success(
        t('translation.AppointmentAttachments.uploadSuccess')
      )
      const result = await refetchAppointment()
      const newAttachments = result?.data?.appointment?.find?.attachments
      const newClientAttachments =
        result?.data?.appointment?.find?.client?.attachments

      setAppointmentAttachments(newAttachments)
      setClientAttachments(newClientAttachments)
      setUploadDrawerOpen(false)
      setAttachmentFile(null)
      setAttachmentPreview([])
      setFieldValue('attachmentTitle', '')
      setFieldValue('attachmentDescription', '')
      footerButtonClicked && !isMobile && closeModal()
    }

    setIsBusy(false)
  }

  const handleUpdate = async (e, footerButtonClicked) => {
    e.stopPropagation()
    e.preventDefault()
    if (!attachmentToUpdate) return
    setIsBusy(true)
    const result = await updateAttachment({
      variables: {
        input: {
          id: attachmentToUpdate?.id,
          title: values?.attachmentTitle,
          description: values?.attachmentDescription,
        },
      },
      refetchQueries: [
        calendarVersion === 'v2'
          ? {
              query: AppointmentFindDocument,
              variables: { appointmentId: parseInt(values?.id) },
            }
          : !booking
          ? { query: AppointmentGetDocument, variables: { id: values?.id } }
          : {
              query: ResourceBookingGetDocument,
              variables: { id: values?.id },
            },
      ],
    })
    if (!result?.errors?.length) {
      appServices.toast.success(
        t('translation.AppointmentAttachments.uploadEditSuccess')
      )
      const result = await refetchAppointment()
      const newAttachments =
        calendarVersion === 'v2'
          ? result?.data?.appointment?.find?.attachments
          : result?.data?.appointment?.get?.attachments
      const newClientAttachments =
        result?.data?.appointment?.find?.client?.attachments
      setAppointmentAttachments(newAttachments)
      setClientAttachments(newClientAttachments)
      setIsUpdateMode(false)
      setUploadDrawerOpen(false)
      setAttachmentToUpdate(null)
      setAttachmentPreview([])
      setFieldValue('attachmentTitle', '')
      setFieldValue('attachmentDescription', '')
      footerButtonClicked && !isMobile && closeModal()
    } else {
      appServices.toast.danger(
        t('translation.AppointmentAttachments.doslo-je-do-pogreske')
      )
    }
    setIsBusy(false)
  }

  const handleDelete = async () => {
    if (!selectedAttachments?.length) return
    setIsBusy(true)
    const result = await deleteAttachments({
      variables: {
        attachmentIds: selectedAttachments,
      },
      refetchQueries: [
        calendarVersion === 'v2'
          ? {
              query: AppointmentFindDocument,
              variables: { appointmentId: parseInt(values?.id) },
            }
          : !booking
          ? { query: AppointmentGetDocument, variables: { id: values?.id } }
          : {
              query: ResourceBookingGetDocument,
              variables: { id: values?.id },
            },
      ],
    })
    if (!result?.errors?.length) {
      appServices.toast.success(
        t('translation.AppointmentAttachments.privitci-uspjesno-obrisani')
      )
      const result = await refetchAppointment()
      const newAttachments =
        calendarVersion === 'v2'
          ? result?.data?.appointment?.find?.attachments
          : result?.data?.appointment?.get?.attachments
      const newClientAttachments =
        result?.data?.appointment?.find?.client?.attachments
      setAppointmentAttachments(newAttachments)
      setClientAttachments(newClientAttachments)
      setSelectedAttachments([])
    } else {
      appServices.toast.danger(
        t('translation.AppointmentAttachments.doslo-je-do-pogreske')
      )
    }
    setIsBusy(false)
  }

  const handleShare = async attachment => {
    if (!canShare) return
    try {
      const file = await dataUrlToFile(
        attachment.downloadUrl,
        attachment.fileName
      )

      navigator
        .share({
          title: attachment.title || attachment.fileName,
          text: '',
          files: [file],
        } as any)
        .then(() =>
          appServices.toast.success(
            t('translation.AppointmentAttachments.attachmentShareSuccess')
          )
        )
        .catch(error => {
          // appServices.toast.success('Došlo je do pogreške pri dijeljenju')
          console.log(error)
        })
    } catch (err) {
      appServices.toast.success(
        t('translation.AppointmentAttachments.attachmentShareError')
      )
    }
  }

  useEffect(() => {
    if (booking) {
      setClientAttachments(booking?.client?.attachments)
      setAppointmentAttachments(booking?.attachments)
    } else {
      setClientAttachments(appointment?.client?.attachments)
      setAppointmentAttachments(appointment?.attachments)
    }
  }, [appointment, booking, isLoading, values])

  const sortedClientAttachments = [
    ...appointmentAttachments,
    ...clientAttachments?.filter(
      item =>
        !appointmentAttachments.some(
          appointmentAttachment => appointmentAttachment.id === item.id
        )
    ),
  ]
  useEffect(() => {
    if (!attachmentToUpdate) {
      setFieldValue('attachmentTitle', '')
      setFieldValue('attachmentDescription', '')
      setFieldValue('attachmentId', '')
    } else {
      setFieldValue('attachmentTitle', attachmentToUpdate.title)
      setFieldValue('attachmentDescription', attachmentToUpdate.description)
      setFieldValue('attachmentId', attachmentToUpdate.id)
    }
  }, [attachmentToUpdate])

  const handleDrawerAction = (action: 'open' | 'close') => {
    setUploadDrawerOpen(action === 'open' ? true : false)
    setFieldValue('attachmentTitle', '')
    setFieldValue('attachmentDescription', '')
    setFieldValue('attachmentId', '')
    setIsUpdateMode(false)
    setAttachmentFile(null)
    setAttachmentPreview([])
    setAttachmentToUpdate(null)
  }
  const wrapperRef = useRef<any>()

  useOnClickOutsideWithoutTriggeringAction(wrapperRef, () =>
    setZoomedImage(null)
  )
  useEffect(() => {
    const handleKeyPressActions = event => {
      if (event.keyCode === 27) setZoomedImage(null)
    }
    window.addEventListener('keydown', handleKeyPressActions)

    return () => {
      window.removeEventListener('keydown', handleKeyPressActions)
    }
  }, [])

  if (org?.subscriptionPlan === 'free') {
    return <AttachmentsUpgradePage isAppointmentModal closeModal={closeModal} />
  }

  return (
    <ClientAttachmentsWrapper>
      <ModalBodyContainer>
        {isLoading || isBusy || loadingOrg ? (
          <Loader isComponent={true} />
        ) : null}

        <EventBodyWrapper>
          <FormsContainer>
            {uploadDrawerOpen ? (
              <FormFieldsWrapperAttachments>
                <AttachmentsFormContainer>
                  <>
                    <FieldRow style={{ alignItems: 'flex-start' }}>
                      <AttachmentColumn>
                        <OrganizationAvatarRow
                          style={{ width: '100%', marginBottom: 'unset' }}
                        >
                          {isCameraMode ? (
                            <>
                              <WebcamWrapper>
                                <Webcam
                                  style={{ height: 220, width: 'inherit' }}
                                  audio={false}
                                  ref={webcamRef}
                                  //mirrored={cameraOrientation === 'user'}
                                  minScreenshotHeight={1920}
                                  minScreenshotWidth={1080}
                                  screenshotQuality={1}
                                  videoConstraints={{
                                    facingMode: isMobile
                                      ? cameraOrientation
                                      : 'user',
                                    height: {
                                      min: 720,
                                      ideal: 1920,
                                      max: 1920,
                                    },
                                    width: { min: 720, ideal: 1080 },
                                    aspectRatio: 1.777777778,
                                  }}
                                  screenshotFormat="image/jpeg"
                                />

                                {isMobile ? (
                                  <BottomIcon
                                    onClick={e => {
                                      e.stopPropagation()
                                      setCameraOrientation(
                                        typeof cameraOrientation === 'object'
                                          ? 'user'
                                          : { exact: 'environment' }
                                      )
                                    }}
                                    style={{
                                      position: 'absolute',
                                      top: 10,
                                      right: 10,
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <SyncArrowsSquare
                                      color={'#333'}
                                      size={'small'}
                                      style={{ marginRight: 'auto' }}
                                    />
                                  </BottomIcon>
                                ) : null}
                              </WebcamWrapper>
                              <div
                                style={{
                                  justifyContent: 'space-between',
                                  display: 'flex',
                                  paddingTop: 20,
                                }}
                              >
                                <Button
                                  onClick={event => {
                                    event.preventDefault()
                                    event.stopPropagation()
                                    capture()
                                    setIsCameraMode(false)
                                  }}
                                  buttonType={'secondaryLink'}
                                  label={t(
                                    'translation.AppointmentAttachments.slikaj'
                                  )}
                                  iconComponent={
                                    <CameraOutlined
                                      style={{ marginRight: 0 }}
                                    />
                                  }
                                  name="captureButton"
                                />
                                <Button
                                  onClick={event => {
                                    event.preventDefault()
                                    event.stopPropagation()
                                    setIsCameraMode(false)
                                    setAttachmentFile(null)
                                    setAttachmentPreview([])
                                  }}
                                  name="backButton"
                                  label={t('translation.App.label-back')}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <AttachmentPreviewImage
                                src={
                                  attachmentPreview[0]
                                    ? attachmentPreview[0].preview
                                    : attachmentToUpdate
                                    ? attachmentToUpdate.thumbnailUrl
                                    : getAttachmentImage(initialValues)
                                }
                              />
                              {!isUpdateMode ? (
                                <div
                                  style={{
                                    justifyContent: 'space-between',
                                    display: 'flex',
                                    paddingTop: 20,
                                  }}
                                >
                                  <Button
                                    onClick={event => {
                                      event.preventDefault()
                                      event.stopPropagation()
                                      imageInput?.current?.open()
                                    }}
                                    buttonType={'secondary'}
                                    disabled={isUpdateMode}
                                    label={t(
                                      'translation.AppointmentAttachments.prenesi'
                                    )}
                                    iconComponent={
                                      <CloudDownloadOutlined
                                        style={{ marginRight: 0 }}
                                      />
                                    }
                                    name="uploadButton"
                                  />
                                  <Button
                                    onClick={event => {
                                      event.preventDefault()
                                      event.stopPropagation()
                                      setIsCameraMode(true)
                                    }}
                                    disabled={isUpdateMode}
                                    name="goToCaptureButton"
                                    iconComponent={
                                      <CameraOutlined
                                        style={{ marginRight: 0 }}
                                      />
                                    }
                                    label={t(
                                      'translation.AppointmentAttachments.slikaj'
                                    )}
                                  />
                                </div>
                              ) : null}

                              <Dropzone
                                ref={imageInput}
                                onDrop={handleImageSelect}
                                accept="image/jpeg, image/png, application/pdf"
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                  </div>
                                )}
                              </Dropzone>
                            </>
                          )}
                        </OrganizationAvatarRow>
                      </AttachmentColumn>
                      <FieldColumn
                        style={{
                          flexDirection: 'column',
                        }}
                      >
                        <FieldRow>
                          <FieldColumn>
                            <FormField.Text
                              label={t(
                                'translation.LocationGeneralInfo.label-name'
                              )}
                              name="attachmentTitle"
                              autoFocus
                            />
                          </FieldColumn>
                        </FieldRow>
                        <FieldRow>
                          <FieldColumn>
                            <FormField.Text
                              label={t(
                                'translation.LocationGeneralInfo.label-description'
                              )}
                              name="attachmentDescription"
                            />
                          </FieldColumn>
                        </FieldRow>
                        {!isMobile && (
                          <FormButtons>
                            <Button
                              footerButton
                              type="button"
                              buttonType={'text'}
                              label={t(
                                'translation.LocationGeneralInfo.button-close'
                              )}
                              name="closeUploadDrawerButton"
                              onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                handleDrawerAction('close')
                              }}
                            />

                            <Button
                              footerButton
                              type="button"
                              buttonType={'secondaryLink'}
                              label={t(
                                'translation.LocationGeneralInfo.button-save'
                              )}
                              disabled={!isUpdateMode ? !attachmentFile : false}
                              name="saveChanges"
                              onClick={
                                isUpdateMode ? handleUpdate : handleUpload
                              }
                            />
                          </FormButtons>
                        )}
                      </FieldColumn>
                    </FieldRow>
                  </>
                </AttachmentsFormContainer>
              </FormFieldsWrapperAttachments>
            ) : null}
            {uploadDrawerOpen ? (
              <FormFooterContainer
                hasShadow
                className="lg:absolute bottom-0 w-full"
              >
                <Button
                  type="button"
                  buttonType={isMobile ? 'secondary' : 'text'}
                  name={'close'}
                  label={t('translation.AppointmentModal.button-close')}
                  footerButton
                  onClick={
                    isMobile
                      ? e => {
                          e.preventDefault()
                          e.stopPropagation()
                          handleDrawerAction('close')
                        }
                      : closeModal
                  }
                />
                {canEditAppointment ? (
                  <Button
                    disabled={!isUpdateMode ? !attachmentFile : false}
                    name="saveChanges"
                    onClick={e =>
                      isUpdateMode
                        ? handleUpdate(e, true)
                        : handleUpload(e, true)
                    }
                    buttonType={'primary'}
                    label={t('translation.AppointmentModal.button-save')}
                  />
                ) : null}
              </FormFooterContainer>
            ) : (
              <FormFooterContainer
                hasShadow
                className="lg:absolute bottom-0 w-full"
              >
                <Button
                  buttonType={isMobile ? 'secondary' : 'text'}
                  name={'close'}
                  label={t('translation.AppointmentModal.button-close')}
                  footerButton
                  onClick={closeModal}
                />
                {canEditAppointment ? (
                  <Button
                    onClick={onSaveForm}
                    buttonType={'primary'}
                    name={'saveChanges'}
                    label={t('translation.AppointmentModal.button-save')}
                  />
                ) : null}
              </FormFooterContainer>
            )}
            {canEditAppointment && !uploadDrawerOpen && (
              <AttachmentUploadButton>
                <Button
                  type="button"
                  buttonType={'secondaryLink'}
                  label={t('translation.AppointmentAttachments.dodaj-privitak')}
                  name="addAttachment"
                  onClick={() => handleDrawerAction('open')}
                  iconComponent={<icons.Add size={'smaller'} />}
                />
              </AttachmentUploadButton>
            )}
            {selectedAttachments?.length ? (
              <AttachmentHeader
                style={{
                  fontSize: isMobile ? 13 : 14,
                  fontWeight: 400,
                  paddingBottom: 0,
                }}
              >
                {t('translation.AppointmentAttachments.selectedAttachments', {
                  selected: selectedAttachments?.length,
                  total: clientAttachments?.length,
                })}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingRight: 20,
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                      footerButton
                      type="button"
                      buttonType={'text'}
                      label={t(
                        'translation.AppointmentAttachments.odznaci-sve'
                      )}
                      size={'small'}
                      name="unselectAll"
                      onClick={() => setSelectedAttachments([])}
                      iconComponent={<Cross size={'smaller'} />}
                      hasIconOnly
                      style={{ marginRight: 10 }}
                    />
                    <Button
                      footerButton
                      type="button"
                      buttonType={'text'}
                      size={'small'}
                      label={t('translation.DeleteButton.label-delete')}
                      hasIconOnly
                      iconComponent={
                        <Trash
                          size="smaller"
                          stroke={2}
                          color={selectedTheme.colors.accent1}
                          style={{ width: 22, height: 22 }}
                        />
                      }
                      name="deleteAll"
                      onClick={() =>
                        showPopup({
                          onConfirm: () => handleDelete(),
                          titleText: t(
                            'translation.ClientAttachments.jeste-li-sigurni-da-zelite-obrisati-odabrane-privitke'
                          ),
                        })
                      }
                    />
                  </div>
                </div>
              </AttachmentHeader>
            ) : null}

            <AttachmentItemsGrid>
              {sortedClientAttachments?.map(attachment => (
                <AttachmentItemWrapper
                  isClickable={canEditAppointment}
                  key={attachment?.id}
                  onClick={async () => {
                    if (!canEditAppointment) return

                    const isSelected = selectedAttachments.find(
                      x => x === attachment.id
                    )
                    if (isSelected) {
                      setSelectedAttachments(prev =>
                        prev.filter(x => x !== attachment.id)
                      )
                    } else {
                      setSelectedAttachments(prev => [...prev, attachment.id])
                    }
                  }}
                >
                  <AttachmentItemLink isClickable={canEditAppointment}>
                    <AttachmentItemImage
                      isAppointment
                      isPdf={attachment.mimeType === 'application/pdf'}
                      src={attachment?.thumbnailUrl}
                    />
                    {attachment.mimeType !== 'application/pdf' ? (
                      <div
                        onClick={e => {
                          e.preventDefault()
                          e.stopPropagation()
                          setZoomedImage(attachment?.thumbnailUrl)
                        }}
                        className="absolute top-[5px] left-[5px] bg-black/10 rounded-lg w-7 h-7 flex justify-center cursor-pointer items-center shadow-[0_0_5px_rgba(0,0,0,0.1)] hover:bg-black/30 transition"
                      >
                        <icons.Search
                          size="size16"
                          className="text-white stroke-0"
                        />
                      </div>
                    ) : null}

                    {selectedAttachments.find(x => x === attachment.id) ? (
                      <CheckboxWrapper>
                        <Checkbox
                          checked={true}
                          style={{ position: 'absolute', top: 10, right: 10 }}
                        />
                      </CheckboxWrapper>
                    ) : null}

                    <AttachmentItemInfo>
                      <div className="flex justify-between my-1 items-center">
                        <p className="text-sm text-zoyya-secondaryDark relative top-[2px]">
                          {moment(attachment?.createdAt).format('DD.MM.YYYY.')}
                        </p>

                        <div>
                          {appointmentAttachments.find(
                            item => item.id === attachment.id
                          ) ? (
                            <icons.CalendarIcon size="smaller" />
                          ) : null}
                        </div>
                      </div>
                      <p className="font-medium text-zoyya-text text-left break-words mb-2">
                        {attachment.title}
                      </p>
                      {attachment.description ? (
                        <p className="text-sm pb-2 font-light">
                          {attachment.description}
                        </p>
                      ) : null}
                    </AttachmentItemInfo>
                  </AttachmentItemLink>

                  <BottomSectionButtonsWrapper>
                    {canEditAppointment ? (
                      <BottomIcon
                        onClick={e => {
                          e.stopPropagation()
                          setUploadDrawerOpen(true)
                          setAttachmentToUpdate(attachment)
                          setAttachmentFile(null)
                          setAttachmentPreview([])
                          setIsUpdateMode(true)
                          setIsCameraMode(false)
                          containerRef?.current?.scrollIntoView({
                            behavior: 'smooth',
                          })
                        }}
                      >
                        <Tooltip
                          label={t(
                            'translation.ClientDetailsMobile.edit-label'
                          )}
                        >
                          <Edit
                            color={'#6a4ee1'}
                            size={'smaller'}
                            style={{ marginRight: 'auto' }}
                          />
                        </Tooltip>
                      </BottomIcon>
                    ) : null}
                    {canShare ? (
                      <BottomShareIcon
                        onClick={e => {
                          e.stopPropagation()
                          handleShare(attachment)
                        }}
                      >
                        <Tooltip label={t('translation.clientRoutes.share')}>
                          <Share
                            color={'#6a4ee1'}
                            size={'smaller'}
                            stroke={1.7}
                            style={{ marginRight: 'auto' }}
                          />
                        </Tooltip>
                      </BottomShareIcon>
                    ) : null}
                    <BottomIcon
                      href={attachment.downloadUrl}
                      download
                      target="_blank"
                      onClick={e => {
                        e.stopPropagation()
                      }}
                    >
                      <Tooltip
                        label={t('translation.AppointmentAttachments.preuzmi')}
                      >
                        <Download
                          color={'#6a4ee1'}
                          size={'smaller'}
                          style={{ marginRight: 'auto' }}
                        />
                      </Tooltip>
                    </BottomIcon>
                  </BottomSectionButtonsWrapper>
                </AttachmentItemWrapper>
              ))}
              {zoomedImage && (
                <div className="absolute lg:bg-black/50 bg-white/80 h-full w-full top-0 left-0 flex items-center justify-center z-[9]">
                  <div className="relative w-11/12">
                    <div
                      onClick={() => setZoomedImage(null)}
                      className="absolute top-[5px] right-[5px] bg-black/30 rounded-lg w-7 h-7 flex justify-center items-center hover:bg-black/50 transition cursor-pointer"
                    >
                      <icons.Cross
                        size="size14"
                        className="text-white !stroke-2"
                      />
                    </div>
                    <div ref={wrapperRef}>
                      <img src={zoomedImage} className="w-full rounded-lg" />
                    </div>
                  </div>
                </div>
              )}
            </AttachmentItemsGrid>
          </FormsContainer>
        </EventBodyWrapper>
      </ModalBodyContainer>
    </ClientAttachmentsWrapper>
  )
}
