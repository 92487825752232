import { useQuery } from '@apollo/client'
import { Button, ButtonWithConfirmation, FormField } from 'components'
import { icons } from 'components/Icon'
import Warning from 'components/Icon/Icons/Warning'
import { MessagingButtons } from 'components/MessagingButtons/MessagingButtons'
import { includes } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useParams } from 'react-router-dom'
import { useSdk } from 'sdk'
import { useAppServices } from 'sdk/appServices'
import { UserContext } from 'src/Context'
import { PlanUpgradeField } from 'src/components/PlanUpgradeField/PlanUpgradeField'
import { useACL } from 'src/sdk/acl'
import { useGenders } from 'src/sdk/helperHooks'
import {
  useClientUpdateMutation,
  useCountryAllQuery,
  usePrepaymentsGetStripeConnectAccountInfoQuery,
} from 'state/graphql'
import { getOrganizationByIdGql } from 'state/query/getOrganizationById'
import styled from 'styled-components'
import {
  FieldColumn,
  FieldRow,
  SettingsAccountAvatar,
} from 'views/Settings/styles'
// import { useGetClient } from 'state/queries'
import moment from 'moment'
import { formatPhone } from 'src/helpers'
import { WarningTriangle } from 'src/components/Icon/Icons/WarningTriangle'
import { trpc } from 'src/trpc'

type TWarningContainerProps = {}
export const WarningContainer = styled.div<TWarningContainerProps>`
  display: grid;
  grid-template-columns: 18px auto;
`

type TMultipleRowsProps = {}
export const MultipleRows = styled.div<TMultipleRowsProps>`
  display: flex;
  flex-direction: column;
  gap: 14px;
  justify-content: start;
`

type TClientImageWrapper = {}
export const ClientImageWrapper = styled.div<TClientImageWrapper>`
  margin-right: 15px;
  align-self: flex-start;
`

type TInputWrapper = {}
export const InputWrapper = styled.div<TInputWrapper>`
  display: flex;
  flex-direction: column;
`

type TGridRow = {
  oneColumn?: boolean
}
export const GridRow = styled.div<TGridRow>`
  display: grid;
  grid-template-columns: ${props => (props?.oneColumn ? 'auto' : '220px auto')};
`

type TClientFormProps = {
  values: any
  errors: any
  setFieldValue: any
  showSendInviteButton?: boolean
  onSendInviteClick?: () => any
  refetchClient?: () => any
  isEdit?: boolean
  setActiveForm?: any
  location?: any
  isAppointment?: boolean
}

export const ClientForm = (props: TClientFormProps) => {
  const {
    errors,
    values,
    showSendInviteButton,
    onSendInviteClick,
    isEdit,
    setActiveForm,
    setFieldValue,
    location,
    isAppointment,
    refetchClient,
  } = props
  const genders = useGenders()

  const { pathname } = useLocation()
  const { t, isMobile } = useSdk()
  const { data: countriesData } = useCountryAllQuery()
  const [isEmailEmpty, setIsEmailEmpty] = useState<boolean>(
    !values?.email || !!errors?.email
  )
  const [isSmsEmpty, setIsSmsEmpty] = useState<boolean>(
    !values?.mobilePhone || !!errors?.mobilePhone
  )

  const { hasPermission } = useACL()

  const canAccessPhone = hasPermission('ACCESS_CLIENT_PHONE')
  const canAccessEmail = hasPermission('ACCESS_CLIENT_EMAIL')

  const { setOpenBillingModal } = useContext<any>(UserContext)

  const { orgId } = useParams<any>()
  const { data: organizationData } = useQuery(getOrganizationByIdGql, {
    variables: { id: orgId },
  })

  const org = organizationData?.organization?.get
  const isGroupLocation = location?.bookingKind === 'GROUP'

  useEffect(() => {
    setIsSmsEmpty(!values?.mobilePhone || !!errors?.mobilePhone)
    setIsEmailEmpty(!values?.email || !!errors?.email)
  }, [values, errors])

  // useEffect(() => {
  //   refetchClient()
  // }, [])

  // const isFromClientList = includes(pathname, 'clients/list/')
  const isFromClientList =
    includes(pathname, 'clients/list/edit') ||
    includes(pathname, 'clients/list/new/newClient')

  const [clientUpdateMutation] = useClientUpdateMutation()
  const clientActivateEmail = trpc.client_activateEmail.useMutation()

  const appServices = useAppServices()

  // const {
  //   data: clientData,
  //   loading: loadingClient,
  //   refetch: refetchClient,
  // } = useGetClient(values?.client?.id)
  // const client = clientData?.client?.get

  const handleActivateEmail = async () => {
    const result = await clientActivateEmail.mutateAsync({
      clientId: BigInt(isAppointment ? values?.client?.id : values?.id),
      email: values?.email,
    })

    appServices
      .handleMutationResult(
        {},
        t('translation.EditClient.toast-email-activated')
      )
      .onSuccess(() => {
        // refetchClient()
      })

    await refetchClient?.()
  }

  const handleBlock = async () => {
    let clientResult = await clientUpdateMutation({
      variables: {
        input: {
          id: values?.client?.id,
          status: !values?.client?.status ? 'BLOCKED' : null,
          // here we put also other properties to prevent erasing values by setting to null
          firstName: values?.client.firstName?.trim(),
          lastName: values?.client.lastName?.trim() || '',
          fullName:
            values?.client.firstName?.trim() +
            ' ' +
            (values?.client.lastName?.trim() || ''),
          email: values?.client.email ? values?.client.email?.trim() : '',
          comment: values?.client.comment,
          gender: values?.client.gender,
          category: values?.client.category,
          mobilePhone: formatPhone(values?.client.mobilePhone),
          address: values?.client.address,
          city: values?.client.city,
          zipCode: values?.client.zipCode,
          vatId: !values?.client.vatId ? '' : values?.client.vatId.trim(),
          identifier: values?.client.identifier,
          countryId: values?.client.country?.id,
          birthDate: values?.client?.birthDate
            ? moment(values?.client.birthDate).format('YYYY-MM-DD')
            : null,
          membershipExpirationDate: values?.client?.membershipExpirationDate
            ? moment(values?.client.membershipExpirationDate).format(
                'YYYY-MM-DD'
              )
            : null,
          sendReminderSms: values?.client?.sendReminderSms,
          sendReminderEmail: values?.client?.sendReminderEmail,
          sendNewsEmail: values?.client?.sendNewsEmail,
          sendBookingEmail: values?.client?.sendBookingEmail,
          sendHelpEmail: values?.client?.sendHelpEmail,
          paymentRequired: values?.client?.paymentRequired,
        },
      },
    })

    appServices
      .handleMutationResult(
        clientResult,
        !values.client?.status
          ? t('translation.EditClient.toast-client-status-blocked')
          : t('translation.EditClient.toast-client-status-unblocked')
      )
      .onSuccess(() => {
        setActiveForm('appointment')
        // refetchClient()
      })

    setFieldValue('client', {
      ...values.client,
      status: !values.client?.status ? 'BLOCKED' : null,
    })
  }

  const blockButton = () => {
    return (
      <FieldRow>
        <FieldColumn
          style={{ justifyContent: 'flex-end', marginBottom: '15px' }}
        >
          <ButtonWithConfirmation
            buttonLabel={
              values?.client?.status === 'BLOCKED'
                ? t('translation.NewClientContainer.label-unblock')
                : t('translation.NewClientContainer.label-block')
            }
            buttonIcon={
              values?.client?.status === 'BLOCKED' ? (
                <icons.Add size="smaller" stroke={2} />
              ) : (
                <icons.Cross size="smaller" stroke={2} />
              )
            }
            withConfirm
            onConfirm={() => handleBlock()}
            confirmationMessage={
              values?.client?.status === 'BLOCKED'
                ? t('translation.NewClientContainer.title-confirmClientUnblock')
                : t('translation.NewClientContainer.title-confirmClientBlock')
            }
          />
        </FieldColumn>
      </FieldRow>
    )
  }

  useEffect(() => {
    if (isEmailEmpty) {
      setFieldValue('paymentRequired', false)
    } else {
      if (isEdit) {
        setFieldValue('paymentRequired', values?.paymentRequired)
      } else {
        setFieldValue('paymentRequired', true)
      }
    }
  }, [isEmailEmpty])

  // if (loadingClient) return <Loader isComponent={true} />

  const { data: stripeConnectAccountData } =
    usePrepaymentsGetStripeConnectAccountInfoQuery({
      skip: !org?.stripeConnectAccountId,
    })

  const hasStripeChargesEnabled =
    stripeConnectAccountData?.prepayments?.getStripeConnectAccountInfo
      ?.hasChargesEnabled

  const hasInvoicingData =
    location?.prepaymentsConfig?.userId &&
    location?.prepaymentsConfig?.operatorId &&
    location?.prepaymentsConfig?.prepaymentsDeviceId &&
    location?.prepaymentsConfig?.prepaymentServiceId

  const hasPrepaymentsCredentials =
    org?.prepaymentsProvider === 'STRIPE'
      ? hasStripeChargesEnabled && hasInvoicingData
      : org?.hasVivaMerchantId && org?.hasVivaApiKey && hasInvoicingData

  const isMailboxDisabled = isAppointment
    ? values?.client?.mailboxStatus === 'DISABLED'
    : values?.mailboxStatus === 'DISABLED'
  const mailboxDisabledReason = isAppointment
    ? values?.client?.mailboxDisabledReason
    : values?.mailboxDisabledReason

  return (
    <>
      {!isMobile ? (
        <GridRow oneColumn={!isFromClientList || isAppointment}>
          {isFromClientList && !isAppointment ? (
            <SettingsAccountAvatar
              style={{
                width: '180px',
                height: '180px',
                maxWidth: '180px',
                maxHeight: '180px',
                marginRight: 0,
              }}
              data-cy="settings-avatar-image"
              src={
                values?.user?.avatarUrl &&
                !values?.user?.avatarUrl?.endsWith('null')
                  ? values?.user?.avatarUrl
                  : values?.user?.google_profilePicUrl ||
                    values?.user?.fb_profilePicUrl ||
                    '/assets/default-avatar.svg'
              }
            />
          ) : null}
          <InputWrapper>
            <FieldRow>
              <FieldColumn>
                <FormField.Text
                  autoFocus={!values?.firstName}
                  label={t(
                    'translation.AppointmentModal.label-clientFirstName'
                  )}
                  name="firstName"
                />
              </FieldColumn>
              <FieldColumn>
                <FormField.Text
                  label={t('translation.AppointmentModal.label-clientLastName')}
                  name="lastName"
                />
              </FieldColumn>

              {!isGroupLocation ? null : org?.subscriptionPlan ===
                'advanced' ? (
                <FieldColumn>
                  <FormField.DateInput
                    label={t(
                      'translation.NewClientContainer.label-membershipExpirationDate'
                    )}
                    displayFormat="DD.MM.YYYY."
                    name="membershipExpirationDate"
                  />
                </FieldColumn>
              ) : (
                <PlanUpgradeField
                  fullWidthButton
                  title={t(
                    'translation.NewClientContainer.label-membershipExpirationDate'
                  )}
                  text={t(
                    'translation.LocationOnLineBookingSettings.label-only-advanced-plan'
                  )}
                  handleClick={() => setOpenBillingModal(true)}
                />
              )}
            </FieldRow>
            <FieldRow>
              {canAccessEmail ? (
                <FieldColumn>
                  <FormField.Text
                    label="Email"
                    name="email"
                    helpText={t(
                      'translation.NewClientContainer.tooltip-clientEmail'
                    )}
                  />
                </FieldColumn>
              ) : null}

              {canAccessPhone ? (
                <FieldColumn>
                  <FormField.PhoneInput
                    label={t('translation.AppointmentModal.label-mobilePhone')}
                    placeholder={t(
                      'translation.AppointmentModal.placeholder-mobilePhone'
                    )}
                    helpText={t(
                      'translation.NewClientContainer.tooltip-clientMobilePhone'
                    )}
                    name="mobilePhone"
                  />
                </FieldColumn>
              ) : null}
            </FieldRow>
            <FieldRow style={{ alignItems: 'flex-start' }}>
              <FieldColumn>
                {isMailboxDisabled ? (
                  <WarningContainer className="flex items-center">
                    <WarningTriangle
                      className="text-zoyya-accent5"
                      size={'smaller'}
                    />
                    <div className="ml-2 text-[13px] text-zoyya-accent5">
                      {mailboxDisabledReason?.includes('spamreport')
                        ? t('translation.NewClientContainer.email-spamreported')
                        : t('translation.NewClientContainer.email-blocked')}
                    </div>

                    {mailboxDisabledReason &&
                    !mailboxDisabledReason?.includes('spamreport') ? (
                      <div className="mt-2 ml-auto">
                        <Button
                          style={{ width: 'fit-content' }}
                          size={'smaller'}
                          type="button"
                          label={t(
                            'translation.NewClientContainer.activate-email'
                          )}
                          buttonType={'secondary'}
                          onClick={handleActivateEmail}
                        />
                      </div>
                    ) : null}
                  </WarningContainer>
                ) : (!isEdit && !isEmailEmpty) ||
                  (values?.client?.user?.emailNotificationSettings
                    ?.sendReminderEmail &&
                    !isEmailEmpty) ||
                  (values?.user?.emailNotificationSettings?.sendReminderEmail &&
                    !isEmailEmpty) ||
                  (!values?.client?.user && !values?.user && !isEmailEmpty) ? (
                  <MultipleRows>
                    {showSendInviteButton &&
                      !values?.client?.user?.id &&
                      (isEdit ? (
                        <Button
                          style={{ width: 'fit-content' }}
                          label={t(
                            'translation.NewClientContainer.label-sendInviteToEmail'
                          )}
                          buttonType={'secondary'}
                          onClick={onSendInviteClick}
                        />
                      ) : (
                        <FormField.Checkbox
                          label={t(
                            'translation.NewClientContainer.label-sendInviteToEmail'
                          )}
                          name="sendInviteViaEMail"
                          type={'checkbox'}
                          helpText={t(
                            'translation.NewClientContainer.sendInviteToEmail-helpText'
                          )}
                        />
                      ))}
                    <FormField.Checkbox
                      label={t(
                        'translation.NewClientContainer.label-send-appointment-email'
                      )}
                      name="sendReminderEmail"
                      type={'checkbox'}
                      helpText={t(
                        'translation.Notifications.email-notifications-hint'
                      )}
                    />
                  </MultipleRows>
                ) : (!!values?.client?.user || !!values?.user) &&
                  (values?.client?.user?.emailNotificationSettings
                    ?.sendReminderEmail === false ||
                    values?.user?.emailNotificationSettings
                      ?.sendReminderEmail === false) &&
                  isEdit ? (
                  <WarningContainer>
                    <Warning color={'orange'} size={'smaller'} />
                    <i style={{ marginLeft: '8px' }}>
                      {t(
                        'translation.NewClientContainer.label-client-disabled-email-warning'
                      )}
                    </i>
                  </WarningContainer>
                ) : null}
              </FieldColumn>
              <FieldColumn>
                {(!isEdit && !isSmsEmpty) ||
                (values?.client?.user?.emailNotificationSettings
                  ?.sendReminderSms &&
                  !isSmsEmpty) ||
                (values?.user?.emailNotificationSettings?.sendReminderSms &&
                  !isSmsEmpty) ||
                (!values?.client?.user && !values?.user && !isSmsEmpty) ? (
                  <FormField.Checkbox
                    label={t(
                      'translation.NewClientContainer.label-send-appointment-sms'
                    )}
                    name="sendReminderSms"
                    type={'checkbox'}
                    helpText={t(
                      'translation.Notifications.sms-notifications-hint'
                    )}
                  />
                ) : (!!values?.client?.user || !!values?.user) &&
                  (values?.client?.user?.emailNotificationSettings
                    ?.sendReminderSms === false ||
                    values?.user?.emailNotificationSettings?.sendReminderSms ===
                      false) &&
                  isEdit ? (
                  <WarningContainer>
                    <Warning color={'orange'} size={'smaller'} />
                    <i style={{ marginLeft: '8px' }}>
                      {t(
                        'translation.NewClientContainer.label-client-disabled-sms-warning'
                      )}
                    </i>
                  </WarningContainer>
                ) : null}
              </FieldColumn>
            </FieldRow>
          </InputWrapper>
        </GridRow>
      ) : (
        <>
          <FieldRow
            style={{
              flexDirection: 'row',
              alignItems: 'flex-end',
              marginBottom: '14px',
            }}
          >
            {isFromClientList ? (
              <SettingsAccountAvatar
                style={{
                  width: '100px',
                  height: '100px',
                  minWidth: '100px',
                  minHeight: '100px',
                }}
                data-cy="settings-avatar-image"
                src={
                  values?.user?.avatarUrl &&
                  !values?.user?.avatarUrl?.endsWith('null')
                    ? values?.user?.avatarUrl
                    : values?.user?.google_profilePicUrl ||
                      values?.user?.fb_profilePicUrl ||
                      '/assets/default-avatar.svg'
                }
              />
            ) : null}

            <FieldColumn style={{ marginBottom: '0px' }}>
              <FormField.Text
                autoFocus={!values?.firstName}
                label={t('translation.AppointmentModal.label-clientFirstName')}
                name="firstName"
                autoCapitalize="on"
              />
            </FieldColumn>
          </FieldRow>

          <FieldRow>
            <FieldColumn>
              <FormField.Text
                label={t('translation.AppointmentModal.label-clientLastName')}
                name="lastName"
                autoCapitalize="on"
              />
            </FieldColumn>
          </FieldRow>

          {!isGroupLocation ? null : org?.subscriptionPlan === 'advanced' ? (
            <FieldColumn>
              <FormField.DateInput
                label={t(
                  'translation.NewClientContainer.label-membershipExpirationDate'
                )}
                displayFormat="DD.MM.YYYY."
                name="membershipExpirationDate"
              />
            </FieldColumn>
          ) : (
            <PlanUpgradeField
              fullWidthButton
              title={t(
                'translation.NewClientContainer.label-membershipExpirationDate'
              )}
              text={t(
                'translation.LocationOnLineBookingSettings.label-only-advanced-plan'
              )}
              handleClick={() => setOpenBillingModal(true)}
            />
          )}

          <FieldRow style={{ alignItems: 'flex-start' }}>
            {canAccessEmail ? (
              <FieldColumn>
                <FormField.Text
                  label="Email"
                  name="email"
                  helpText={t(
                    'translation.NewClientContainer.tooltip-clientEmail'
                  )}
                />
              </FieldColumn>
            ) : null}
            <FieldColumn>
              {isMailboxDisabled ? (
                <WarningContainer className="flex items-center">
                  <WarningTriangle
                    className="text-zoyya-accent5"
                    size={'smaller'}
                  />
                  <div className="ml-2 text-[13px] text-zoyya-accent5">
                    {mailboxDisabledReason?.includes('spamreport')
                      ? t('translation.NewClientContainer.email-spamreported')
                      : t('translation.NewClientContainer.email-blocked')}
                  </div>

                  {mailboxDisabledReason &&
                  !mailboxDisabledReason?.includes('spamreport') ? (
                    <div className="mt-2 ml-auto">
                      <Button
                        style={{ width: 'fit-content' }}
                        size={'smaller'}
                        type="button"
                        label={'Aktiviraj email'}
                        buttonType={'secondary'}
                        onClick={handleActivateEmail}
                      />
                    </div>
                  ) : null}
                </WarningContainer>
              ) : (!isEdit && !isEmailEmpty) ||
                (values?.client?.user?.emailNotificationSettings
                  ?.sendReminderEmail &&
                  !isEmailEmpty) ||
                (values?.user?.emailNotificationSettings?.sendReminderEmail &&
                  !isEmailEmpty) ||
                (!values?.client?.user && !values?.user && !isEmailEmpty) ? (
                <MultipleRows>
                  {showSendInviteButton &&
                    (isEdit ? (
                      <Button
                        style={{ width: 'fit-content' }}
                        label={t(
                          'translation.NewClientContainer.label-sendInviteToEmail'
                        )}
                        buttonType={'secondary'}
                        onClick={onSendInviteClick}
                      />
                    ) : (
                      <FormField.Checkbox
                        label={t(
                          'translation.NewClientContainer.label-sendInviteToEmail'
                        )}
                        name="sendInviteViaEMail"
                        type={'checkbox'}
                        helpText={t(
                          'translation.NewClientContainer.sendInviteToEmail-helpText'
                        )}
                      />
                    ))}
                  <FormField.Checkbox
                    label={t(
                      'translation.NewClientContainer.label-send-appointment-email'
                    )}
                    name="sendReminderEmail"
                    type={'checkbox'}
                    helpText={t(
                      'translation.Notifications.email-notifications-hint'
                    )}
                  />
                </MultipleRows>
              ) : (!!values?.client?.user || !!values?.user) &&
                (values?.client?.user?.emailNotificationSettings
                  ?.sendReminderEmail === false ||
                  values?.user?.emailNotificationSettings?.sendReminderEmail ===
                    false) &&
                isEdit ? (
                <WarningContainer>
                  <Warning color={'orange'} size={'smaller'} />
                  <i style={{ marginLeft: '8px' }}>
                    {t(
                      'translation.NewClientContainer.label-client-disabled-email-warning'
                    )}
                  </i>
                </WarningContainer>
              ) : null}
            </FieldColumn>
          </FieldRow>

          <FieldRow>
            {canAccessPhone ? (
              <FieldColumn>
                <FormField.PhoneInput
                  label={t('translation.AppointmentModal.label-mobilePhone')}
                  placeholder={t(
                    'translation.AppointmentModal.placeholder-mobilePhone'
                  )}
                  helpText={t(
                    'translation.NewClientContainer.tooltip-clientMobilePhone'
                  )}
                  name="mobilePhone"
                />
              </FieldColumn>
            ) : null}
            <FieldColumn>
              {(!isEdit && !isSmsEmpty) ||
              (values?.client?.user?.emailNotificationSettings
                ?.sendReminderSms &&
                !isSmsEmpty) ||
              (values?.user?.emailNotificationSettings?.sendReminderSms &&
                !isSmsEmpty) ||
              (!values?.client?.user && !values?.user && !isSmsEmpty) ? (
                <FormField.Checkbox
                  label={t(
                    'translation.NewClientContainer.label-send-appointment-sms'
                  )}
                  name="sendReminderSms"
                  type={'checkbox'}
                  helpText={t(
                    'translation.Notifications.sms-notifications-hint'
                  )}
                />
              ) : (!!values?.client?.user || !!values?.user) &&
                (values?.client?.user?.emailNotificationSettings
                  ?.sendReminderSms === false ||
                  values?.user?.emailNotificationSettings?.sendReminderSms ===
                    false) &&
                isEdit ? (
                <WarningContainer>
                  <Warning color={'orange'} size={'smaller'} />
                  <i style={{ marginLeft: '8px' }}>
                    {t(
                      'translation.NewClientContainer.label-client-disabled-sms-warning'
                    )}
                  </i>
                </WarningContainer>
              ) : null}
            </FieldColumn>
          </FieldRow>
          {canAccessPhone && values?.mobilePhone && !isSmsEmpty ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginBottom: 10,
              }}
            >
              <MessagingButtons
                phoneNumber={values?.mobilePhone}
              ></MessagingButtons>
            </div>
          ) : null}
        </>
      )}

      <FieldRow>
        <FieldColumn>
          <FormField.Select
            label={t('translation.NewClientContainer.label-gender')}
            name="gender"
            parseValue={val => genders.find(e => e.id === val)}
            formatValue={val => val?.id}
            options={genders}
            isSearchable={false}
          />
        </FieldColumn>

        <FieldColumn>
          <FormField.Text
            label={t('translation.OrganizationDesktop.label-vatid')}
            name="vatId"
            helpText={t('translation.NewClientContainer.clientVatId')}
          />
        </FieldColumn>
        <FieldColumn>
          <FormField.DateInput
            label={t('translation.NewClientContainer.label-dob')}
            name="birthDate"
            displayFormat="DD.MM.YYYY."
          />
        </FieldColumn>
      </FieldRow>
      <FieldRow style={{ alignItems: 'flex-end' }}>
        <FieldColumn>
          <FormField.Select
            label={t('translation.LocationGeneralInfo.label-country')}
            name="country"
            formatOptionLabel={e => e.countryName}
            options={countriesData?.country?.all as any[]}
            getOptionLabel={e => e.countryName}
          />
        </FieldColumn>
        <FieldColumn>
          <FormField.Text
            label={t('translation.LocationGeneralInfo.label-address')}
            name="address"
            helpText={t('translation.LocationGeneralInfo.tooltip-info-address')}
          />
        </FieldColumn>
      </FieldRow>
      <FieldRow style={{ alignItems: 'flex-end' }}>
        <FieldColumn>
          <FormField.Text
            label={t('translation.LocationGeneralInfo.label-zip-code')}
            name="zipCode"
          />
        </FieldColumn>
        <FieldColumn>
          <FormField.Text
            label={t('translation.LocationGeneralInfo.label-city')}
            name="city"
          />
        </FieldColumn>
      </FieldRow>
      <FieldRow>
        <FieldColumn>
          <FormField.TextArea
            rows={2}
            label={t('translation.NewClientContainer.label-comment')}
            name="comment"
          />
        </FieldColumn>
      </FieldRow>

      {!isGroupLocation ? (
        <FieldRow>
          <FieldColumn>
            <FormField.Checkbox
              disabled={!values?.email || !hasPrepaymentsCredentials}
              label={t('translation.NewClientContainer.label-paymentRequired')}
              name="paymentRequired"
              type={'checkbox'}
              helpText={t(
                'translation.NewClientContainer.label-paymentRequired-helpText'
              )}
            />
          </FieldColumn>
        </FieldRow>
      ) : null}

      {isEdit && !pathname.includes('clients/list/edit') ? blockButton() : null}
    </>
  )
}
