import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { RouterOutput, trpc } from 'src/trpc'
import { Loader } from 'src/components'
import { useSdk } from 'src/sdk'
import { getOrganizationByIdGql } from 'src/state/query/getOrganizationById'
import { useQuery } from '@apollo/client'
import { GiftCardForm } from './GiftCardForm'
import { getGiftCardStatusOptions, serialize } from 'src/helpers'
import { useClientGetQuery } from 'src/state/graphql'
import { GiftCardScreen } from './GiftCardScreen'
import { useHeader } from 'src/mobile/Header'
import moment from 'moment'

export const EditGiftCard = () => {
  const params = useParams<{
    locationId: string
    orgId: string
    id: string
  }>()
  const { locationId, orgId, id } = params
  const [isBusy, setIsBusy] = useState<boolean>(false)
  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(false)
  const { navigateTo, appServices } = useSdk()
  const trpcContext = trpc.useContext()

  const { data: organizationData, loading: loadingOrgData } = useQuery(
    getOrganizationByIdGql,
    {
      variables: { id: orgId },
    }
  )
  const org = organizationData?.organization?.get

  const { data, isLoading, refetch, isRefetching } =
    trpc.giftCard_findOne.useQuery({
      orgId: orgId,
      locationId: BigInt(locationId),
      id: BigInt(id),
    })

  const giftCard = data as RouterOutput['giftCard_findOne']

  useHeader({
    content:
      giftCard?.giftCardType?.toString()?.toLowerCase() === 'giftcard'
        ? 'Poklon bon'
        : 'Paket',
    onGoBack: () =>
      navigateTo.listGiftCard({
        voucherType: giftCard?.giftCardType?.toString()?.toLowerCase(),
      }),
  })

  const { data: clientData, loading: loadingClient } = useClientGetQuery({
    variables: {
      id: String(giftCard?.clientId),
    },
    skip: !giftCard?.clientId,
  })

  const client = clientData?.client?.get

  const updateGiftCardMutation = trpc.giftCard_update.useMutation()

  useEffect(() => {
    trpcContext.giftCard_findOne.invalidate()
  }, [])

  useEffect(() => {
    giftCard?.status === 'ACTIVE'
      ? setIsPreviewMode(true)
      : setIsPreviewMode(false)
  }, [giftCard])

  const handleSubmit = async values => {
    try {
      setIsBusy(true)
      const res = await updateGiftCardMutation.mutateAsync({
        name: values?.name,
        id: giftCard?.id!,
        clientId: values?.client?.id ? BigInt(values?.client?.id) : null,
        description: serialize(values?.description),
        terms: values?.terms,
        status: values?.status?.id.toUpperCase(),
        sendEmailToClient: values?.sendEmailToClient,
        validTo: values?.validTo
          ? moment(values?.validTo).format('YYYY-MM-DD')
          : null,
      })

      if (res) {
        refetch()
        appServices.toast.success('Poklon bon je uspješno izmijenjen')
        await trpcContext.giftCard_pos_findMany.invalidate(undefined, {
          refetchType: 'all',
        })

        navigateTo.listGiftCard({
          type: 'giftcard',
          voucherType: giftCard?.giftCardType?.toLowerCase(),
        })
      } else
        appServices.toast.danger(
          'Došlo je do greške prilikom spremanja poklon bona'
        )
    } catch (e) {
      appServices.toast.danger(
        'Došlo je do greške prilikom spremanja poklon bona'
      )
    } finally {
      setIsBusy(false)
    }
  }

  const isGiftCardUsed =
    data?.giftCardType === 'GIFTCARD'
      ? data?.balance !== data?.value
      : !data?.items?.every(item => {
          const itemInTemplate = giftCard?.items?.find(
            x => x.serviceId === item.serviceId
          )
          return itemInTemplate?.quantity === item.quantity
        })

  if (isLoading || loadingOrgData || loadingClient)
    return <Loader isComponent />

  if (isPreviewMode)
    return (
      <GiftCardScreen
        currency={org?.currency?.id}
        data={giftCard}
        setIsPreviewMode={setIsPreviewMode}
      />
    )

  return (
    <GiftCardForm
      initialValues={{
        name: giftCard?.name || '',
        giftCardTemplateId: {
          id: giftCard?.giftCardTemplateId,
          name: giftCard?.giftCardTemplate?.name,
        },
        code: giftCard?.code || '',
        clientId: giftCard?.clientId || null,
        description: giftCard?.description || '',
        terms: giftCard?.terms || '',
        status: getGiftCardStatusOptions(isGiftCardUsed, giftCard?.status).find(
          x => x?.id === giftCard?.status?.toLowerCase()
        ),
        voucherType: giftCard?.giftCardType,
        validTo: giftCard?.validTo
          ? moment(giftCard?.validTo).format('YYYY-MM-DD') //format(giftCard?.validTo, 'MM/dd/yyyy')
          : null,
        client,
        items: giftCard?.items,
        sendEmailToClient: false,
        isSold: giftCard?.isSold,
      }}
      currency={org?.currency?.id}
      language={org?.language}
      onSubmit={handleSubmit}
      loading={isBusy || isRefetching}
      isEdit={true}
      refetch={refetch}
      giftCardInitialClientId={giftCard?.clientId?.toString()}
      isGiftCardUsed={isGiftCardUsed}
    />
  )
}

export default EditGiftCard
