import React from 'react'
import styled from 'styled-components'
import * as styles from './style'

const StyledIcon = styled.svg<any>`
  ${styles.base};
  ${props => styles[props.size]};
  stroke-width: ${props => props.stroke || 1.5};
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
`

export const Support = props => {
  return (
    <StyledIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 25 25"
    >
      <g
        fill="none"
        stroke="inherit"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M16,21.5h1c1.934,0,3.5-1.067,3.5-3V16.33" />
        <path d="M19.5,8.5a4,4,0,0,1,1.334,7.772A1,1,0,0,1,19.5,15.33Z" />
        <path d="M4.5,8.5a4,4,0,0,0-1.334,7.772A1,1,0,0,0,4.5,15.33Z" />
        <path d="M19.5,10V8a7.5,7.5,0,0,0-15,0v2" />
        <path d="M11.5,20.5h4a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5h-4a1,1,0,0,1-1-1v0A1,1,0,0,1,11.5,20.5Z" />
        <path d="M15.225,14.665a4.56,4.56,0,0,1-6.45,0" />
        <path d="M8.75,9.661a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
        <path d="M15.25,9.661a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
      </g>
    </StyledIcon>
  )
}
