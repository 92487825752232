import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Styles from './style'
import { Warning } from '../Icon'
import Text from '../Text/Text'
import ChevronDown from '../Icon/Icons/ChevronDown'
import { ButtonArrow } from '../Button/style'

export const Input = props => {
  const [showPassword, setShowPassword] = useState(false)

  //const [currentValue, setCurrentValue] = React.useState(props.value);
  const ref = React.useRef()

  const {
    disabled,
    hasError,
    label,
    maxLength,
    id,
    name,
    onChange,
    onBlur,
    onKeyUp,
    prefix,
    placeholder,
    size,
    type,
    onClick,
    forwardedRef,
    required,
    autoFocus,
    icon,
    isCalendar,
    value,
    autoComplete,
    style,
  } = props

  const inputProps = {
    isCalendar,
    disabled,
    hasError,
    maxLength,
    hasIcon: !!icon,
    name,
    onChange,
    onClick,
    onBlur,
    onKeyUp,
    prefix,
    placeholder,
    autoComplete,
    type: type === 'password' && showPassword ? 'text' : type,
    size,
    value: value || '',
    ref: forwardedRef,
    required: required,
    autoFocus,
    'aria-required': required ? true : undefined,
    style,
  }
  if (id) {
    inputProps['id'] = id
  }
  return (
    <div className="w-full">
      {label.length > 0 && (
        <Text htmlFor={id} type="label">
          {label}
        </Text>
      )}
      <Styles.InputFieldWrapper prefix={prefix} size={size}>
        {icon}
        <Styles.InputStyled
          {...inputProps}
          ref={ref}
          value={value}
          onChange={onChange}
          data-cy={`input_${name}`}
        />
        <Styles.InputIcon>
          {isCalendar ? (
            <ButtonArrow>
              <ChevronDown color={'grayDark'} isChevron />
            </ButtonArrow>
          ) : null}
        </Styles.InputIcon>
      </Styles.InputFieldWrapper>
      {hasError && (
        <Styles.HelpTextWrapper>
          {hasError && <Warning size="smaller" />}
          <Styles.HelpText
            type="help"
            htmlFor={name}
            data-cy={`input_${name}`}
            hasError={hasError}
          >
            {hasError}
          </Styles.HelpText>
        </Styles.HelpTextWrapper>
      )}
    </div>
  )
}

Input.propTypes = {
  /** It disables the input field. </i> */
  disabled: PropTypes.bool,
  /** It colors the field in red. */
  hasError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /** Marks the input as required */
  required: PropTypes.bool,
  /** It adds an help text below the input box. */
  help: PropTypes.string,
  /** Id to link label with input for a11y */
  id: PropTypes.string,
  /** It adds a label on top of the input box. Make sure you also add an id for a11y */
  label: PropTypes.string,
  /** It adds a maxlength option for the input. */
  maxLength: PropTypes.string,
  /** It's the name of the input. */
  name: PropTypes.string.isRequired,
  /** It's the placeholder value of the input. */
  placeholder: PropTypes.string,
  /** Optional object describing fixed text that is placed inside the input, format is `{ text: '@', paddingLeft: '30px' }` */
  prefix: PropTypes.shape({
    text: PropTypes.string.isRequired,
    paddingLeft: PropTypes.string.isRequired,
  }),
  /** The onChange event */
  onChange: PropTypes.func,
  /** The onBlur event */
  onBlur: PropTypes.func,
  /** The onKeyUp event */
  onKeyUp: PropTypes.func,
  /** This is the vertical size of the input field, can be `small`, `regular`, or `tall` */
  size: PropTypes.string,
  /** The type of the input */
  type: PropTypes.string,
  /** The value of the input */
  value: PropTypes.string,
  /**
   * this consumed by the default export that is wrapping the component into a ForwardRef
   * @ignore
   */
  forwardedRef: PropTypes.shape({ current: PropTypes.any }),
  onClick: PropTypes.func,
  isCalendar: PropTypes.bool,
  icon: PropTypes.object,
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
}

Input.defaultProps = {
  disabled: false,
  hasError: false,
  required: false,
  help: '',
  id: '',
  label: '',
  placeholder: '',
  size: 'regular',
  type: 'text',
  value: undefined,
  onBlur: () => {},
  onKeyUp: () => {},
  forwardedRef: undefined,
  prefix: null,
  maxLength: undefined,
}
export default Input
