import React, { InputHTMLAttributes } from 'react'
import { useField, useFormikContext } from 'formik'
import styled from 'styled-components'
import {
  blue,
  blueLighter,
  grayLight,
  grayDarker,
  grayDark,
  red,
  redLight,
  redLighter,
  white,
} from 'components/style/colors'
import {
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
} from 'components/style/fonts'
import { FieldContainer } from 'components/Form/FieldContainer'
import { FieldRow } from 'views/Settings/styles'
import { device } from '../../../constants'
import mobiscroll from '@mobiscroll/scroller'
import '@mobiscroll/scroller/dist/css/mobiscroll.react.min.css'

mobiscroll.settings = {
  theme: 'ios',
  themeVariant: 'light',
}

export const StyledInput = styled.input<any>`
  position: relative;
  box-sizing: border-box;
  margin: ${props => (props.size === 'small' ? '0 0 8px 0' : '0px')};
  background: ${({ hasError }) => (hasError ? redLight : white)};
  border-radius: 4px;
  border: 1px solid ${({ hasError }) => (hasError ? red : grayLight)};
  box-shadow: 2px 2px 0 2px transparent;
  transition-property: border-width, border-color, box-shadow;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
  color: ${({ hasError }) => (hasError ? red : grayDarker)};
  font-family: ${fontFamily};
  font-size: ${fontSize};
  font-weight: ${fontWeight};
  line-height: ${lineHeight};
  padding: 9px 8px 8px 8px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  ${({ prefix }) => (prefix ? `padding-left: ${prefix.paddingLeft};` : '')};
  cursor: ${props => (props.isCalendar ? 'pointer' : 'inherit')};
  padding-left: ${props => (props.hasIcon ? '50px' : 'auto')};

  &::placeholder {
    color: ${props => props.theme.colors.secondaryDark};
  }

  &:focus {
    border: 1px solid ${({ hasError }) => (hasError ? red : blue)};
    box-shadow: 0px 0px 0px 3px
      ${({ hasError }) => (hasError ? redLighter : blueLighter)};
    outline: none;
    transition-property: border-width, border-color, box-shadow;
    transition-duration: 0.1s;
    transition-timing-function: ease-in;
  }

  &:disabled {
    background: ${grayLight};
    border: 1px solid ${grayLight};
    color: ${grayDark};
    cursor: not-allowed;

    &::placeholder {
      color: ${props => props.theme.colors.secondaryDark};
    }
  }
`
type TFormFieldTimeInput = InputHTMLAttributes<HTMLInputElement> & {
  name: any
  label?: string
  initialValues?: any
  hours?: any
  minutes?: any
}

const StyledFieldRow = styled(FieldRow)`
  margin-bottom: 0px;
  @media ${device.tablet} {
    flex-direction: row;
    margin-bottom: 0px;
  }
`
export const FormFieldTimeInput = React.forwardRef(
  (props: TFormFieldTimeInput, ref) => {
    const { hours, minutes, initialValues, name, ...rest } = props
    const [field, meta] = useField(props)
    const { setFieldValue } = useFormikContext()

    const wheels = [
      [
        {
          circular: false,
          data: hours,
          label: 'From',
        },
        {
          circular: false,
          data: [':'],
        },
        {
          circular: false,
          data: minutes,
          label: 'To',
        },
        {
          circular: false,
          data: ['-'],
        },
        {
          circular: false,
          data: hours,
          label: 'From',
        },
        {
          circular: false,
          data: [':'],
        },
        {
          circular: false,
          data: minutes,
          label: 'To',
        },
      ],
    ]

    return (
      <FieldContainer
        label={props.label}
        error={meta.error}
        fieldName={field.name}
        type={props.type}
        style={{ padding: 0 }}
      >
        <ScrollerWrapper>
          <mobiscroll.Scroller
            display="inline"
            type="hidden"
            rows={4}
            wheels={wheels}
            value={initialValues}
            onChange={e => {
              setFieldValue(name, {
                ...e,
                valueText:
                  e?.valueText?.replace(/ /g, '').slice(0, 5) +
                  ' - ' +
                  e?.valueText?.replace(/ /g, '').slice(6),
              })
            }}
            showLabel={false}
            height={50}
            minWidth={32}
          />
        </ScrollerWrapper>
      </FieldContainer>
    )
  }
)

FormFieldTimeInput.defaultProps = {
  autoComplete: 'off',
  spellCheck: false,
  autoCorrect: 'off',
  autoCapitalize: 'off',
}

const ScrollerWrapper = styled.div<any>`
  width: 100% !important;
  color: red;
  .mbsc-fr-c,
  .mbsc-fr-w {
    border: none !important;
  }

  .mbsc-ios .mbsc-sc-whl-l {
    border-top: 1px solid #e9eaea;
    border-bottom: 1px solid #e9eaea;
  }
  .mbsc-ios .mbsc-sc-itm-sel {
    color: #333;
    font-size: 22px;
  }
`
