import React, { useState, useEffect, useRef } from 'react'
import { Loader } from 'components'
import { ModalBodyContainer } from 'src/styles'
import { EventBodyWrapper } from 'views/AppointmentModal/styles'
import {
  //genders,
  formatPhone,
  getAddressString,
  getRatingStars,
} from 'src/helpers'
import moment from 'moment'
import { useSdk } from 'sdk'
import { orderBy } from 'lodash'
import { sumBy } from 'lodash'
import {
  useClientGetAppointmentsFromResourceBookingsQuery,
  AppointmentCancellationEnum,
} from 'state/graphql'
import styled from 'styled-components'
import Button, { ButtonStyled } from 'components/Button/Button'
import { device } from '../../../constants'
import { useInviteUser } from 'state/queries'
import { useAppServices } from 'sdk/appServices'
import {
  Appointments,
  ClientDetailsAppointments,
  ClientDetailsBookingsColumn,
  ClientDetailsBookings,
  ClientDetailsSalesNumber,
  ClientDetailsTextSmall,
  ClientDetailsText,
  ClientDetailsSales,
  ClientDetailsOverview,
  ClientDetailsHeaderBottom,
  ClientDetailsHeaderTop,
  ClientDetailsHeader,
  ClientDetailsContainer,
  ClientsListNoAvatar,
  ClientsDetailsAvatar,
  ClientInfo,
  ClientReview,
  IconWrapper,
  MoreOptionTitle,
  MoreOption,
  MoreOptionsOverlay,
  MoreOptions,
  ActionButtonLabel,
  ActionButtonIconContainer,
  ActionButtonContainer,
  ActionButtons,
  ClientZoyyaAvatar,
  ClientDetailsAvatarContainer,
  ButtonsWrapper,
  MyClientDetailsTextSmall,
  AppointmentCircleButton,
  AppointmentsTitle,
} from 'views/Clients/ClientDetailsGroupMobile'
import { Avatar } from 'components'
import { icons } from 'components/Icon'
import { Badge } from 'src/styles'
import AppointmentCardGroup from './AppointmentCardGroup'
import AppointmentCardGroupMobile from 'views/Clients/AppointmentCardGroupMobile'
import { usePopup } from 'src/sdk/tw/Popup/usePopup'
import { useGenders } from 'src/sdk/helperHooks'
import { Tooltip2 } from 'src/components/Tooltip2/Tooltip2'
import { WarningTriangle } from 'src/components/Icon/Icons/WarningTriangle'

type TClientHeaderProps = {}
export const ClientHeader = styled.div<TClientHeaderProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 0px;
  width: 100%;
  @media ${device.tablet} {
    padding: 0;
  }
`

type TClientFormLeftProps = {
  isUser?: boolean
}
export const ClientFormLeft = styled.div<TClientFormLeftProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.light};
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 0px 10px 20px 20px;
  flex: 1;
  position: relative;
  min-height: 284px;
  @media ${device.tablet} {
    flex-wrap: wrap;
    max-height: 180px;
    align-items: baseline;
    justify-content: center;
    margin-right: 0;
    padding: 20px 15px;
    border-bottom: 1px solid ${props => props.theme.colors.lightBackground};
    border-radius: 0px;
    min-height: unset;
  }
`

type TClientFormImageProps = {}
export const ClientFormImage = styled.img<TClientFormImageProps>`
  width: 100px;
  min-height: 100px;
  border-radius: 4px;
  object-fit: cover;
  margin-bottom: 0px;
  height: 100px;
  @media ${device.tablet} {
    width: 60px;
    min-height: 60px;
    margin-bottom: 10px;
    height: 60px;
  }
`

type TClientEditButtonProps = {}
export const ClientEditButton = styled.div<TClientEditButtonProps>`
  position: absolute;
  top: 50px;
  right: -10px;
  background: ${props => props.theme.colors.light};
  border-radius: 100px;
  width: 40px;
  height: 40px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    box-shadow: 2px 2px 10px 0px #dbdfe2;
    transform: translate(3px, 3px);
  }
  @media ${device.tablet} {
    top: 60px;
    left: 40px;
    background: ${props => props.theme.colors.primary};
    width: 25px;
    height: 25px;
  }
`

type TClientNameProps = {}
export const ClientName = styled.span<TClientNameProps>`
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  @media ${device.tablet} {
    font-size: 24px;
    font-weight: 600;
    color: #000;
  }
`

type TClientEmailProps = {}
export const ClientEmail = styled.span<TClientEmailProps>`
  font-size: 12px;
  color: ${props => props.theme.colors.secondaryDark};
  @media ${device.tablet} {
    max-width: 40vw;
    text-overflow: ellipsis;
    display: inline;
    overflow: hidden;
  }
`

type TClientNumbersProps = {
  isUser?: boolean
}
export const ClientNumbers = styled.div<TClientNumbersProps>`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: ${props => (props.isUser ? 0 : '20px')};
  @media ${device.tablet} {
    width: 140px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
`

type TClientNumberProps = {}
export const ClientNumber = styled.span<TClientNumberProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

type TClientNumberNameProps = {}
export const ClientNumberName = styled.span<TClientNumberNameProps>`
  font-size: 18px;
  font-weight: 600;
`

type TClientNumberDescProps = {}
export const ClientNumberDesc = styled.span<TClientNumberDescProps>`
  font-size: 12px;
  color: ${props => props.theme.colors.secondaryDark};
`

type TClientNumbersDividerProps = {}
export const ClientNumbersDivider = styled.div<TClientNumbersDividerProps>`
  width: 2px;
  background: ${props => props.theme.colors.outline};
  height: 40px;
  @media ${device.tablet} {
    background: ${props => props.theme.colors.lightBackground};
  }
`

type TClientFormsProps = {}
export const ClientForms = styled.div<TClientFormsProps>`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
`

type TClientFormRightProps = {}
export const ClientFormRight = styled.div<TClientFormRightProps>`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.light};
  border-radius: 10px;
  width: 100%;
  height: 100%;
  flex: 3;
  min-height: 100%;
  padding: 20px 30px;
  height: auto;
`

type TClientRightRowProps = {}
export const ClientRightRow = styled.div<TClientRightRowProps>`
  display: flex;
  margin-bottom: 25px;
  justify-content: space-between;
`

type TClientRightColumnProps = {}
export const ClientRightColumn = styled.div<TClientRightColumnProps>`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${props => props.theme.colors.outline};
  padding-bottom: 10px;
  flex: 1;
  height: fit-content;
`
type TClientColumn = {}
export const ClientColumn = styled.div<TClientColumn>`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${props => props.theme.colors.outline};
  padding-bottom: 10px;
  height: fit-content;
`

type TClientLabelProps = {}
export const ClientLabel = styled.span<TClientLabelProps>`
  color: ${props => props.theme.colors.secondaryDark};
  font-weight: 500;
  margin-bottom: 5px;
`

type TClientValueProps = {}
export const ClientValue = styled.span<TClientValueProps>`
  min-height: 23px;
`

type TClientFormBottomProps = {}
export const ClientFormBottom = styled.div<TClientFormBottomProps>`
  display: flex;
  flex-direction: column;
  padding: 5px;
  background: ${props => props.theme.colors.light};
  width: 100%;
  border-radius: 10px;
`

type TClientFilterRowProps = {}
export const ClientFilterRow = styled.div<TClientFilterRowProps>`
  display: flex;
  align-items: center;
  border-bottom: 2px solid #f0f0f0;
  border-radius: 0px;
  padding-bottom: 0px;
  margin: 0px 15px;
  justify-content: space-between;
`

type TClientFilterProps = { isActive?: boolean; isOrange?: boolean }
export const ClientFilter = styled.div<TClientFilterProps>`
  flex: 1;
  height: 100%;
  color: ${props =>
    props.isOrange
      ? `${props.theme.colors.accent5}`
      : props.theme.colors.secondaryDarker};
  border-bottom: 2px solid
    ${props =>
      props.isActive ? props.theme.colors.primary : props.theme.colors.outline};
  padding: 5px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
  margin-bottom: -2px;
`

type TClientListContainerProps = {}
export const ClientListContainer = styled.div<TClientListContainerProps>`
  border-radius: 10px;
  max-height: calc(100vh - 520px);
  overflow: auto;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }
  &::-webkit-scrollbar {
    width: 3px;
    background-color: #ffffff;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #dbdfe2;
  }
`

type TPhoneRow = {}
export const PhoneRow = styled.div<TPhoneRow>`
  display: flex;
  justify-content: space-between;
`
export const ClientFormImageContainer = styled.div`
  height: 100px;
  width: 100px;
  margin-bottom: 10px;
  position: relative;
`

export const EditButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${ButtonStyled} {
    > div {
      margin-right: 0px !important;
    }
    :first-child {
      margin-right: 10px;
    }
  }
`

type TClientDetailsProps = {
  initialValues: any
  onEdit: any
}
export const ClientDetailsGroup = (props: TClientDetailsProps) => {
  const { initialValues, onEdit } = props
  const { t, isMobile } = useSdk()
  const clientData = useClientGetAppointmentsFromResourceBookingsQuery({
    variables: { clientId: initialValues?.client?.id },
    skip: !initialValues?.client?.id,
  })
  const genders = useGenders()
  const { showPopup } = usePopup()
  /*  const clientData = useClientGetAppointmentsQuery({
    variables: { clientId: initialValues?.client?.id },
    skip: !initialValues?.client?.id,
  }) */

  useEffect(() => {
    clientData.refetch()
  }, [])

  /* const bookingsData = useClientGetBookingsQuery({
    variables: { clientId: initialValues?.client?.id },
    skip: !initialValues?.client?.id,
  }) */
  //console.log(clientData?.data?.client?.getAppointments)
  /* const allData: any[] = [
    //...(clientData?.data?.client?.getAppointments || []), // u getBookings queryu se vec dohvate svi termini
    ...(clientData?.data?.client?.getAppointments?.map(app => {
      return {
        ...app,
        date: app?.startTime,
        //reason: 'NONE',
        assignedTo: app?.assignedTo,
        startTimeUtc: app?.startTime,
        resourceId: app?.assignedTo?.id,
        notes: app?.notes,
        startTime: moment(app?.startTime).format('HH:mm'),
        endTime: moment(app?.endTime).format('HH:mm'),
      }
    }) || []),
  ] */
  const allData = clientData?.data?.client?.getAppointmentsFromResourceBookings
  const sortedAllEvents = orderBy(allData, 'endTimeUtc', ['desc'])

  const sortedFutureEvents = sortedAllEvents
    .filter(x => {
      if (x?.kind === 'GROUP') {
        return (
          moment(x?.endTimeUtc).isAfter(moment()) &&
          x?.status !== 'canceled' &&
          x?.groupBookings?.find(y => y?.clientId == initialValues?.client?.id)
            ?.resourceBookingStatus !== 'canceled'
        )
      } else {
        return (
          moment(x?.endTimeUtc).isAfter(moment()) && x?.status !== 'canceled'
        )
      }
    })
    ?.sort(
      (a, b) =>
        moment(a?.startTimeUtc).valueOf() - moment(b?.startTimeUtc).valueOf()
    )

  const sortedPastEvents = sortedAllEvents
    .filter(x => {
      if (x?.kind === 'GROUP') {
        return (
          moment(x?.endTimeUtc).isBefore(moment()) &&
          x?.status !== 'canceled' &&
          x?.groupBookings?.find(y => y?.clientId == initialValues?.client?.id)
            ?.resourceBookingStatus !== 'canceled'
        )
      } else {
        return (
          moment(x?.endTimeUtc).isBefore(moment()) && x?.status !== 'canceled'
        )
      }
    })
    ?.sort(
      (a, b) =>
        moment(b?.startTimeUtc).valueOf() - moment(a?.startTimeUtc).valueOf()
    )

  const noShows = sortedAllEvents?.filter(x => {
    if (x?.kind === 'GROUP') {
      return (
        x?.status === 'canceled' ||
        x?.groupBookings?.find(y => y?.clientId == initialValues?.client?.id)
          ?.resourceBookingStatus === 'canceled'
      )
    } else {
      return x?.status === 'canceled'
    }
  })

  const clientNoShows = noShows?.filter(
    event => event?.reason === AppointmentCancellationEnum.ClientNoShow
  )

  const reviewsTotalSum = sumBy(sortedAllEvents, 'reviewRating')
  const reviewsTotalCount = sortedAllEvents.filter(item => item?.reviewRating)
    ?.length

  const reviewNumber =
    reviewsTotalSum && sortedAllEvents[0]
      ? Math.round(reviewsTotalSum / reviewsTotalCount)
      : 0

  const appServices = useAppServices()

  const [inviteUser] = useInviteUser()

  async function sendInvitation(values) {
    await inviteUser({
      variables: {
        input: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          gender: values.gender || 'Unspecified',
          mobilePhone: formatPhone(values.mobilePhone),
          role: 'CLIENT',
          clientId: values?.id,
        },
      },
    })
    appServices.toast.success(
      t('translation.EditClient.toast-employeeInvited', {
        firstName: values.firstName,
        lastName: values.lastName,
      })
    )
  }
  const [filter, setFilter] = useState<string>('future')
  const [isInitialFilter, setIsInitialFilter] = useState(true)

  useEffect(() => {
    if (isInitialFilter) {
      setFilter(sortedFutureEvents.length > 0 ? 'future' : 'past')
    }
  }, [sortedFutureEvents])

  const totalBookingsPrice = allData
    ?.filter(item => item?.status !== 'canceled' && item?.status !== 'noShow')
    ?.map(
      item =>
        item?.services?.reduce(
          (n, service) => n + (service?.price ? service?.price : 0),
          0
        )
    )
    ?.reduce((a, b) => {
      //@ts-ignore
      return a + b
    }, 0)

  const [isMoreOpen, setIsMoreOpen] = useState(false)
  // close options drawer when clicked outside of it
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsMoreOpen(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  return (
    <>
      {!isMobile ? (
        <ModalBodyContainer>
          {clientData.loading ? (
            <div style={{ minHeight: '500px' }}>
              <Loader isComponent />
            </div>
          ) : (
            <EventBodyWrapper>
              <ClientHeader>
                <ClientForms>
                  <ClientFormLeft isUser={initialValues?.client?.user?.id}>
                    <ClientFormImageContainer>
                      <ClientFormImage
                        src={
                          initialValues.client?.user?.avatarUrl &&
                          !initialValues.client?.user?.avatarUrl?.endsWith(
                            'null'
                          )
                            ? initialValues.client?.user?.avatarUrl
                            : initialValues.client?.user
                                ?.google_profilePicUrl ||
                              initialValues.client?.user?.fb_profilePicUrl ||
                              '/assets/default-avatar.svg'
                        }
                      />

                      {initialValues.client?.user?.id ? (
                        <ClientZoyyaAvatar>
                          <Avatar
                            src={'/zoyyaLetter.png'}
                            alt={''}
                            size="small"
                            style={{}}
                          />
                        </ClientZoyyaAvatar>
                      ) : null}
                    </ClientFormImageContainer>

                    <ClientName>
                      {initialValues.client?.firstName +
                        ' ' +
                        initialValues.client?.lastName}
                    </ClientName>

                    <ClientEmail>
                      {initialValues.client?.email}

                      {initialValues?.client?.mailboxStatus === 'DISABLED' ? (
                        <Tooltip2
                          helpText={
                            initialValues?.client?.mailboxDisabledReason?.includes(
                              'spamreport'
                            )
                              ? t(
                                  'translation.NewClientContainer.email-spamreported'
                                )
                              : t(
                                  'translation.NewClientContainer.email-blocked'
                                )
                          }
                        >
                          <span className="ml-1">
                            <WarningTriangle color="orange" size="size14" />{' '}
                          </span>
                        </Tooltip2>
                      ) : null}
                    </ClientEmail>
                    {initialValues.client?.status === 'BLOCKED' ? (
                      <Badge style={{ marginLeft: '0', marginTop: '5px' }}>
                        {t('translation.ClientsList.label-blocked')}
                      </Badge>
                    ) : null}
                    <div className="mt-[5px] mb-[15px] justify-start">
                      {getRatingStars(reviewNumber)}
                    </div>
                    <EditButtonContainer>
                      <Button
                        buttonType={'secondary'}
                        size="small"
                        label={t('translation.Client.izmjeni')}
                        iconComponent={<icons.Edit size={'smaller'} />}
                        onClick={onEdit}
                      />
                      {!(
                        initialValues?.client?.user?.id ||
                        initialValues?.client?.userId
                      ) && initialValues?.client?.email ? (
                        <div>
                          <Button
                            buttonType={'secondaryLink'}
                            label={t('translation.ClientDetails.pozovi')}
                            name="sendInvite"
                            size="small"
                            iconComponent={<icons.SendEmail size={'smaller'} />}
                            onClick={event => {
                              event.preventDefault()
                              event.stopPropagation()
                              showPopup({
                                onConfirm: () =>
                                  sendInvitation(initialValues?.client),
                                titleText: t(
                                  'translation.ClientsList.title-confirmSend',
                                  {
                                    firstName: initialValues?.client?.firstName,
                                    lastName: initialValues?.client?.lastName,
                                  }
                                ),
                                contentText: t(
                                  'translation.ClientsList.label-sendInviteInfo'
                                ),
                              })
                            }}
                          />
                        </div>
                      ) : null}
                      {/*{!initialValues?.client?.email ? (*/}
                      {/*    <div>*/}
                      {/*      {' '}*/}
                      {/*      <Button*/}
                      {/*          buttonType={'secondary'}*/}
                      {/*          label={t('translation.ClientDetails.no-email')}*/}
                      {/*          size="small"*/}
                      {/*          iconComponent={<icons.Email size={'smaller'} />}*/}
                      {/*          tooltip={t(*/}
                      {/*              'translation.ClientDetails.no-email-tooltip'*/}
                      {/*          )}*/}
                      {/*          onClick={onEdit}*/}
                      {/*      />*/}
                      {/*    </div>*/}
                      {/*) : null}*/}
                    </EditButtonContainer>
                    {/*<ClientNumbers isUser={initialValues?.client?.user?.id}>*/}
                    {/*  <ClientNumber>*/}
                    {/*    <ClientNumberName>{allClientEvents}</ClientNumberName>*/}
                    {/*    <ClientNumberDesc>*/}
                    {/*      {t('translation.ClientDetails.arrivals')}*/}
                    {/*    </ClientNumberDesc>*/}
                    {/*  </ClientNumber>*/}
                    {/*  <ClientNumbersDivider />*/}
                    {/*  <ClientNumber>*/}
                    {/*    <ClientNumberName>{noShows?.length}</ClientNumberName>*/}
                    {/*    <ClientNumberDesc>*/}
                    {/*      {t('translation.ClientDetails.noShows')}*/}
                    {/*    </ClientNumberDesc>*/}
                    {/*  </ClientNumber>*/}
                    {/*</ClientNumbers>*/}
                  </ClientFormLeft>
                  <ClientFormRight>
                    <ClientRightRow>
                      <ClientRightColumn>
                        <ClientLabel>
                          {t('translation.ClientDetails.gender')}
                        </ClientLabel>
                        <ClientValue>
                          {
                            genders.find(
                              gen => gen.id === initialValues?.client?.gender
                            )?.title
                          }
                        </ClientValue>
                      </ClientRightColumn>

                      <ClientRightColumn>
                        <ClientLabel>
                          {t('translation.ClientDetails.phone')}
                        </ClientLabel>
                        {initialValues?.client?.mobilePhone ? (
                          <a
                            style={{
                              minHeight: '23px',
                            }}
                            href={'tel:' + initialValues?.client?.mobilePhone}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {initialValues?.client?.mobilePhone}
                          </a>
                        ) : (
                          <div
                            style={{
                              minHeight: '23px',
                            }}
                          ></div>
                        )}
                      </ClientRightColumn>
                    </ClientRightRow>
                    <ClientRightRow>
                      <ClientRightColumn>
                        <ClientLabel>
                          {t('translation.ClientDetails.adress')}
                        </ClientLabel>
                        <ClientValue>
                          {getAddressString(initialValues?.client)}
                        </ClientValue>
                      </ClientRightColumn>
                      <ClientRightColumn>
                        <ClientLabel>
                          {t(
                            'translation.NewClientContainer.label-membershipExpirationDate'
                          )}
                        </ClientLabel>
                        <ClientValue
                          style={{
                            color: moment().isAfter(
                              moment(
                                initialValues?.client?.membershipExpirationDate
                              )
                            )
                              ? '#fe9060'
                              : '#333',
                          }}
                        >
                          {initialValues?.client?.membershipExpirationDate
                            ? moment(
                                initialValues?.client?.membershipExpirationDate
                              ).format('DD.MM.YYYY.')
                            : ''}
                        </ClientValue>
                      </ClientRightColumn>
                    </ClientRightRow>
                    <div>
                      <ClientColumn>
                        <ClientLabel>
                          {t('translation.ClientDetails.note')}
                        </ClientLabel>
                        <ClientValue>
                          {initialValues?.client?.comment}
                        </ClientValue>
                      </ClientColumn>
                    </div>
                  </ClientFormRight>
                </ClientForms>
                <ClientFormBottom>
                  <ClientFilterRow>
                    <ClientFilter
                      isActive={filter === 'future'}
                      onClick={() => {
                        setFilter('future')
                        setIsInitialFilter(false)
                      }}
                    >
                      {`${t(
                        'translation.ClientDetails.futureAppointments'
                      )}: ${sortedFutureEvents?.length}`}
                    </ClientFilter>
                    <ClientFilter
                      isActive={filter === 'past'}
                      onClick={() => {
                        setFilter('past')
                        setIsInitialFilter(false)
                      }}
                    >
                      {`${t(
                        'translation.ClientDetails.pastAppointments'
                      )}: ${sortedPastEvents?.length}`}
                    </ClientFilter>
                    <ClientFilter
                      isOrange={filter === 'missed'}
                      isActive={filter === 'missed'}
                      onClick={() => {
                        setFilter('missed')
                        setIsInitialFilter(false)
                      }}
                    >
                      {`${t(
                        'translation.ClientDetails.missedAppointments'
                      )}: ${noShows?.length}`}
                    </ClientFilter>
                  </ClientFilterRow>
                  <ClientListContainer>
                    {filter === 'future'
                      ? sortedFutureEvents?.map((event, idx) => (
                          <AppointmentCardGroup
                            key={idx}
                            event={event}
                            isGroupLocation={true}
                          />
                        ))
                      : filter === 'past'
                      ? sortedPastEvents.map((event, idx) => (
                          <AppointmentCardGroup
                            key={idx}
                            event={event}
                            isGroupLocation={true}
                          />
                        ))
                      : noShows.map((event, idx) => (
                          <AppointmentCardGroup
                            key={idx}
                            event={event}
                            isGroupLocation={true}
                          />
                        ))}
                  </ClientListContainer>
                </ClientFormBottom>
              </ClientHeader>
            </EventBodyWrapper>
          )}
        </ModalBodyContainer>
      ) : (
        <ClientDetailsContainer>
          {clientData.loading ? (
            <div style={{ minHeight: '500px' }}>
              <Loader isComponent />
            </div>
          ) : (
            <>
              <ClientDetailsHeader>
                <ClientDetailsHeaderTop>
                  <ClientDetailsAvatarContainer onClick={onEdit}>
                    {initialValues.client?.user?.avatarUrl &&
                    !initialValues.client?.user?.avatarUrl?.endsWith('null') ? (
                      <ClientsDetailsAvatar
                        src={initialValues.client?.user?.avatarUrl}
                      />
                    ) : !!initialValues.client?.user?.google_profilePicUrl ? (
                      <ClientsDetailsAvatar
                        src={initialValues.client?.user?.google_profilePicUrl}
                      />
                    ) : !!initialValues.client?.user?.fb_profilePicUrl ? (
                      <ClientsDetailsAvatar
                        src={initialValues.client?.user?.fb_profilePicUrl}
                      />
                    ) : (
                      <ClientsListNoAvatar>
                        {initialValues.client?.firstName
                          ? initialValues.client?.firstName
                              .charAt(0)
                              .toUpperCase() +
                            initialValues.client?.lastName
                              .charAt(0)
                              .toUpperCase()
                          : 'NN'}
                      </ClientsListNoAvatar>
                    )}
                  </ClientDetailsAvatarContainer>
                  <ClientInfo>
                    <ClientName>{`${initialValues.client?.firstName} ${initialValues.client?.lastName}`}</ClientName>
                    <ClientReview className="mt-[5px] mb-[15px] justify-start">
                      {getRatingStars(reviewNumber)}
                    </ClientReview>
                    {initialValues.client?.status === 'BLOCKED' ? (
                      <Badge style={{ marginLeft: '0', marginTop: '5px' }}>
                        {t('translation.ClientsList.label-blocked')}
                      </Badge>
                    ) : null}
                  </ClientInfo>
                </ClientDetailsHeaderTop>
                <ClientDetailsHeaderBottom>
                  <ActionButtons>
                    <ActionButtonContainer
                      style={{
                        opacity: `${
                          initialValues.client?.mobilePhone ? 1 : 0.5
                        }`,
                        //@ts-ignore
                        pointerEvents: `${
                          initialValues.client?.mobilePhone ? 1 : 'none'
                        }`,
                      }}
                      href={'sms:' + initialValues.client?.mobilePhone}
                      target="_blank"
                    >
                      <ActionButtonIconContainer>
                        <icons.Sms size={'small'} />
                      </ActionButtonIconContainer>
                      <ActionButtonLabel>
                        {t('translation.ClientDetailsMobile.sms-label')}
                      </ActionButtonLabel>
                    </ActionButtonContainer>
                    <ActionButtonContainer
                      style={{
                        opacity: `${
                          initialValues.client?.mobilePhone ? 1 : 0.5
                        }`,
                        //@ts-ignore
                        pointerEvents: `${
                          initialValues.client?.mobilePhone ? 1 : 'none'
                        }`,
                      }}
                      href={'tel:' + initialValues.client?.mobilePhone}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ActionButtonIconContainer>
                        <icons.PhoneBold size={'small'} />
                      </ActionButtonIconContainer>
                      <ActionButtonLabel>
                        {t('translation.ClientDetailsMobile.call-label')}
                      </ActionButtonLabel>
                    </ActionButtonContainer>

                    <ActionButtonContainer
                      style={{
                        opacity: `${
                          initialValues.client?.mobilePhone ? 1 : 0.5
                        }`,
                        //@ts-ignore
                        pointerEvents: `${
                          initialValues.client?.mobilePhone ? 1 : 'none'
                        }`,
                      }}
                      href={`https://wa.me/${initialValues.client?.mobilePhone}`}
                      target="_blank"
                    >
                      <ActionButtonIconContainer>
                        <icons.WhatsApp size={'small'} />
                      </ActionButtonIconContainer>
                      <ActionButtonLabel>WhatsApp</ActionButtonLabel>
                    </ActionButtonContainer>

                    <ActionButtonContainer
                      style={{
                        opacity: `${initialValues.client?.email ? 1 : 0.5}`,
                        //@ts-ignore
                        pointerEvents: `${
                          initialValues.client?.email ? 1 : 'none'
                        }`,
                      }}
                      href={'mailto:' + initialValues.client?.email}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ActionButtonIconContainer>
                        <icons.Email size={'small'} />
                      </ActionButtonIconContainer>
                      <ActionButtonLabel>Email</ActionButtonLabel>
                    </ActionButtonContainer>

                    <ActionButtonContainer onClick={() => setIsMoreOpen(true)}>
                      <ActionButtonIconContainer>
                        <icons.ThreeDotsVertical
                          size={'small'}
                          style={{
                            color: '#333',
                            fill: '#333',
                            strokeWidth: 1,
                          }}
                        />
                      </ActionButtonIconContainer>
                      <ActionButtonLabel>
                        {t('translation.FooterNavigation.more')}
                      </ActionButtonLabel>
                    </ActionButtonContainer>
                  </ActionButtons>
                </ClientDetailsHeaderBottom>
              </ClientDetailsHeader>
              <ClientDetailsOverview>
                <ClientDetailsSales>
                  <ClientDetailsSalesNumber
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>
                      {(totalBookingsPrice || '0') +
                        ' ' +
                        initialValues?.currencyId}
                    </div>
                    {initialValues?.client?.membershipExpirationDate ? (
                      <div
                        style={{
                          color: moment().isAfter(
                            moment(
                              initialValues?.client?.membershipExpirationDate
                            )
                          )
                            ? '#fe9060'
                            : '#333',
                        }}
                      >
                        {moment(
                          initialValues?.client?.membershipExpirationDate
                        ).format('DD.MM.YYYY.')}
                      </div>
                    ) : null}
                  </ClientDetailsSalesNumber>
                  <ClientDetailsTextSmall
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>
                      {t('translation.ClientDetailsMobile.total-sales')}
                    </div>
                    {initialValues?.client?.membershipExpirationDate ? (
                      <div>
                        {t(
                          'translation.NewClientContainer.label-membershipExpirationDate'
                        )}
                      </div>
                    ) : null}
                  </ClientDetailsTextSmall>
                </ClientDetailsSales>
                <ClientDetailsBookings>
                  <ClientDetailsBookingsColumn>
                    <ClientDetailsText>
                      {sortedFutureEvents?.length + sortedPastEvents?.length}
                    </ClientDetailsText>
                    <ClientDetailsTextSmall>
                      {t('translation.ClientDetailsMobile.total-bookings')}
                    </ClientDetailsTextSmall>
                  </ClientDetailsBookingsColumn>
                  <ClientDetailsBookingsColumn>
                    <ClientDetailsText>{`${clientNoShows?.length} (${noShows?.length})`}</ClientDetailsText>
                    <ClientDetailsTextSmall>
                      {`${t('translation.ClientDetails.noShows')} ${t(
                        'translation.ClientDetailsMobile.canceled'
                      )}`}
                    </ClientDetailsTextSmall>
                  </ClientDetailsBookingsColumn>
                </ClientDetailsBookings>
              </ClientDetailsOverview>
              <AppointmentsTitle>
                {t('translation.NewClientContainer.title-appointments')}
              </AppointmentsTitle>
              <ButtonsWrapper>
                <AppointmentCircleButton<any>
                  isActive={filter === 'future'}
                  onClick={() => {
                    setIsInitialFilter(false)
                    setFilter('future')
                  }}
                >
                  <MyClientDetailsTextSmall>
                    {t('translation.ClientDetailsMobile.future-dates') +
                      ': ' +
                      sortedFutureEvents?.length}
                  </MyClientDetailsTextSmall>
                </AppointmentCircleButton>
                <AppointmentCircleButton<any>
                  isActive={filter === 'past'}
                  onClick={() => {
                    setIsInitialFilter(false)
                    setFilter('past')
                  }}
                >
                  <MyClientDetailsTextSmall>
                    {t('translation.ClientDetailsMobile.past-dates') +
                      ': ' +
                      sortedPastEvents?.length}
                  </MyClientDetailsTextSmall>
                </AppointmentCircleButton>
                <AppointmentCircleButton<any>
                  isOrange={filter === 'missed'}
                  isActive={filter === 'missed'}
                  onClick={() => {
                    setIsInitialFilter(false)
                    setFilter('missed')
                  }}
                >
                  <MyClientDetailsTextSmall>
                    {t('translation.ClientDetailsMobile.missed-dates') +
                      ': ' +
                      noShows?.length}
                  </MyClientDetailsTextSmall>
                </AppointmentCircleButton>
              </ButtonsWrapper>
              <ClientDetailsAppointments>
                <Appointments>
                  {filter === 'future'
                    ? sortedFutureEvents?.map((event, idx) => (
                        <AppointmentCardGroupMobile key={idx} event={event} />
                      ))
                    : filter === 'past'
                    ? sortedPastEvents.map((event, idx) => (
                        <AppointmentCardGroupMobile key={idx} event={event} />
                      ))
                    : noShows.map((event, idx) => (
                        <AppointmentCardGroupMobile key={idx} event={event} />
                      ))}
                </Appointments>
              </ClientDetailsAppointments>{' '}
            </>
          )}
          {isMoreOpen ? (
            <MoreOptionsOverlay>
              <MoreOptions ref={wrapperRef}>
                <MoreOption onClick={onEdit}>
                  <IconWrapper>
                    <icons.Edit size={'smaller'} />
                  </IconWrapper>
                  <MoreOptionTitle>
                    {t('translation.ClientDetailsMobile.edit-label')}
                  </MoreOptionTitle>
                </MoreOption>

                {/* to be completed for attachments if needed */}
                {/* {!isNew && !isGroupLocation ? (
                  <MoreOption
                    onClick={() => setActiveForm('clientAttachments')}
                  >
                    <IconWrapper>
                      <icons.Download size={'smaller'} />
                    </IconWrapper>
                    <MoreOptionTitle>
                      {t('translation.Appointment.privitci')}
                    </MoreOptionTitle>
                  </MoreOption>
                ) : null} */}
                {!(
                  initialValues.client?.user?.id || initialValues.client?.userId
                ) && initialValues.client?.email ? (
                  <MoreOption
                    onClick={() => {
                      if (initialValues.client) {
                        showPopup({
                          onConfirm: () =>
                            sendInvitation(initialValues?.client),
                          titleText: t(
                            'translation.ClientsList.title-confirmSend',
                            {
                              firstName: initialValues?.client?.firstName,
                              lastName: initialValues?.client?.lastName,
                            }
                          ),
                          contentText: t(
                            'translation.ClientsList.label-sendInviteInfo'
                          ),
                        })
                      }
                    }}
                  >
                    <IconWrapper>
                      <icons.SendEmail size={'smaller'} />
                    </IconWrapper>
                    <MoreOptionTitle>
                      {t('translation.ClientDetailsMobile.sendInvite-label')}
                    </MoreOptionTitle>
                  </MoreOption>
                ) : null}
              </MoreOptions>
            </MoreOptionsOverlay>
          ) : null}
        </ClientDetailsContainer>
      )}
    </>
  )
}
