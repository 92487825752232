/**
 * Created by ivanazuberold on 29/11/2020.
 */

import React, { useContext } from 'react'
import styled from 'styled-components'
import { FormContentContainer, FormField } from '../../../components'
import { EventContainer, EventDateTitle } from '../styles'
import { FieldColumn, FieldRow } from '../../Settings/styles'
import { useSdk } from '../../../sdk'
import moment from 'moment'
import transparentize from 'polished/lib/color/transparentize'
import { device } from '../../../constants'
import { useGetAllHomeData } from '../../../state/queries'
import { getLocationId } from '../../../helpers'
import { orderBy } from 'lodash'
import { Email } from '../../../components/Icon/Icons/Email'
import { PhoneBold } from '../../../components/Icon/Icons/PhoneBold'
import Warning from 'components/Icon/Icons/Warning'
import { MobileContext } from 'src/Context'
export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.outline};
  padding: 15px 25px 5px 25px;
  background: ${props => props.theme.colors.lightBackground};

  @media ${device.tablet} {
    flex-direction: column;
    padding: 15px 15px 5px 15px;
  }
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const Label = styled.span`
  color: #333;
  font-family: Poppins, SFProDisplay, Poppins, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 28px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: inherit;
  margin-right: 10px;
  min-width: 70px;
`

export const Text = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline;
  max-width: 255px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StatusLabel = styled.div`
  background: ${props =>
    props.status === 'cancel' || props.status === 'cancelBooking'
      ? transparentize(0.2, props.theme.colors.accent5)
      : transparentize(0.2, props.theme.colors.primary)};
  color: ${props => props.theme.colors.light};
  padding: 5px 10px;
  border-radius: 4px;
`
export const TitleRow = styled.div`
  padding: 15px 25px 10px;
  border-bottom: 1px solid ${props => props.theme.colors.outline};
  display: flex;
  justify-content: space-between;

  @media ${device.tablet} {
    padding: 10px;
  }
`

export const UpdateStatusModal = ({
  setActiveForm,
  appointmentStatus,
  event,
}) => {
  const { t } = useSdk()
  const isMobile = useContext(MobileContext)

  const selectedDate = moment().startOf('year')

  const clientData = useGetAllHomeData(
    moment(selectedDate).startOf('year'),
    moment(selectedDate).endOf('year'),
    true,
    getLocationId(),
    [],
    event?.client?.id,
    [event?.client?.id]
  )

  const orderedEvents = orderBy(clientData.data?.appointments, 'time')

  const reservationsData = clientData.data?.resourceBooking?.search || []

  const filteredReservations = reservationsData?.filter(
    x => x.status !== 'CONFIRMED'
  )

  const allEvents = [
    ...orderedEvents,
    ...filteredReservations?.map(reservation => {
      return {
        ...reservation,
        duration: reservation.durationMinutes,
        date: reservation.startTime,
        startTime: moment(reservation.startTime).format('HH:mm'),
        endTime: moment(reservation.endTime).format('HH:mm'),
        assignedTo: reservation.resource,
        isReservation: true,
      }
    }),
  ]

  const sortedAllEvents = orderBy(allEvents, 'date', ['desc'])

  const allClientEvents = sortedAllEvents?.length
  const noShows = sortedAllEvents?.filter(
    x => x.reason === 'CLIENT_NO_SHOW'
  )?.length

  const statusOptions = [
    ...(appointmentStatus !== 'cancelBooking'
      ? [
          {
            id: 'CLIENT_CANCELED',
            name: t('translation.UpdateStatusModal.clientCanceled'),
          },
        ]
      : []),
    {
      id: 'ORG_CANCELED',
      name: t('translation.UpdateStatusModal.salonCanceled'),
    },
    ...(appointmentStatus !== 'cancelBooking'
      ? [
          {
            id: 'CLIENT_NO_SHOW',
            name: t('translation.UpdateStatusModal.clientNoShow'),
          },
        ]
      : []),
  ]

  const shouldShowRefundCheckbox =
    event?.posPaymentRequest && event?.posPaymentRequest?.status === 'PAID'

  return (
    <>
      <Wrapper>
        <TitleRow>
          <EventDateTitle cancelBooking={appointmentStatus === 'cancelBooking'}>
            {appointmentStatus === 'cancel' ||
            appointmentStatus === 'cancelBooking'
              ? t('translation.UpdateStatusModal.pleaseConfirmCancellation')
              : appointmentStatus === 'confirmBooking'
              ? t('translation.UpdateStatusModal.bookingConfirmation')
              : appointmentStatus === 'backToConfirm'
              ? t('translation.UpdateStatusModal.backToConfirmation')
              : t('translation.UpdateStatusModal.pleaseConfirmConclusion')}
          </EventDateTitle>
        </TitleRow>
        <Container>
          <Column>
            <Row>
              <Label>{t('translation.UpdateStatusModal.client')} </Label>
              <Text>
                {event?.client?.firstName
                  ? event?.client?.firstName + ' ' + event?.client?.lastName
                  : t('translation.UpdateStatusModal.noClient')}
              </Text>
            </Row>
            {event?.client?.email && (
              <Row>
                <Label>{t('translation.UpdateStatusModal.email')} </Label>
                <Text
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    textDecoration: 'underline',
                    color: '#6a4ee1',
                  }}
                >
                  <Email size={'smaller'} style={{ marginRight: 10 }} />
                  <a
                    href={'mailto:' + event?.client?.email}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {event?.client?.email}
                  </a>
                </Text>
              </Row>
            )}
            {event?.client?.mobilePhone && (
              <Row>
                <Label>{t('translation.SelectedLocation.label-phone')} </Label>
                <Text
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    textDecoration: 'underline',
                    color: '#6a4ee1',
                  }}
                >
                  <PhoneBold size={'smaller'} style={{ marginRight: 10 }} />
                  <a
                    href={'tel:' + event?.client?.mobilePhone}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {event?.client?.mobilePhone}
                  </a>
                </Text>
              </Row>
            )}
            {event?.client && (
              <Row>
                <Label>{t('translation.UpdateStatusModal.arrivals')} </Label>
                <Text>
                  {allClientEvents + ' / ' + (allClientEvents - noShows)}
                </Text>
              </Row>
            )}
          </Column>
          <Column>
            <Row>
              <Label>{t('translation.UpdateStatusModal.employee')}</Label>
              <Text>
                {event.assignedTo
                  ? event.assignedTo?.firstName +
                    ' ' +
                    event.assignedTo?.lastName
                  : event.resource?.firstName + ' ' + event.resource?.lastName}
              </Text>
            </Row>
            <Row>
              <Label>{t('translation.UpdateStatusModal.date')}</Label>
              <Text>
                {moment(event.date).format(
                  t('regionalFormats.date.momentFormatDate')
                ) +
                  ' ' +
                  event.startTime +
                  ' - ' +
                  event.endTime}
              </Text>
            </Row>
            <Row>
              <Label>{t('translation.UpdateStatusModal.services')}</Label>
              <Text>
                {event?.services?.map(
                  (service, idx) =>
                    service.name +
                    (idx < event?.services?.length - 1 ? ', ' : '')
                )}
              </Text>
            </Row>
            {event.notes && (
              <Row>
                <Label>{t('translation.UpdateStatusModal.note')}</Label>
                <Text>{event.notes}</Text>
              </Row>
            )}
          </Column>
        </Container>
      </Wrapper>

      <FormContentContainer>
        <EventContainer style={{ minHeight: 220 }}>
          {appointmentStatus === 'cancel' ||
          appointmentStatus === 'cancelBooking' ? (
            <FieldRow>
              <FieldColumn>
                <FormField.Select
                  isDisabled={appointmentStatus === 'cancelBooking'}
                  closeMenuOnSelect
                  parseValue={val => statusOptions?.find(e => e.id === val)}
                  formatValue={val => val?.id}
                  options={statusOptions}
                  onChange={item => {}}
                  label={t('translation.UpdateStatusModal.cancelReason')}
                  name={'cancelReason'}
                />
              </FieldColumn>
            </FieldRow>
          ) : null}
          {event?.cancelReason !== 'CLIENT_NO_SHOW' && (
            <FieldRow>
              {event?.client?.email ? (
                <FieldColumn style={{ marginLeft: '-4px' }}>
                  <FormField.Checkbox
                    label={t('translation.UpdateStatusModal.sentClientEmail')}
                    name="sendEmail"
                    type={'checkbox'}
                    disabled={!event?.client}
                    helpText={t('translation.UpdateStatusModal.sendEmailHint')}
                  />
                </FieldColumn>
              ) : (
                <FieldColumn>
                  <Warning
                    color={'orange'}
                    size={isMobile ? 'large' : 'smaller'}
                    style={{ marginRight: '8px' }}
                  />
                  <i>
                    {t(
                      'translation.AppointmentModal.client-email-missing-warning'
                    )}
                  </i>
                </FieldColumn>
              )}
            </FieldRow>
          )}
          {(appointmentStatus === 'cancel' ||
            appointmentStatus === 'cancelBooking') &&
          shouldShowRefundCheckbox ? (
            <FieldRow>
              <FieldColumn style={{ marginLeft: '-4px' }}>
                <FormField.Checkbox
                  helpText={t(
                    'translation.UpdateStatusModal.refund-checkbox-help'
                  )}
                  label={t(
                    'translation.UpdateStatusModal.refund-checkbox-label'
                  )}
                  type={'checkbox'}
                  name={'shouldRefundPaymentRequest'}
                />
              </FieldColumn>
            </FieldRow>
          ) : null}
          <FieldRow>
            <FieldColumn>
              <FormField.TextArea
                label={
                  event?.cancelReason !== 'CLIENT_NO_SHOW'
                    ? t('translation.UpdateStatusModal.clientNote')
                    : t('translation.AppointmentModal.label-comment')
                }
                name="cancelNotes"
                rows={2}
                helpText={t('translation.UpdateStatusModal.noteLabel')}
              />
            </FieldColumn>
          </FieldRow>
        </EventContainer>
      </FormContentContainer>
    </>
  )
}
